.cards-modal {
  overflow: scroll;
  .cards-content {
    max-width: 512px;
    border-radius: 20px;
    .cards-header,
    .cards-body {
      padding: 10px 15px 0;
    }
    .cards-header {
      display: flex;
      flex-flow: column;
      text-align: center;
      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 50%;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
        }
      }
      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 207px;
        margin: 0 auto;
        line-height: 30px;
        padding-bottom: 8px;
      }
    }
    .cards-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 24px 12px;
      .card-item {
        width: 100%;
        display: flex;
        margin: 8px 0;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        padding: 16px;
        border: 1px solid rgba(163, 172, 185, 0.2);

        .card-data-container {
          display: flex;
          flex-direction: column;
          text-align: left;
          .card-data {
            display: flex;
            .card-brand {
              width: 35px;
              margin-right: 12px;
            }
            .card-num {
              font-size: 16px;
              font-weight: 700;
              margin: 0;
              padding-right: 12px;
            }
          }
          .card-exp {
            font-size: 16px;
            padding-top: 8px;
            margin: 0;
            color: rgba(105, 115, 134, 0.5);
          }
        }
        #dropdown-btn {
          background-color: transparent;
          color: black;
        }
        .btn-options {
          display: flex;
          justify-content: center;
          height: fit-content;
          border-radius: 5px 5px 5px 5px;
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
          padding: 4px 8px;
          background: #fff;
          width: 38px;
        }
      }
    }
    .cards-body,
    .cards-footer {
      padding-top: 0;
    }
    .cards-header,
    .cards-footer {
      border: 0px;
    }
    .cards-footer,
    .cards-body {
      text-align: center;
    }
    .cards-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 44px;
      .btn-blue {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 38px;
        color: #fff;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        margin: 0 11px 0 0;
        background: #3cb2b9;
        border-radius: 40px;
      }
    }
    .cards-error {
      color: #d02a2a;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 992px) {
  .cards-content {
    min-width: 440px;
  }
}
