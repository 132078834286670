.host-reservation-container {
  width: 100%;
  padding: 48px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .car-form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-info {
      width: 100%;
      max-width: 510px;

      .btn-contact-container {
        margin-right: 10px;
      }

      .return-container {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .reservation-index {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          text-decoration: none;
          color: #000;

          .return-link {
            text-overflow: ellipsis;
            margin-left: 15px;
          }
        }
      }

      .btn-confirmation-code {
        width: 100%;
        background: #3cb2b9;
        box-shadow: 0px 0px 25px rgba(209, 217, 230, 0.8);
        border-radius: 8px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        margin: 45px 0 24px;
      }

      .card-car-information {
        .card-title {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          color: #195154;
          margin: 24px 0 8px;
        }

        .card-placas {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          color: #0e1433;
          margin: 0 0 24px;
        }

        .return-car {
          position: relative;
          margin-bottom: 32px;

          img {
            width: 100%;
            max-height: 178px;
            object-fit: cover;
            border-radius: 8px;
          }
        }
      }

      .car-experiences {
        display: flex;
        margin-bottom: 25px;
        gap: 10px;
        flex-wrap: wrap;

        .MuiChip-avatar {
          width: auto;
          height: auto;
        }
      }

      .car-specs {
        margin-bottom: 48px;
      }

      .contracts-container {
        display: none;
      }
    }

    .card {
      width: 100%;
      max-width: 510px;
      border: 0;
      border-radius: 20px;

      .btn-confirmation-code-des {
        display: none;
      }

      .card-body {
        padding: 0;
        .warning {
          display: flex;
          align-items: center;
          flex-flow: column;
          margin: 20px 0;

          .alert-triangle {
            border-radius: 50%;
            background: #f2c94c;
            width: 25px;
            height: 25px;
            padding: 0 0 5px;
            top: 10px;
            right: 50%;
            position: relative;

            img {
              width: 16px;
              height: 16px;
              margin-bottom: 7px;
            }
          }

          .text-container {
            background: rgba(242, 201, 76, 0.5);
            border-radius: 8px;
            margin: 0 auto;
            width: 100%;
            height: 59px;
            padding: 6px;

            p {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              text-align: center;
              color: #008de0;
              margin-bottom: 0;
              opacity: 1;
            }
          }
        }

        label {
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: #333333;
          margin-bottom: 4px;
        }

        .un-desde,
        .un-hasta {
          margin-bottom: 16px;
        }

        .dropdown-toggle::after {
          content: none;
        }

        .input-container {
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #e0e0e0;

          .input-text {
            width: 48%;
            padding: 4px;

            p {
              font-family: "Montserrat";
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              color: #333333;
              margin-bottom: 0px;
            }
          }

          .dropdown-divider {
            height: 28px;
            width: 1px;
            margin: 0 5px;
            border-top: 0px;
            border-right: 1px solid #e0e0e0;
          }
        }

        .input-text-large {
          width: 100%;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e0e0e0;
          padding: 8px 20px;
          justify-content: flex-start;
          margin-bottom: 32px;

          p {
            font-family: "Montserrat";
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
            margin: 0 0 0 8px;
          }

          a,
          span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
          }

          span {
            color: #333333;
          }
        }

        .dropdown-divider {
          margin: 17px 0;
        }

        .extra {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0;

          .day-tag {
            display: flex;
            align-items: center;
            gap: 10px;

            .price-day,
            .insurance-day {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #3cb2b9;
              margin-bottom: 0;
            }

            h5 {
              margin-bottom: 0px;
            }

            .tooltip-form {
              color: #828282;
              width: 16px;
              height: 16px;
            }
          }

          h5 {
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 16px;
            color: #828282;
            margin-bottom: 5px;

            img {
              margin-bottom: 5px;
            }
          }

          span {
            font-family: "Montserrat";
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
          }
        }

        .total {
          display: flex;
          justify-content: flex-end;
          text-align: end;
          margin-top: 18px;

          .totalPrice {
            span {
              font-weight: bold;
              margin: 0 5px 0 10px;
            }
          }

          .total-label,
          .totalPrice {
            font-family: "Montserrat";
            font-size: 18px;
            line-height: 22px;
          }

          .total-label {
            font-weight: 600;
          }
        }

        .profit {
          margin: 18px 0 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 16px !important;
          width: 100%;
          height: 52px;
          background: rgba(111, 207, 151, 0.25);
          border-radius: 10px;

          .day-tag {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 0 16px 0 0;

            .tooltip-form {
              color: #828282;
              width: 16px;
              height: 16px;
            }

            h5 {
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 0;
            }
          }

          .gain {
            margin-bottom: 0;

            span {
              font-weight: bold;
              margin: 0 5px 0 10px;
            }

            font-family: "Montserrat";
            font-size: 18px;
            line-height: 22px;
          }
        }

        .col-sm-12 {
          padding: 0;
        }

        .btn-active {
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          margin: 49px 0 59px;

          .last-dates {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;
            text-align: center;
            color: #373a3c;
            margin-bottom: 16px;
          }

          p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 16px;
          }

          .help {
            display: flex;
            flex-direction: column;
            align-items: center;

            .txt-help {
              font-weight: 400;
              font-size: 16px;
              text-align: center;
            }

            .btn-help {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 240px;
              height: 52px;
              background: var(--Brand-Colors-Primary-Color);
              border-radius: 40px;
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 20px;
              line-height: 21px;
              color: #ffffff;
              margin-bottom: 24px;
            }
          }
        }

        .btn-finish {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 49px 0 59px;

          .btn-orange {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 240px;
            height: 52px;
            background: var(--Brand-Colors-Primary-Color);
            border-radius: 40px;
            font-family: "Montserrat";
            font-weight: 700;
            font-size: 20px;
            line-height: 21px;
            color: #ffffff;
          }
        }

        .btn-next {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 49px 0 59px;

          .last-dates {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;
            text-align: center;
            color: #373a3c;
            margin-bottom: 16px;
          }

          .btn-red {
            width: 240px;
            height: 52px;
            background: #eb5757;
            border-radius: 40px;
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }
        }

        .btn-pending {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 49px 0 59px;

          .btn-red {
            width: 240px;
            height: 52px;
            background: #eb5757;
            border-radius: 40px;
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }
        }

        .btn-cancel {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 49px 0 59px;

          .btn-red {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 248px;
            height: 32px;
            background: #eb5757;
            border-radius: 30px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
          }
        }
      }

      .insurance-container {
        margin-bottom: 40px;
        .insurance-summary {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            font-family: "Montserrat";
            font-weight: 700;
            font-size: 20px;
            line-height: 46px;
            text-align: center;
            color: #195154;
          }

          .card {
            padding-left: 34px;
            background: #ffffff;
            border: 2px solid #3cb2b9;
            box-sizing: border-box;
            box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
            border-radius: 27px;

            .card-body {
              display: flex;
              flex-wrap: wrap;
              background-color: transparent;

              .card-title-text {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #373a3c;
              }

              .list-right,
              .list-left {
                margin-bottom: 2px;

                li {
                  font-family: "Montserrat";
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #373a3c;
                }
              }
            }
          }
        }

        .insurance-information {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 0 10px;

          .title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 46px;
            color: #195154;
          }

          .information {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            max-width: 300px;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
          }
        }
      }
    }

    .contracts-container-mobile {
      width: 100%;
      max-width: 510px;
      .terms {
        .terms-title {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #195154;
        }

        .check-list-container {
          max-width: 383px;
          margin-top: 30px;
          text-align: left;

          a,
          p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }

          a {
            text-decoration-line: underline;
            color: #2f80ed;
          }
        }

        .text-container {
          margin: 24px 0;
          text-align: left;

          a {
            text-decoration: underline;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #008de0;
          }
        }
      }
    }
  }

  .banner-swipper {
    margin: 0;
    height: 550px;

    .swiper-slide {
      text-align: unset;

      .responsibilities-host-container-banner {
        padding: 0;
        width: 95%;
        max-width: 510px;
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: #7b7b7b;
        border: 0px;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .host-reservation-container {
    .car-form {
      flex-flow: row;
      align-items: flex-start;
      justify-content: center;
      padding-top: 60px;

      .card-info {
        display: block;
        max-width: 529px;

        .return-container {
          display: flex;
          align-items: center;
          margin-bottom: 42px;

          img {
            width: 5px;
            margin-right: 15px;
          }

          b {
            font-weight: 600;
          }

          span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            color: #333333;
          }
        }

        .card-car-information {
          margin-top: 48px;

          .return-car {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            img {
              width: 100%;
              max-height: 380px;
              object-fit: cover;
              background: #333333;
            }
          }

          .card-title {
            font-size: 36px;
            color: #195154;
          }
        }

        .btn-confirmation-code {
          display: none;
        }

        .contracts-container {
          display: flex;
          .terms {
            .terms-title {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              color: #195154;
            }

            .check-list-container {
              width: 383px;
              margin-top: 30px;
              text-align: left;

              a,
              p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
              }

              a {
                text-decoration-line: underline;
                color: #2f80ed;
              }
            }

            .text-container {
              margin: 24px 0;
              text-align: left;

              a {
                text-decoration: underline;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #008de0;
              }
            }
          }
        }
      }

      .card {
        padding: 203px 24px 0;

        .btn-confirmation-code-des {
          display: flex;
          justify-content: center;
          background: #3cb2b9;
          box-shadow: 0px 0px 25px rgba(209, 217, 230, 0.8);
          border-radius: 8px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          margin-bottom: 58px;
        }

        .card-body {
          .warning {
            display: flex;
            align-items: center;
            flex-flow: column;
            margin-top: 20px;
            padding: 0;

            .alert-triangle {
              border-radius: 50%;
              background: #f2c94c;
              width: 25px;
              height: 25px;
              padding: 0 0 5px;
              top: 10px;
              right: 50%;
              position: relative;

              img {
                width: 16px;
                height: 16px;
                margin-bottom: 7px;
              }
            }

            .text-container {
              background: rgba(242, 201, 76, 0.5);
              border-radius: 8px;
              margin: 0 auto;
              width: 100%;
              height: 46px;
              padding: 6px;

              p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: #333333;
                margin-bottom: 0;
                opacity: 1;
              }
            }
          }

          .terms {
            display: none;
          }

          .col-md-6 {
            padding: 0 31px 0 0;
          }

          .total {
            margin-bottom: 0px;

            h6 {
              font-size: 16px;
              line-height: 20px;
              margin-right: 36px;
            }
          }

          .profit {
            margin-top: 20px;
          }

          .un-container-date {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .delibery {
            width: 45%;
            padding: 0;
            float: left;
          }

          .devolution {
            margin-left: 45px;
          }

          .un-hasta,
          .un-desde {
            width: 45%;
          }

          .input-container {
            display: flex;
            align-items: center;
            justify-content: space-around;

            .input-text {
              width: 100px;
            }
          }

          .extra {
            .discount-code {
              width: 100%;
            }
          }

          .btn-active {
            display: flex;
            align-items: flex-end;
            margin: 40px 0 60px;

            .last-dates {
              margin-bottom: 20px;
            }

            p {
              margin-bottom: 0;
            }

            .help {
              align-items: flex-end;

              .btn-help {
                width: 160px;
                height: 38px;
                border-radius: 40px;
                font-family: "Montserrat";
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
              }

              .txt-help {
                text-align: right;
                max-width: 370px;
              }
            }
          }

          .btn-pending,
          .btn-finish,
          .btn-cancel {
            justify-content: flex-end;
          }

          .btn-next {
            align-items: flex-end;
          }
        }

        .insurance-container {
          display: none;
        }
      }

      .contracts-container-mobile {
        display: none;
      }
    }

    .banner-swipper {
      height: 420px;

      .swiper-slide {
        .responsibilities-host-container-banner {
          width: 95%;
          max-width: 1020px;
        }
      }
    }
  }
}
