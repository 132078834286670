.a-reservation-container {
  display: flex;
  flex-flow: column;
  align-items: center;

  .banner-swipper {
    margin: 0;
    height: 405px;

    .swiper-slide {
      text-align: unset;

      .responsibilities-host-container-bannerr {
        padding: 0;
        width: 95%;
        max-width: 510px;
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: #7b7b7b;
        border: 0px;
      }
    }
  }

  .join-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    .title-container {
      text-align: center;

      h1 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #195154;
        margin: 32px 0;
      }
    }

    .option-container {
      .badge-host {
        span {
          margin: 5px -9px;
          background-color: #ffffff;
          color: var(--Brand-Colors-Primary-Color);
        }
      }
    }

    .drop-orange {
      margin-bottom: 32px;

      .MuiInputBase-formControl {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 14px;
        height: 45px;
        line-height: 17px;
        text-align: right;
        color: #ffffff;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 30px;
        border: 0px;
        width: 312px;
        text-align: center;

        .bs-caret {
          margin: 16px 16px 22px 0;
        }

        .filter-option {
          text-align: center;
        }
      }

      .dropdown-toggle.btn-default:hover {
        background: var(--Brand-Colors-Primary-Color);
        color: #ffffff;
        border: 0px;
      }

      .dropdown-toggle::after {
        display: none;
      }
    }
  }

  .reservation-card {
    padding-bottom: 120px;

    .empty_cars {
      width: 309.88px;
      height: 237px;
      margin: 0px auto;
      display: block;
    }

    .empty-text {
      text-align: center;
      font-size: 18px;
      width: 310px;
      line-height: 30px;
      color: #3cb2b9;
      font-family: "Montserrat";
      font-weight: bold;
    }

    .card {
      background: #ffffff;
      border: 0;
      width: 359px;
      height: auto;

      .list-group {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .list-group-item {
          width: 99%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: 0px;
          border-radius: 8px;
        }

        .dropdown-divider {
          background: #e0e0e0;
          width: 84%;
        }
      }
    }

    .card::-webkit-scrollbar {
      width: 8px;
      height: 100%;
    }

    .card::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #f2f2f2;
      width: 8px;
      margin: 30px 0;
    }

    .card::-webkit-scrollbar-thumb {
      background: #3cb2b9;
      width: 8px;
      border-radius: 4px;
    }
  }
}

@media only screen and (min-width: 991px) {
  .a-reservation-container {
    margin-left: 65px;
  }
}

@media only screen and (min-width: 1130px) {
  .a-reservation-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    .banner-swipper {
      height: 347px;

      .swiper-slide {
        .responsibilities-host-container-banner {
          width: 95%;
          max-width: 1020px;
        }
      }
    }

    .join-container {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      width: 1110px;

      .title-container {
        text-align: left;

        h1 {
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 42px;
          line-height: 46px;
          color: #195154;
          margin: 32px 0;
        }
      }

      .option-container {
        position: relative;
        background: #ffffff;
        border: 1px solid #bdbdbd;
        padding: 2px 4px;
        width: 100%;
        border-radius: 40px;
        margin-bottom: 59px;

        button {
          width: 270px;
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #0e1433;
          z-index: 1;
          border-radius: 30px;

          .al-trip {
            border-radius: 50%;
            padding: 0;
            width: 20px;
            height: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 7px;
          }
        }

        .btn-menu {
          position: relative;
        }
      }

      button:active,
      .btn:active,
      button:hover,
      .btn:not(:disabled):not(.disabled) {
        border: 0px;
        outline: none;
        box-shadow: none;
      }

      .text-normal {
        font-family: "Montserrat";
        font-weight: normal;
        color: #0e1433;
      }

      .text-active {
        font-family: "Montserrat";
        font-weight: bold !important;
        color: #ffffff !important;
      }

      .MuiTabs-indicator {
        background: var(--Brand-Colors-Primary-Color);
        box-shadow: 0px 1px 10px 5px rgba(92, 92, 92, 0.080283);
        border-radius: 30px;
        height: 40px;
        margin-bottom: 5px;
        z-index: 0;
        position: absolute;
      }

      .MuiTabs-flexContainer {
        .Mui-selected {
          color: #ffffff;
          .badge-host {
            span {
              background-color: #ffffff !important;
              color: var(--Brand-Colors-Primary-Color) !important;
            }
          }
        }

        .MuiTab-textColorInherit {
          .badge-host {
            span {
              background-color: var(--Brand-Colors-Primary-Color);
              color: #ffffff;
            }
          }
        }
      }
    }

    .reservation-card {
      max-width: 1215px;

      .empty_cars {
        width: 540px;
        height: 413px;
      }

      .empty-text {
        line-height: 30px;
        text-align: center;
        color: #3cb2b9;
        width: 100%;
        font-size: 20px;
        font-family: "Montserrat";
        font-weight: bold;
      }

      .card {
        background: #ffffff;
        border: 0;
        width: 100%;
        height: auto;

        .list-group {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .list-group-item {
            .car-image {
              width: 250px;
              div {
                width: 250px !important;
              }
            }
            .trip-container {
              .trip-data {
                gap: 1vw;
                overflow: hidden;
              }
            }
          }

          .btn-orange {
            background: var(--Brand-Colors-Primary-Color);
            border-radius: 25px;
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            align-self: center;
          }

          .dropdown-divider {
            background: #e0e0e0;
            width: 84%;
          }
        }
      }

      .card::-webkit-scrollbar {
        width: 8px;
        height: 100%;
      }

      .card::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #f2f2f2;
        width: 8px;
        margin: 30px 0;
      }

      .card::-webkit-scrollbar-thumb {
        background: #3cb2b9;
        width: 8px;
        border-radius: 4px;
      }
    }
  }
}
