.modal-dialog {
  margin: 0 auto !important;
}

.change-number-modal {
  display: flex !important;
  align-items: center;
  .modal-content {
    width: 345px;
    margin: 100px auto;
    box-shadow: 2px 8px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background: var(--Utility-Colors-Primary-Background);
    padding: 20px 16px 20px 16px;
    gap: 24px;

    .modal-header,
    .modal-footer {
      border: none;
    }

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;
      text-align: left;
      padding: 0;
      gap: 8px;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        display: flex;
        background: transparent;
        align-items: center;
        justify-content: center;
        border: none;
        align-self: end;

        img {
          filter: var(--Custom-Colors-Svg);
        }
      }

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: var(--Utility-Colors-Primary-Text);
        width: 100%;
        margin: 0;
      }
    }

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      padding: 0;

      h3 {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 16px;
        line-height: 19.5px;
        color: var(--Utility-Colors-Primary-Text);
        margin: 0;
      }

      .react-tel-input {
        display: flex;
        justify-content: center;
        gap: 8px;
        flex-flow: row-reverse;

        .form-control {
          border-radius: 40px;
          padding: 0 4px;
          height: 41px;
          background: var(--Utility-Colors-Secondary-Background);
          border-color: var(--Utility-Colors-Secondary-Background);
          border-width: 2px;
          font-family: "Montserrat";
          color: var(--Utility-Colors-Primary-Text);
        }

        .flag-dropdown {
          position: relative;
          border-radius: 40px;
          background: var(--Utility-Colors-Secondary-Background);
          border-color: var(--Utility-Colors-Secondary-Background);
          border-width: 2px;
        }

        .flag-dropdown:focus {
          border-color: var(--Brand-Colors-Secondary-Color);
        }

        .country-list {
          border-radius: 16px;
          background: var(--Utility-Colors-Secondary-Background);

          .country {
            font-family: "Monsterrat";
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      .btn-orange {
        border-radius: 40px;
        margin: auto;
        width: 100%;
        height: 47px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        background: var(--Brand-Colors-Primary-Color);
        color: var(--Brand-Colors-White-color-fix);
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .change-number-modal {
    .modal-content {
      width: 520px;
      padding: 20px 24px 20px 24px;

      .modal-body {
        align-items: center;

        h3 {
          width: 345px;
          text-align: left;
        }

        .react-tel-input {
          width: 345px;
        }
      }

      .modal-footer {
        .btn-orange {
          width: 310px;
        }
      }
    }
  }
}
