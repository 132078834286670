.a-cars-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fafafa;
  min-height: calc(100vh - 64px);

  .a-cars__join-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 21px 16px 32px 16px;

    .a-cars__title-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .a-cars__route {
        display: flex;
        gap: 8px;
        align-items: center;

        .a-cars__route__arrow {
          filter: invert(94%) sepia(7%) saturate(226%) hue-rotate(179deg)
            brightness(92%) contrast(88%);
          width: 10px;
          height: 10px;
        }

        .a-cars__route__first,
        .a-cars__route__second {
          color: #475467;
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .a-cars__title {
        font-family: "Montserrat";
        color: #151516;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
      }

      .a-cars__subtitle {
        color: #4a4a57;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .a-cars__search__container {
      display: flex;
      margin-top: 20px;
      gap: 6px;

      .a-cars__search {
        width: 245px;
        display: flex;
        border-radius: 12px;
        border: 1px solid #d1d1d1;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .a-cars__search__icon {
          color: #6298d5;
        }

        .a-cars__search__input {
          width: calc(100% - 40px);
        }
      }

      .a-cars__search__filter {
        margin-right: 6px;
      }

      .a-cars__search__filter,
      .a-cars__search__add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 12px;
        border: 1px solid #d1d1d1;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        svg {
          color: #4a4a57;
        }
      }
    }
  }

  .a-cars-container__reservation__card {
    width: 343px;

    .car-card {
      .list-group {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .list-group-item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          border: 0px;
          flex-direction: column;
          border-radius: var(--radius-xl, 12px);
          background: var(--Colors-Utility-colors-Secondary-background, #fff);
          box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
            0px 1px 2px 0px rgba(16, 24, 40, 0.06);
          padding: 0;
          margin: 12px 0;

          .list-group-item__image__container {
            position: relative;

            .btn-options {
              position: absolute;
              right: 10px;
              top: 10px;
              padding: 0;
              width: 36px;
              height: 36px;
              border-radius: var(--radius-xl, 12px);
              border: 1px solid var(--Colors-Custom-colors-divider-fg, #d1d1d1);
              background: var(
                --Colors-Utility-colors-Secondary-background,
                #fff
              );
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }

            .list-group-item__status {
              width: 118px;
              position: absolute;
              display: flex;
              top: 8px;
              left: 8px;
              height: 24px;
              z-index: 9;
              gap: 6px;

              svg {
                margin: 0;
              }

              span {
                display: flex;
                align-items: center;
                gap: 6px;
                padding: 0;

                p {
                  color: var(--Colors-Utility-colors-Primary-text, #151516);
                  font-family: "Montserrat";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  margin-bottom: 0;
                }

                .list-group-item__status__icon__container {
                  position: relative;
                  width: 30px;
                  height: 16px;

                  .list-group-item__status__icon {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    object-fit: cover;
                  }

                  .list-group-item__status__icon:nth-child(1) {
                    z-index: 2;
                    top: 0;
                    left: 0;
                  }

                  .list-group-item__status__icon:nth-child(2) {
                    z-index: 1;
                    top: 0;
                    left: 10px;
                  }
                }
              }
            }

            .car-image {
              display: flex;
              height: 100%;
              background-color: #f9f9f9;

              img {
                max-height: 180px;
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 8px 8px 0 0;
              }

              .empty-image {
                width: 112px;
                object-fit: contain;
              }
            }
          }

          .list-group-item__info__container {
            display: flex;
            flex-direction: column;
            padding: 24px 20px 20px;
            gap: 20px;

            .list-group-item__main__info {
              display: flex;
              flex-direction: column;
              gap: 4px;

              h2 {
                color: var(--Colors-Utility-colors-Primary-text, #151516);
                font-family: "Montserrat";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 0;
              }

              .plate {
                color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                font-family: "Montserrat";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 0;
              }

              .price {
                color: var(--Colors-Utility-colors-Primary-text, #151516);
                font-family: "Montserrat";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 0;
              }
            }

            .list-group-item__type {
              .list-group-item__type__title {
                color: var(--Colors-Utility-colors-Primary-text, #151516);
                font-family: "Montserrat";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
              }

              .list-group-item__type__container {
                display: flex;
                flex-wrap: wrap;
                gap: 6px;

                .car-data-item {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 6px;
                  padding: 4px 8px;
                  border-radius: 12px;
                  border: 1px solid
                    var(--Colors-Custom-colors-divider-fg, #d1d1d1);
                  background: var(
                    --Colors-Utility-colors-Secondary-background,
                    #fff
                  );
                  color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                  font-family: "Montserrat";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;

                  p {
                    margin-bottom: 0;
                  }

                  img,
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }

            .list-group-item__direction {
              display: flex;
              flex-direction: column;

              .list-group-item__direction__title {
                color: var(--Colors-Utility-colors-Primary-text, #151516);
                font-family: "Montserrat";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
              }

              .list-group-item__direction__info {
                display: flex;
                align-items: flex-start;
                gap: 4px;

                .list-group-item__direction__container {
                  display: flex;
                  flex-direction: column;
                  width: calc(100% - 20px);

                  .list-group-item__direction__comercial__name {
                    color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                    font-family: "Montserrat";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    margin-bottom: 0;
                  }

                  .list-group-item__direction__address {
                    overflow: hidden;
                    color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                    text-overflow: ellipsis;
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    white-space: nowrap;
                  }
                }
              }
            }

            .document-status {
              border-radius: var(--radius-xl, 12px);
            }
          }
        }
      }
    }
  }

  .cars-empty-list {
    width: 100%;
    padding: 0 16px;
    display: flex;
    justify-content: center;

    .cars-empty-list__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: var(--radius-xl, 12px);
      border: 1px solid var(--Colors-Custom-colors-Disabled-bg, #e9e9e9);
      background: var(--Colors-Utility-colors-Secondary-background, #fff);
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
      padding: 70px 40px 48px;
      min-width: 343px;
      max-width: 1254px;
      width: 100%;

      img {
        width: 112.394px;
        height: 44.24px;
      }

      .cars-empty-list__information {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 54px 0 32px;

        .cars-empty-list__title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        .cars-empty-list__subtitle {
          font-size: 14px;
          font-weight: 400;
        }

        .cars-empty-list__title,
        .cars-empty-list__subtitle {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-style: normal;
        }
      }

      .btn-add {
        display: flex;
        align-items: center;
        gap: 6px;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
        background: var(--Colors-Brand-colors-Primary-color, #b72446);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 175px;
        height: 40px;

        p {
          color: var(--Colors-Brand-colors-White-color-fix, #fff);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }

        img {
          width: 28px;
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .a-cars-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: 65px;

    .a-cars__join-container {
      max-width: 1254px;
      width: 100%;
      margin: 0;
      padding: 32px 0 24px;

      .title-container {
        text-align: left;

        h1 {
          margin: 0;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 42px;
          line-height: 46px;
          color: #195154;
          padding: 44px 0 0 0;
        }
      }

      .btn-container {
        display: flex;
        align-items: flex-end;
        .btn-add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 180px;
          height: 30px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;

          p {
            margin: 0;
            padding: 0 6px 0 4px;
          }
        }
      }

      .a-cars__search__container {
        .a-cars__search {
          width: 320px;
        }

        .a-cars__search__container__actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 320px);

          .a-cars__search__container__actions__dropdowns {
            display: flex;
            gap: 6px;
          }

          .btn-add {
            display: flex;
            align-items: center;
            gap: 6px;
            border-radius: var(--radius-xl, 12px);
            border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
            background: var(--Colors-Brand-colors-Primary-color, #b72446);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            width: 175px;
            height: 40px;

            p {
              color: var(--Colors-Brand-colors-White-color-fix, #fff);
              font-family: "Montserrat";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 0;
            }

            img {
              width: 28px;
            }
          }
        }
      }
    }

    .a-cars-container__reservation__card {
      max-width: 1254px;
      width: 99%;

      .car-card {
        .list-group {
          .list-group-item {
            flex-direction: row;
            height: 190px;

            .list-group-item__image__container {
              width: 300px;

              .car-image {
                img {
                  max-height: 190px;
                  border-radius: 8px 0 0 8px;
                }
              }
            }

            .list-group-item__info__container {
              flex-direction: row;
              gap: 14px;
              width: calc(100% - 300px);

              .list-group-item__direction {
                width: 20%;
              }

              .list-group-item__type {
                width: 32%;
              }

              .list-group-item__main__info {
                width: 41%;
                gap: 10px;

                .document-status {
                  width: 100%;
                  height: 35px;
                  font-size: 14px;
                }
              }

              .btn-options {
                position: absolute;
                right: 10px;
                top: 10px;
                padding: 0;
                width: 36px;
                height: 36px;
                border-radius: var(--radius-xl, 12px);
                border: 1px solid
                  var(--Colors-Custom-colors-divider-fg, #d1d1d1);
                background: var(
                  --Colors-Utility-colors-Secondary-background,
                  #fff
                );
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              }
            }
          }
        }
      }
    }

    .cars-empty-list {
      .cars-empty-list__container {
        padding: 113px 40px 78px;
      }
    }
  }
}

@media only screen and (min-width: 1196px) {
  .a-cars-container {
    .a-cars-container__reservation__card {
      padding: 0;
      .car-card {
        .list-group {
          .list-group-item {
            .list-group-item__info__container {
              gap: 20px;

              .list-group-item__direction,
              .list-group-item__type,
              .list-group-item__main__info {
                width: 30%;
              }
            }
          }
        }
      }
    }
  }
}
