.complete-profile-modal {
  display: flex !important;
  align-items: center;
  .modal-content {
    width: 312px;
    height: 587px;
    margin: 0 auto;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;

    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 50%;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
        }
      }

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 210px;
        margin: 17px auto 24px;
        line-height: 30px;
      }
    }

    .modal-body {
      display: flex;
      flex-flow: column;
      align-items: center;

      p {
        font-family: "Montserrat";
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 64px;
      }
    }

    .modal-body,
    .modal-footer {
      padding-top: 0;
    }

    .modal-header,
    .modal-footer {
      border: 0px;
    }

    .modal-footer,
    .modal-body {
      text-align: center;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 40px;

      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 272px;
        height: 38px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        color: #fff;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        margin: 0 11px 0 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .complete-profile-modal {
    .modal-content {
      width: 635px;
      height: 594px;

      .modal-header {
        h2 {
          font-size: 20px;
          line-height: 30px;
          width: 373px;
          margin-bottom: 7px 0 28px;
        }
      }

      .modal-body {
        padding: 0 48px;

        p {
          margin: 0 0 56px;
          width: 445px;
        }
      }

      .modal-footer {
        padding-bottom: 50px;

        .btn-orange {
          width: 223px;
        }
      }
    }
  }
}
