.admin-navbar {
  padding: 8px 8px 8px 16px;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #d1d1d1;

  .admin-navbar__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .admin-navbar__link {
      display: none;
    }
    .admin-navbar__button__image {
      margin: 9px;
      transition: transform 0.5s ease;
    }

    .admin-navbar__company {
      gap: 10px;
      position: relative;

      .admin-navbar__company__container {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 10px;

        .admin-navbar__company__image {
          width: 32px;
          height: 32px;
        }

        .admin-navbar__company__text {
          margin-bottom: 0;
          color: #151516;
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .admin-navbar__dropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 356px;
        height: 233px;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid #d1d1d1;
        padding: 20px 24px;
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
          0px 1px 2px 0px rgba(16, 24, 40, 0.06);
        bottom: -240px;
        left: -117px;
        gap: 18px;
        color: #6f7285;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        z-index: 11;

        .dropdown-divider {
          margin: 5px auto;
          background: #d1d1d1;
          height: 1px;
          width: 100%;
          min-height: 1px;
        }

        .admin-navbar__dropdown__logout {
          display: flex;
          gap: 12px;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
          }
        }

        .admin-navbar__dropdown__links {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #6f7285;
          margin-bottom: 0px;
          gap: 8px;

          .admin-navbar__dropdown__status {
            border-radius: 37px;
            border: 1px solid #d1d1d1;
            background: #e9e9e9;
            color: #000;
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            height: 18px;
          }
        }
      }
    }

    .admin-navbar__notifications__container {
      .admin-navbar__notifications {
        margin: 9px;
      }

      .admin-navbar__notifications__dropdown {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .admin-navbar {
    display: flex;
    justify-content: center;

    .admin-navbar__container {
      justify-content: flex-end;
      width: 100%;
      max-width: 994px;
      gap: 32px;

      .admin-navbar__link {
        display: flex;
      }

      .admin-navbar__button__image {
        display: none;
      }

      .admin-navbar__notifications__container {
        position: relative;
        .admin-navbar__notifications {
          margin: 9px;
        }

        .admin-navbar__notifications__dropdown {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 320px;
          border-radius: 12px;
          border: 1px solid #d1d1d1;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
            0px 1px 2px 0px rgba(16, 24, 40, 0.06);
          top: 44px;
          right: -50%;
          z-index: 11;

          .admin-navbar__notifications__dropdown__title_container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            padding: 20px 24px 0;

            .admin-navbar__notifications__dropdown__title {
              color: #151516;
              font-family: "Montserrat";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 0px;
            }

            span {
              color: #b72446;
              font-family: "Montserrat";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
            }
          }

          .admin-navbar__all__notification__container {
            max-height: 427px;
            overflow: scroll;
            padding: 0 24px;
          }
        }
      }
    }
  }
}

.admin-navbar__link {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  p {
    margin-bottom: 0;
    color: #4a4a57;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  .admin-navbar__link__logo {
    width: 24px;
    height: 24px;
  }
  .admin-navbar__link__open {
    width: 18px;
    height: 18px;
  }
}

.admin-navbar__notification__container__empty {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 4px;

  .admin-navbar__notification__container__empty__icon {
    margin: 16px 0 12px;
  }

  h4 {
    color: #151516;
    text-align: center;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    color: #4a4a57;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.admin-navbar__notification__container {
  .admin-navbar__notification {
    display: flex;
    gap: 9px;
    position: relative;

    .admin-navbar__notification__status {
      width: 10px;
      top: 0;
      left: -15px;
      position: absolute;
    }

    .admin-navbar__notification__info__container {
      .admin-navbar__notification__user {
        display: flex;
        gap: 8px;

        .admin-navbar__notification__user__name {
          color: #4a4a57;
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          margin-bottom: 0px;
        }

        .admin-navbar__notification__user__time {
          color: #6f7285;
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: 400;
          display: flex;
          line-height: 18px;
          margin-bottom: 0px;
        }
      }

      .admin-navbar__notification__information {
        color: #4a4a57;
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0px;
      }
    }
  }

  .dropdown-divider {
    margin: 16px auto;
    background: #d1d1d1;
    height: 1px;
    width: 100%;
  }
}
