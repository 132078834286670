.modal__notifications__dropdown__container {
  .modal-dialog {
    display: flex;
    align-items: center;
    height: 100%;

    .modal-content {
      width: fit-content;
      margin: 0 auto;

      .modal__notifications__dropdown {
        display: flex;
        flex-direction: column;
        width: 320px;
        border-radius: 12px;
        border: 1px solid #d1d1d1;

        .modal__notifications__dropdown__title_container {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          padding: 20px 24px 0;

          .modal__notifications__dropdown__title {
            color: #151516;
            font-family: "Montserrat";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0px;
          }

          span {
            color: #b72446;
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .modal__all_notification__container {
          max-height: 427px;
          overflow: scroll;
          padding: 0 24px;
        }
      }
    }
  }
}
