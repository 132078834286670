.client-table {
  height: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: var(--radius-xl, 12px);
  background: var(--Colors-Utility-colors-Secondary-background, #fff);
  color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  .MuiTablePagination-displayedRows {
    margin-bottom: 0;
  }

  .MuiTablePagination-selectLabel {
    margin-bottom: 0;
  }

  .btn-options {
    background-color: transparent;
    border: 0px;
  }
}
