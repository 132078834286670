.profile-linker {
  text-decoration: none;
  display: flex;
  width: 100%;
  padding: 24px 0;
  align-items: center;
  gap: 16px;

  .user-image {
    width: 74px;
    height: 74px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 0;
  }

  .user-info {
    color: var(--Utility-Colors-Primary-Text);
    .name {
      font-family: "Montserrat";
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 11px;
    }

    .watch {
      font-family: "Montserrat";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .arrow-right {
    margin-left: 10px;
  }
}

.card-become-host {
  border-radius: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
  padding: 16px 12px;
  background: var(--Utility-Colors-Secondary-Background);
  margin: 20px 0;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1),
    0.5px 0px 4px 0px rgba(0, 0, 0, 0.1);

  .car-become-host__info {
    .title {
      color: var(--Utility-Colors-Primary-Text);
      font-family: "Montserrat";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 7px;
    }
    .info {
      color: var(--Utility-Colors-Primary-Text);
      font-family: "Montserrat";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .profit-image {
    width: 75px;
    height: 57px;
  }
}

.card-become-host-desktop {
  background: var(--Utility-Colors-Primary-Background);
}

.user-type-toggle {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 32px 0;
  align-items: center;

  .switch {
    width: 40px;
    height: 18px;
  }

  .driver,
  .host {
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 0;
    color: var(--Utility-Colors-Secondary-Text);
  }
}
