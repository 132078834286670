.admin-sidebar {
  position: absolute;
  height: calc(100% - 64px);
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  transition: left 0.5s ease, transform 0.5s ease;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 12;

  .admin-sidebar__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 20px;

    .admin-sidebar__body__dropdown__container {
      position: relative;
      width: 100%;
      .admin-sidebar__body__dropdown__button {
        display: flex;
        width: 100%;
        padding: 8px;
        height: 56px;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        border: 1px solid #d1d1d1;
        background: #fff;

        .admin-sidebar__body__dropdown__button__section {
          display: flex;
          gap: 8px;
          align-items: center;

          .admin-sidebar__body__dropdown__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            border: 1px solid #d1d1d1;
            background: #b72446;
            width: 40px;
            height: 40px;
          }

          .admin-sidebar__body__dropdown__title {
            color: #4a4a57;
            font-family: "Montserrat";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
      }

      .admin-sidebar__body__dropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 392px;
        border-radius: 12px;
        border: 1px solid #d1d1d1;
        padding: 20px 17px;
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
          0px 1px 2px 0px rgba(16, 24, 40, 0.06);
        bottom: -402px;
        z-index: 11;
        gap: 12px;

        .admin-sidebar__body__dropdown__section {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 12px;
          gap: 6px;

          .admin-sidebar__body__dropdown__section__icons {
            width: 16px;
            height: 16px;
            filter: invert(46%) sepia(7%) saturate(981%) hue-rotate(194deg)
              brightness(94%) contrast(85%);
          }

          .admin-sidebar__body__dropdown__section__title {
            color: #6f7285;
            font-family: "Montserrat";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0px;
          }
        }

        .admin-sidebar__body__dropdown__section.active {
          border-radius: 8px;
          background: #b72446;

          .admin-sidebar__body__dropdown__section__icons {
            filter: brightness(1000%);
          }

          .admin-sidebar__body__dropdown__section__title {
            color: #fff;
          }
        }
      }
    }

    .admin-sidebar-menu__button__container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      margin-top: 20px;

      .admin-sidebar-menu__button {
        display: flex;
        color: #000;
        width: 90%;
        font-family: "Montserrat";
        text-decoration: none;
        font-size: 16px;
        font-style: normal;
        padding: 6px 12px;
        cursor: pointer;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        font-weight: 400;
        justify-content: space-between;

        .admin-sidebar__button__content {
          display: flex;
          align-items: center;
          gap: 8px;

          .admin-sidebar__status {
            border-radius: 37px;
            border: 1px solid #d1d1d1;
            background: #e9e9e9;
            color: #000;
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            height: 18px;
          }
        }
      }

      .admin-sidebar-menu__button.active {
        font-weight: 600;
        background: linear-gradient(90deg, #b72446 0%, #a21398 100%);
        color: #fff;

        .turn {
          transform: rotate(-180deg);
          transition: transform 0.6s ease-in-out;
          filter: invert(100%) brightness(1000%);
        }
      }

      .admin-sidebar-menu__subroute__container {
        display: flex;
        align-items: center;
        padding-left: 20px;

        .admin-sidebar-menu__subroute {
          display: flex;
          color: #000;
          font-family: "Montserrat";
          text-decoration: none;
          font-size: 16px;
          font-style: normal;
          padding: 6px 12px;
          cursor: pointer;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          font-weight: 400;
          justify-content: space-between;

          .admin-sidebar__status {
            border-radius: 37px;
            border: 1px solid #d1d1d1;
            background: #e9e9e9;
            color: #000;
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            height: 18px;
          }
        }

        .admin-sidebar-menu__button__list {
          width: 4px;
          height: 19px;
          border-radius: 0px 9999px 9999px 0px;
          background: #b72446;
        }
      }
    }
  }

  .admin-sidebar__footer {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px 36px 32px;
    gap: 20px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 90%; // Adjust this value to set the border length
      height: 2px; // Border thickness
      background-color: #d1d1d1;
      transform: translateX(-50%);
    }

    .admin-sidebar__footer__support {
      display: flex;
      gap: 12px;
      color: #6f7285;
      font-family: "Montserrat";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    .power {
      width: 59.576px;
    }
  }
}
