.car-domain-status-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .car-domain-status-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 350px;
    border-radius: var(--radius-xl, 12px);
    background: var(--Colors-Utility-colors-Secondary-background, #fff);
    padding: var(--spacing-xl, 16px) var(--spacing-xl, 16px)
      var(--spacing-3xl, 24px) var(--spacing-xl, 16px);
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);

    .car-domain-status-header {
      display: flex;
      justify-content: space-between;

      .car-domain-status-container__title {
        color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
        font-family: "Montserrat";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }
    }

    .car-domain-status-body {
      display: flex;
      flex-direction: column;

      .car-domain-status-body__subtitles {
        h5 {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }
        p {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .car-domain-status-body__container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .car-domain-status-body__romio,
        .car-domain-status-body__power {
          .car-domain-status-body__status {
            display: flex;
            height: 40px;
            margin-bottom: 20px;
            justify-content: center;
            align-items: center;
            gap: var(--spacing-lg, 12px);
            border-radius: 12px;
            background: var(--Colors-Utility-colors-Secondary-background, #fff);
            box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
              0px 1px 2px 0px rgba(16, 24, 40, 0.06);

            .car-domain-status-body__status__logo {
              width: 80px;
            }

            .car-domain-status-body__status__chip {
              display: flex;
              align-items: center;
              gap: 4px;

              .car-domain-status-body__status__icon {
                width: 10px;
                height: 10px;
              }

              p {
                color: var(--Colors-Utility-colors-Primary-text, #151516);
                font-family: "Montserrat";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 0;
              }
            }
          }

          .car-domain-status-body__doc__status {
            display: flex;
            flex-direction: column;
            row-gap: 13px;
            padding: 9px 0;

            .car-domain-status-body__status__item {
              display: flex;
              align-items: center;
              gap: 10px;

              img {
                margin: 0 !important;
              }

              p {
                color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                font-family: "Montserrat";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 0;
              }
            }

            .car-domain-status-body__status__divider {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(--Colors-Utility-colors-Tertiary-text, #6f7285);
              font-family: "Montserrat";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              gap: 12px;
            }
          }

          .car-domain-status-body__info {
            color: var(--Colors-Utility-colors-Tertiary-text, #6f7285);
            font-family: "Montserrat";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .car-domain-status-modal {
    padding: 30px 80px;

    .car-domain-status-container {
      width: 658px;

      .car-domain-status-body {
        .car-domain-status-body__container {
          flex-direction: row;
          gap: 0;

          .car-domain-status-body__power,
          .car-domain-status-body__romio {
            width: 50%;
            padding: 0 20px;

            .car-domain-status-body__info {
              margin-top: 20px;
            }
          }

          .car-domain-status-body__power {
            border-right: 1px solid #d1d1d1;
          }
        }
      }
    }
  }
}
