.responsibilities-container-banner {
  .responsibilities-summary {
    .card {
      background: var(--Utility-Colors-Secondary-Background);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 27px;
      padding: 16px 24px 16px 24px;
      border: none;

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 24px;
      }

      .card-body {
        display: flex;
        flex-wrap: wrap;
        background-color: transparent;
        padding: 0px;

        .title {
          font-family: "Montserrat";
          font-weight: 700;
          font-size: 20px;
          line-height: 24.38px;
          text-align: left;
          color: var(--Utility-Colors-Primary-Text);
          margin-bottom: 24px;
        }

        .main-information {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 38px;
          color: var(--Utility-Colors-Secondary-Text);
        }
      
        .know-more {
          display: flex;
          gap: 10px;
          width: 100%;

          a {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            text-decoration-line: underline;
            color: var(--Semantic-Colors-Link);
            text-underline-offset: 3px;
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1108px) {
  .responsibilities-container-banner {
    padding-top: 59px;

    .responsibilities-summary {
      flex-direction: column;

      .card {
        display: flex;
        flex-direction: row;
        margin-bottom: 0;
        padding: 44.5px 64px 44.5px 32px;
        gap: 48px;

        .image-container {
          margin: 0;
        }

        .card-body {
          .main-information {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
