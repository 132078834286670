.kyc-finish {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 90px 0 116px;

  .kyc-information {
    width: 90%;
    max-width: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .kyc-image {
      width: 230px;
      height: 193.464px;
    }

    .title {
      font-family: "Montserrat";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
    }

    .subtitle {
      font-family: "Montserrat";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      margin-bottom: 40px;
    }
  }
}

@media only screen and (min-width: 991px) {
  .kyc-finish {
    .kyc-information {
      .kyc-image {
        width: 250px;
        height: 213.464px;
      }

      .title {
        font-size: 32px;
      }

      .subtitle {
        font-size: 22px;
        margin-bottom: 24px;
      }
    }
  }
}
