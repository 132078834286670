.responsibilities-host-container-banner {
  .responsibilities-summary {
    .card {
      background: var(--Utility-Colors-Secondary-Background);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 27px;
      padding: 16px 24px 16px 24px;
      width: 100%;
      border: none;

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 24px;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        padding: 0px;

        .title {
          font-family: "Montserrat";
          font-weight: 700;
          font-size: 20px;
          max-width: 295px;
          color: var(--Utility-Colors-Primary-Text);
          margin-bottom: 24px;
          line-height: 24.38px;
        }

        .main-information {
          font-family: "Montserrat";
          font-style: normal;
          color: var(--Utility-Colors-Secondary-Text);
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 38px;
        }

        .know-more {
          display: flex;
          gap: 10px;

          a {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            text-decoration-line: underline;
            color: var(--Semantic-Colors-Link);
            text-underline-offset: 3px;
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1108px) {
  .responsibilities-host-container-banner {
    .responsibilities-summary {
      flex-direction: column;

      .card {
        display: flex;
        flex-direction: row;
        margin-bottom: 0;
        padding: 32px 64px 32px 32px;
        gap: 48px;

        .image-container {
          margin: 0;
        }

        .card-body {
          flex-direction: row;
          flex-wrap: wrap;

          .title {
            margin: 0;
          }

          .main-information {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
