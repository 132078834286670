.user-card {
  margin: 20px 2px 0 0;
  display: flex;
  flex-direction: column;
  background: var(--Utility-Colors-Secondary-Background);
  padding: 7px 13px 13px 13px;
  border-radius: 16px;
  max-width: 540px;

  .user-info-image-container {
    display: flex;
    .user-img {
      width: 56px;
      border-radius: 40px;
      object-fit: cover;
      height: 56px;
      margin: 10px 8px 0 0;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: left;

      .host-indicator-container {
        display: flex;
        align-items: center;
        gap: 2px;

        .hostC-indicator {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
        }

        .user-tooltip {
          white-space: pre-line;
          color: var(--Utility-Colors-Primary-Text, #FFF);
        }
      }

      .name,
      .more {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        color: var(--Utility-Colors-Primary-Text, #FFF);
      }

      .name {
        margin-bottom: 0;
        font-size: 17px;
      }

      .more {
        margin-bottom: 0;
        font-size: 12px;
      }
    }
  }

  .btn-contact-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 16px;

    .btn-contact {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      width: 136px;
      height: 38px;

      img {
        margin-bottom: 2px;
      }

      p {
        color: #fff;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        margin: 0 8px 0 0;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .user-card {
    margin-top: 32px;
    flex-direction: row;
    justify-content: space-between;

    .user-info-image-container {
      .user-info {
        .name {
          font-size: 24px;
        }

        .more {
          margin-bottom: 0;
          font-size: 12px;
        }
      }
    }

    .btn-contact-container {
      display: flex;
      justify-content: center;
      width: auto;
      margin-right: 60px;

      .btn-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        width: 136px;
        height: 38px;

        img {
          margin-bottom: 2px;
        }

        p {
          color: #fff;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          margin: 0 8px 0 0;
        }
      }
    }
  }
}
