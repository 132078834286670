.reset-password {
  width: 100%;
  position: relative;
  font-family: "Montserrat";
  background: var(--Utility-Colors-Primary-Background);

  .main {
    padding: 44px 0;
    font-family: "Montserrat";

    .title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: var(--Utility-Colors-Primary-Text);
      padding: 12px 12px 16px;
      margin: 0;
      text-align: center;
    }

    .subtitle {
      width: 90%;
      max-width: 730px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: var(--Utility-Colors-Primary-Text);
      padding-bottom: 40px;
      margin: 0;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;

      .input-form {
        display: flex;
        flex-flow: column;
        margin: 0 0 24px 0;
        width: 100%;
        max-width: 360px;

        .progress {
          width: 100%;
          background: var(--Brand-Colors-Alternate);
        }

        .password-label,
        .confirm-password-label {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: var(--Utility-Colors-Primary-Text);
          margin-bottom: 16px;
        }

        .input-group {
          border-radius: 40px;
          margin-bottom: 16px;

          input:focus {
            box-shadow: none;
            border-color: var(--Utility-Colors-Tertiary-Text);
          }

          .input-group-addon {
            border: 1px solid var(--Utility-Colors-Secondary-Background);
            background: var(--Utility-Colors-Secondary-Background);
            border-radius: 0px 40px 40px 0px;
            border-left: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15%;
            position: relative;
            right: 1px;
            height: 38px;

            img {
              width: 24px;
            }
          }

          .password-input,
          .confirm-password-input {
            padding: 10px 0 10px 16px;
            height: 38px;
            width: 85%;
            background: var(--Utility-Colors-Secondary-Background);
            border-right: 0px;
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
            border-top: 1px solid var(--Utility-Colors-Secondary-Background);
            border-left: 1px solid var(--Utility-Colors-Secondary-Background);
            border-bottom: 1px solid var(--Utility-Colors-Secondary-Background);
            color: var(--Utility-Colors-Primary-Text);
          }
        }
      }
      .button-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 24px;

        .btn-send {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: var(--Brand-Colors-White-color-fix);
          width: 100%;
          height: 52px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .reset-password {
    .main {
      padding: 90px 0 144px 0;
      .title {
        font-size: 42px;
        padding: 12px 12px 24px;
        font-weight: 600;
      }

      .subtitle {
        font-size: 20px;
        font-weight: 600;
        color: var(--Utility-Colors-Secondary-Text);
      }

      .form-container {
        .button-container {
          .btn-send {
            width: 225.52px;
          }
        }
      }
    }
  }
}
