.car-list-dd {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -40px;
  justify-content: center;
}

.dragged-dd {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.item-text-wrapper-dd {
  font-size: small;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-wrapper-dd {
  position: relative;
  flex-shrink: 0;
  display: flex;
  margin: 8px;
  cursor: grab;
  user-select: none;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #8F9CB2;
  color: #a5a6a8;
  font-weight: 500;
  font-family: "Montserrat";
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 20px;
  max-width: 220px;
  max-height: 200px;

  @media screen and (min-width: 990px) {
    flex: auto;
    max-width: 180px;
  }
}

.car-list-dd__img {
  max-width: 100%;
  height: auto;
  overflow: auto;
  display: block;
  pointer-events: none;
}

.btn-remove-dd {
  width: 20px;
  position: absolute;
  cursor: pointer;
  top: 9px;
  left: 3px;
}

.dropzone-car-dd {
  flex: 1;
  height: 150px;
  max-width: 920px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 0.125em dashed #ddd;
  color: #a5a6a8;
  font-weight: 500;
  font-family: "Montserrat";
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 20px;
  background: #ecf7f8;
  border-radius: 12px;
}

.button-dropzone {
  cursor: pointer;
  font-size: 1.11em;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  font-weight: 400;
  border: 0.0625em solid rgba(0, 0, 0, 0);
  border-radius: 0.3125em;
  background-color: #ecf7f8;
  border: none;
  color: #596178;
  font-size: 14px;
  margin: 12px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    padding-left: 4px;
    margin: 0
  }

  img {
    width: 22px;
  }
}