.btn-remove {
  position: absolute;
  cursor: pointer;
  top: 9px;
  left: 3px;
}

.dz-uploaded {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 0.125em dashed #ddd;
  color: #a5a6a8;
  font-weight: 500;
  font-family: "Montserrat";
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 20px;
  position: relative;
  max-width: 445px;
  max-height: 174px;
}

.image-preview-uploaded {
  max-width:100%;
  height:auto;
  overflow: auto;
  display: block;
}