.add-client-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .add-client-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 350px;
    border-radius: var(--radius-xl, 12px);
    background: var(--Colors-Utility-colors-Secondary-background, #fff);
    padding: var(--spacing-xl, 16px) var(--spacing-xl, 16px)
      var(--spacing-3xl, 24px) var(--spacing-xl, 16px);
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);

    .add-client-header {
      display: flex;
      justify-content: space-between;

      .add-client-header__title {
        color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
        font-family: "Montserrat";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 0;
      }

      .btn-close-container {
        .btn-close {
          width: 20px;
          height: 20px;
          padding: 0;
        }
      }
    }

    .add-client-body {
      display: flex;
      flex-direction: column;
      padding: 0 0 32px;
      gap: 20px;

      .add-client-body__subtitle {
        color: var(--Colors-Utility-colors-Tertiary-text, #6f7285);
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0;
      }

      .add-client-body__input-container {
        display: flex;
        flex-direction: column;
        gap: 6px;

        label {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }

        .MuiOutlinedInput-notchedOutline {
          border: 0;
        }

        .add-client-body__input {
          border-radius: var(--radius-xl, 12px);
          width: 100%;
          border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
          background: var(--Colors-Utility-colors-Secondary-background, #fff);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

          input {
            height: 16px;
            padding: 14px 10px;
          }
        }

        .react-tel-input {
          input {
            height: 44px;
            padding: 14px 0 14px 58px;
            border-radius: var(--radius-xl, 12px);
            width: 100%;
            border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
            background: var(--Colors-Utility-colors-Secondary-background, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }

        .add-client-body__alert {
          color: var(--Colors-Semantic-colors-Error-fg, #c62828);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    .add-client-footer {
      display: flex;
      gap: 6px;
      flex-direction: column;

      .add-client-footer__cancel {
        background: var(--Colors-Utility-colors-Secondary-background, #fff);
        color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
      }

      .add-client-footer__add {
        background: var(--Colors-Brand-colors-Primary-color, #b72446);
        color: var(--Colors-Brand-colors-White-color-fix, #fff);
      }

      .add-client-footer__cancel,
      .add-client-footer__add {
        width: 100%;
        height: 44px;
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Colors-Custom-colors-divider-fg, #d1d1d1);
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .add-client-modal {
    .add-client-container {
      width: 560px;

      .add-client-footer {
        justify-content: flex-end;
        flex-direction: row;

        .add-client-footer__cancel {
          width: 180px;
        }

        .add-client-footer__add {
          width: 200px;
        }
      }
    }
  }
}
