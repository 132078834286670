.change-email-modal {
  display: flex !important;
  align-items: center;
  .modal-content {
    background-color: var(--Utility-Colors-Primary-Background);
    width: 312px;
    height: 276px;
    box-shadow: 2px 8px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    padding: 10px 5px;
    border-radius: 20px;

    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: transparent;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        img {
          filter: var(--Custom-Colors-Svg);
        }
      }

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 210px;
        margin: 17px auto 0;
        line-height: 30px;
        color: var(--Utility-Colors-Primary-Text);
      }
    }

    .modal-body {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;

      h3 {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 18px 0 8px;
        color: var(--Utility-Colors-Primary-Text);
      }

      .input-form {
        display: flex;
        flex-flow: column;
        margin-bottom: 40px;
        width: 100%;

        input {
          background: var(--Utility-Colors-Secondary-Background);
          padding: 8px 16px 9px;
          border: 1px solid #828282;
          border-radius: 40px;
          color: var(--Utility-Colors-Primary-Text);
        }

        .input-group {
          border-radius: 40px;

          input {
            height: 39px;
            border-right: 0px;
          }

          input:focus {
            box-shadow: none;
            border-color: #828282;
          }

          .input-group-addon {
            border: 1px solid #828282;
            background: #ffffff;
            border-radius: 0px 40px 40px 0px;
            border-left: 0px;
            height: 39px;
          }
        }

        input::placeholder {
          font-size: 14px;
          line-height: 24px;
          color: var(--Utility-Colors-Secondary-Text);
        }

        input:focus,
        .input-group:focus-within {
          border-color: #828282;
          box-shadow: 0px 4px 18px rgba(209, 217, 230, 0.8);
        }

        input:active,
        button:active,
        input:hover,
        button:hover,
        input:focus,
        button:focus {
          outline: none !important;
        }

        p {
          font-size: 13px;
          line-height: 19px;
          color: #818a91;
        }

        input::placeholder,
        p {
          font-weight: normal;
        }

        label,
        p,
        input::placeholder {
          font-family: "Montserrat";
        }

        .email-input {
          width: 100%;
          text-align: center;
        }
      }
    }

    .modal-body,
    .modal-footer {
      padding-top: 0;
    }

    .modal-header,
    .modal-footer {
      border: 0px;
    }

    .modal-footer,
    .modal-body {
      text-align: center;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 40px;

      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 272px;
        height: 38px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;

        p {
          color: #fff;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          margin: 0 11px 0 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .change-email-modal {
    .modal-content {
      width: 540px;
      height: 273px;

      .modal-header {
        h2 {
          font-size: 20px;
          line-height: 30px;
          width: 373px;
          margin-top: 7px;
        }
      }

      .modal-body {
        padding: 0 48px;

        h3 {
          margin: 16px 0 8px;
        }

        .input-form {
          margin-bottom: 32px;

          .bootstrap-select:not([class*="col-"]):not(
              [class*="form-control"]
            ):not(.input-group-btn) {
            width: 126px;
          }
        }
      }

      .modal-footer {
        padding-bottom: 50px;

        .btn-orange {
          width: 223px;
        }
      }
    }
  }
}
