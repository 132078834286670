.checkboxes-show-host-driver {
  display: flex;
  flex-direction: column;
  margin-top: 37px;

  .checkbox-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 400px;
    align-self: flex-end;

    .check-error {
      position: relative;
      display: flex;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #eb5757;
      margin: 0 0 0 9px;
    }

    .check-terms-form {
      margin: 0;
      .label-terms {
        display: flex;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        p {
          margin-bottom: 0px;
        }
      }
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .btn-des {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .btn-red,
    .btn-green {
      width: 210px;
      height: 52px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      border-radius: 40px;
      color: #ffffff;
    }

    .btn-red {
      background: #eb5757;
    }

    .btn-green {
      background: #219653;
    }
  }

  .warning-host-pending {
    display: flex;
    align-items: center;
    flex-flow: column;
    margin: 20px 0;

    .alert-triangle {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      padding: 0 0 5px;
      top: 10px;
      right: 50%;
      position: relative;

      img {
        width: 100%;
      }
    }

    .text-container {
      background: rgba(242, 201, 76, 0.5);
      border-radius: 8px;
      margin: 0 auto;
      width: 100%;
      height: 59px;
      padding: 6px;

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        margin-bottom: 0;
        opacity: 1;
      }
    }
  }
}
