.dropdown-checkboxes {
  position: relative;

  .dropdown-checkboxes__button {
    display: flex;
    align-items: center;
    height: 40px;
    gap: 6px;
    padding: 10px 14px;
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Custom-colors-divider-fg, #d1d1d1);
    background: var(--Colors-Utility-colors-Secondary-background, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    img {
      margin-top: 3px;
    }
  }

  .dropdown-checkboxes__dropdown__container {
    position: absolute;
    display: flex;
    z-index: 9;
    flex-direction: column;
    padding: 16px 16px;
    row-gap: 12px;
    width: 249px;
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Colors-Custom-colors-divider-fg, #d1d1d1);
    background: var(--Colors-Utility-colors-Secondary-background, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .dropdown-checkboxes__dropdown__container__form {
      display: flex;
      margin: 0;
      height: fit-content;
      gap: 12px;

      .dropdown-checkboxes__dropdown__container__check {
        padding: 0;

        .icons {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
