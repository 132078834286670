.page-not-found {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 90px 0 116px;
  background: var(--Utility-Colors-Primary-Background);

  .error-404 {
    width: 230px;
    height: 193.464px;
  }

  .title {
    font-family: "Montserrat";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    color: var(--Utility-Colors-Primary-Text);
  }

  .subtitle {
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
    color: var(--Utility-Colors-Primary-Text);
  }

  .redirect-section {
    width: 336px;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      color: var(--Utility-Colors-Secondary-Text);
    }

    ul {
      margin-bottom: 0;
      padding: 0;
      gap: 10px;

      li {
        list-style: none;

        a {
          color: var(--Semantic-Colors-Link);
          text-underline-offset: 3px;
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .page-not-found {
    .error-404 {
      width: 356.657px;
      height: 300.001px;
    }

    .title {
      font-size: 42px;
    }

    .subtitle {
      font-size: 32px;
      margin-bottom: 24px;
    }

    .redirect-section {
      width: 536px;
      align-items: flex-start;
      text-align: start;
    }
  }
}
