.client-details-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fafafa;
  min-height: calc(100vh - 64px);
  gap: 32px;

  .client-details__join-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 21px 16px 32px 16px;

    .client-details__title-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .client-details__route {
        display: flex;
        gap: 8px;
        align-items: center;

        .client-details__route__arrow {
          filter: invert(94%) sepia(7%) saturate(226%) hue-rotate(179deg)
            brightness(92%) contrast(88%);
          width: 10px;
          height: 10px;
        }

        .client-details__route__first,
        .client-details__route__second {
          color: #475467;
          text-decoration: none;
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .client-details__title {
        font-family: "Montserrat";
        color: #151516;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
      }

      .client-details__subtitle {
        color: #4a4a57;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }

  .client-details__user__information {
    width: 95%;
    padding: 21px 16px 32px 16px;
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Custom-colors-Disabled-bg, #e9e9e9);
    background: var(--Colors-Utility-colors-Secondary-background, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .client-details__user__information__title {
      display: flex;
      justify-content: space-between;

      h2 {
        font-family: "Montserrat";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }

      .client-details__user__information__title__button {
        .btn-options {
          background-color: transparent;
          border: 0px;
        }
      }
    }
    .client-details__user__information__container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .client-details__user__profile {
        display: flex;
        gap: 16px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }

        .client-details__user__profile__info {
          display: flex;
          flex-direction: column;
          gap: 4px;

          h3 {
            color: var(--Colors-Utility-colors-Primary-text, #151516);
            font-family: "Montserrat";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            margin-bottom: 0;
          }

          p {
            color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 0;
          }
        }
      }

      .client-details__user__contact {
        h4 {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .client-details__user__contact__info {
          display: flex;
          align-items: center;
          gap: 6px;

          p {
            color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
          }

          .icon {
            width: 16px;
            height: 16px;
          }
        }
      }

      .client-details__user__additional {
        h4 {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .client-details__user__additional__info {
          display: flex;
          align-items: center;
          gap: 6px;

          p {
            color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
      }

      .status {
        display: flex;
        align-items: center;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        gap: 2px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .client-details-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: 65px;
    gap: 24px;

    .client-details__join-container {
      max-width: 1254px;
      width: 100%;
      margin: 0;
      padding: 32px 0 24px;

      .title-container {
        text-align: left;

        h1 {
          margin: 0;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 42px;
          line-height: 46px;
          color: #195154;
          padding: 44px 0 0 0;
        }
      }

      .btn-container {
        display: flex;
        align-items: flex-end;

        .btn-add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 180px;
          height: 30px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;

          p {
            margin: 0;
            padding: 0 6px 0 4px;
          }
        }
      }
    }

    .client-details__user__information {
      max-width: 1254px;
      width: 100%;
      margin: 0;

      .client-details__user__information__container {
        flex-direction: row;
        gap: 80px;
      }
    }
  }
}
