.client-options-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .client-options-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 350px;
    border-radius: var(--radius-xl, 12px);
    background: var(--Colors-Utility-colors-Secondary-background, #fff);
    padding: var(--spacing-xl, 16px) var(--spacing-xl, 16px)
      var(--spacing-3xl, 24px) var(--spacing-xl, 16px);
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    gap: 12px;

    .client-options-header {
      display: flex;
      justify-content: space-between;

      .client-options-header__title {
        color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
        font-family: "Montserrat";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    .client-options-body {
      .client-options-body__info {
        color: var(--colors-foreground-fg-secondary-700, #363f72);
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .client-options-footer {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      gap: 8px;

      .btn-cancel {
        width: 119px;
        padding: 8px 14px;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Colors-Custom-colors-divider-fg, #d1d1d1);
        background: var(--Colors-Utility-colors-Secondary-background, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }

      .btn-action {
        width: 169px;
        padding: 8px 14px;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Colors-Semantic-colors-Error-bg, #ffebee);
        background: var(--Colors-Semantic-colors-Error-fg, #c62828);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Colors-Brand-colors-White-color-fix, #fff);
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .client-options-modal {
    .client-options-container {
      width: 383px;
    }
  }
}
