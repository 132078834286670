.add-pay-modal {
  .modal-dialog {
    height: 100%;
    max-width: inherit;
  }

  @media (max-width: 1250px) {
    .modal-dialog {
      max-width: 390px;
    }

    .modal-content {
      width: 390px;
      max-width: 390px;
    }
  }

  .modal-content {
    margin: 0 auto;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;
    width: 548px;

    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }
    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;
      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 50%;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
        }
      }
      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 207px;
        margin: 0 auto;
        line-height: 30px;
        padding-bottom: 8px;
      }
    }
    .modal-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 24px;
      margin: 24px;

      .modal-card-input {
        width: 100%;
        padding: 10px;
        font-size: 16px;
      }
      .input-label {
        font-size: 0.93rem;
        font-weight: normal;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        margin: 0.25rem 0;
      }
      .input {
        border: 1px solid #e6e6e6;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03),
          0px 3px 6px rgba(0, 0, 0, 0.02);
      }
    }
    .modal-body,
    .modal-footer {
      padding-top: 0;
    }
    .modal-header,
    .modal-footer {
      border: 0px;
    }
    .modal-footer,
    .modal-body {
      text-align: center;
    }
    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 44px;
      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 38px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        color: #fff;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        margin: 0 11px 0 0;
      }
    }
    .modal-error {
      color: #d02a2a;
      text-align: center;
    }
  }
}
