.nav-filter-container {
  position: relative;
  background: var(--Utility-Colors-Primary-Background);

  .nav-desktop-join {
    display: none;
  }

  .nav-join {
    display: flex;
    flex-flow: column;
    background: var(--Utility-Colors-Primary-Background);
    padding: 16px 32px 24px;

    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      overflow: hidden;
      font-size: 16px;
      line-height: 20px;
      margin-top: 0;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      margin-bottom: 0;
    }

    h2,
    p {
      color: var(--Utility-Colors-Primary-Text);
    }

    img {
      width: 15px;
      margin-bottom: 10px;
      filter: var(--Custom-Colors-Svg);
    }

    .w-border {
      border-bottom: 1px solid rgba(240, 242, 243, 0.12);
      padding-bottom: 5px;
    }
  }

  .nav-filter {
    display: flex;
    position: absolute;
    width: 100%;
    top: 0;

    .un-form {
      position: absolute;
      width: 100%;
      border: 0px;
      background-color: transparent;
      z-index: 1;

      .card-body {
        border-radius: 0px 0px 60px 60px;
        box-shadow: 0px 8px 25px rgba(73, 73, 73, 0.24);
        width: 100%;
        background-color: var(--Brand-Colors-White-color-fix);
        padding: 40px 24px;

        .pac-target-input {
          background-color: var(--Brand-Colors-Smoke-color-fix);
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom: 1px solid #e0e0e0;
          outline: none !important;
          border-radius: 0px;
          font-family: "Montserrat";
          font-style: normal;
          margin: 7px 0;
          padding-bottom: 4px;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          height: 56px;
          padding-left: 16px;

          &::placeholder {
            color: rgba(44, 44, 44, 0.86);
          }
        }

        .ubication {
          width: 100%;

        }

        .un-container-date {
          div {
            width: 100%;
            .dates-hours__container {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              .un-desde,
              .un-hasta {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .dates-hour {
                  display: flex;
                  gap: 16px;
                  .dropdown {
                    width: 50%;
                    margin-top: 10px;
                    border: none;
                    border-bottom-right-radius: 8px;
                    border-top-right-radius: 8px;
                    background: var(--Brand-Colors-Smoke-color-fix);
                    font-family: "Montserrat";

                    .MuiInputBase-root {
                      .MuiOutlinedInput-notchedOutline {
                        border: 0;
                        border-bottom: 1px solid;
                        border-radius: 0px;
                      }
                    }
                  }
                  .MuiFormControl-root {
                    width: 50%;
                    .MuiInputBase-input {
                      margin-top: 10px;
                    }
                  }
                }

                .date-text-field {
                  input {
                    background-color: var(--Brand-Colors-Smoke-color-fix);
                    border: none;
                    outline: none !important;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    padding-left: 16px;
                  }
                }
              }

              .time-icon {
                display: none;
              }

              .MuiOutlinedInput-notchedOutline {
                border: 0;
              }
            }
          }
        }

        .btn-orange {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--Brand-Colors-Black-color-fix);
          border-radius: 40px;
          margin: 35px auto;
          color: var(--Brand-Colors-White-color-fix);
          width: 248px;
          height: 52px;
          font-weight: 700;
          width: 100%;
          font-family: "Montserrat";
        }

        label,
        a {
          margin-top: 5%;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
        }

        .date {
          margin-bottom: 4px;
        }

        .un-desde,
        .un-hasta {
          margin-top: 20px;

          .form-group {
            input {
              box-shadow: none;
              text-align: start;
            }
          }
        }

        .un-date {
          width: 47%;
        }
      }
    }
  }
}

@media only screen and (min-width: 500px) {
  .nav-filter-container {
    .nav-filter {
      .un-form {
        .card-body {
          display: flex;
          flex-wrap: wrap;

          label,
          a {
            margin-top: 0px;
          }

          .ubication {
            margin: 20px 0 0;
          }

          .btn-orange {
            margin: 14px auto;
          }
          .un-container-date {
            .date-text-field {
              .dates-hours__container {
                .un-desde,
                .un-hasta {
                  width: 70%;
                  margin: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .nav-filter-container {
    .nav-filter {
      .un-form {
        .card-body {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .ubication {
            width: 80%;
          }

          .un-container-date {
            display: flex;
            justify-content: space-between;
            div {
              .dates-hours__container {
                .un-desde,
                .un-hasta {
                  width: 80%;
                  .dates-hour {
                    justify-content: space-between;
                    .dropdown {
                      width: 40%;
                    }
                  }
                }
              }
            }
          }

          .btn-orange {
            width: 80%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1110px) {
  .nav-filter-container {
    display: flex;
    justify-content: center;
    padding-top: 24px;

    .nav-join {
      display: none;
    }
    .nav-desktop-join {
      display: block;
      height: 91px;
      background: var(--Brand-Colors-White-color-fix);
      box-shadow: 0px 5px 20px 0px #00000026;
      padding: 14px 40px;
      border-radius: 80px;
      max-width: 1283px;
      .nav-container-join {
        display: flex;
        margin: 0 auto;
        max-width: 1110px;
        align-items: center;
        .btn-orange {
          width: 42px;
          height: 42px;
          background: var(--Brand-Colors-Black-color-fix);
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 22px;
          }
        }
        label,
        a {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          color: var(--Brand-Colors-Black-color-fix);
        }
        input,
        .ant-picker,
        .dropdown-toggle {
          background-color: var(--Brand-Colors-White-color-fix);
          outline: none !important;
          border: 0px;
          border-radius: 0px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          padding: 0;
          .bs-caret {
            display: none;
          }
        }

        #txtUbication {
          margin-top: 15px;
        }

        input::placeholder {
          color: #333333;
        }
        .nav-desde,
        .nav-hasta {
          border-left: 1px solid rgba(219, 219, 219, 0.6);
          padding: 0 50px 0 32px;

          .MuiInputBase-root {
            font-family: "Montserrat";

            #time-desktop-right,
            #time-desktop-left {
              padding: 0 32px 0 0;
            }
          }
          .form-group {
            input {
              box-shadow: none;
              text-align: start;
            }
          }
        }

        .time-icon {
          display: none;
        }

        .datepicker {
          width: 46%;
          margin-right: 50px;
          input {
            width: 100%;
            color: var(--Brand-Colors-Black-color-fix);
          }
        }

        .MuiFormControl-root {
          width: 35%;
          .MuiOutlinedInput-input {
            height: 40px;
          }
          .MuiOutlinedInput-notchedOutline {
            border: 0px;
          }
        }

        .donde,
        .nav-desde,
        .nav-hasta {
          width: 350px;
        }
        .o-container-date {
          display: flex;
          justify-content: space-around;
          .MuiOutlinedInput-notchedOutline {
            border: 0;
          }
        }
      }
    }
  }
}
