.signup-container {
  background-color: var(--Utility-Colors-Primary-Background);
  display: flex;
  flex-flow: column;
  align-items: center;

  .join-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 40px;
    gap: 40px;

    .title-container {
      text-align: center;
      width: 312px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        color: var(--Utility-Colors-Primary-Text);
        font-family: "Montserrat";
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
      }

      .logo-signup {
        width: 182.68px;
        height: 60px;
      }

      p {
        font-family: "Montserrat";
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--Utility-Colors-Primary-Text);
      }
    }

    img {
      width: 312px;
    }

    a {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }

  .signup-form {
    padding: 40px 0px 80px;
    max-width: 478px;
    width: 312px;

    .input-form {
      display: flex;
      flex-flow: column;
      margin: 3px 0 24px;

      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--Utility-Colors-Secondary-Text);
      }

      p {
        color: var(--Utility-Colors-Secondary-Text);
      }

      input {
        background: var(--Utility-Colors-Secondary-Background);
        padding: 8px 16px 9px;
        border: 1px solid #828282;
        border-radius: 40px;
        color: var(--Utility-Colors-Primary-Text);
      }

      .input-group {
        border-radius: 40px;

        input {
          height: 39px;
          border-right: 0px !important;
        }

        input:focus {
          box-shadow: none;
          border-color: #828282;
        }

        .input-group-addon {
          border: 1px solid #828282;
          background: var(--Utility-Colors-Secondary-Background);
          border-radius: 0px 40px 40px 0px;
          border-left: 0px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15%;
          position: relative;
          right: 1px;
        }
      }

      input::placeholder {
        font-size: 14px;
        line-height: 24px;
        color: var(--Utility-Colors-Tertiary-Text);
      }

      input:focus,
      .input-group:focus-within {
        border-color: #828282;
        box-shadow: 0px 4px 18px rgba(209, 217, 230, 0.8);
      }

      input:active,
      input:hover,
      input:focus {
        outline: none !important;
      }

      p {
        font-size: 13px;
        line-height: 19px;
        color: #818a91;
      }

      input::placeholder,
      p {
        font-weight: normal;
      }

      label,
      p,
      input::placeholder {
        font-family: "Montserrat";
        font-weight: normal;
      }

      .progress {
        margin-top: 16px;
      }
    }

    .button-form {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        width: 100%;
        height: 52px;
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: var(--Brand-Colors-White-color-fix);
        outline: none !important;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .signup-container {
    flex-flow: row;
    align-items: flex-start;
    justify-content: center;
    padding: 100px 0 100px;

    .join-container {
      width: 540px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      padding-top: 0;

      .title-container {
        text-align: left;
        width: 100%;
        align-items: flex-start;

        .title {
          font-size: 42px;
        }

        .logo-signup {
          width: 211.32px;
        }

        p {
          font-size: 20px;
          line-height: 30px;

          .break {
            display: block;
          }
        }
      }

      img {
        width: 100%;
      }
    }

    .signup-form {
      width: 445px;
      margin-left: 8%;

      .input-form {
        .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
            .input-group-btn
          ) {
          width: 126px;
        }

        .number-input {
          width: 100%;
        }

        .lada {
          display: none;
        }

        .lada-des {
          display: block;
          height: 38px;
          margin: 0px 5px 0 0;

          .dropdown-toggle {
            background: #f2f2f2;
            border-radius: 4px;
          }

          .bs-caret {
            display: none;
          }
        }
      }

      .button-form {
        justify-content: flex-end;

        .btn-orange {
          width: 100%;
          height: 52px;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
