.sticky-sidebar {
  display: none;
}

.black-background {
  position: absolute;
  left: 300px;
  top: 64px;
  height: calc(100% - 64px);
  width: calc(100% - 300px);
  background-color: #6f7285;
  opacity: 0.5;
  z-index: 10;
}

@media only screen and (min-width: 991px) {
  .sticky-sidebar {
    position: fixed;
    top: 0px;
    width: 58px;
    height: 100%;
    background: var(
      --Gradients-primary-topbotom,
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(180deg, #b72446 0%, #a21398 100%)
    );
    z-index: 11;
    padding: 20px 8px 16px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sticky-sidebar__header {
      display: flex;
      justify-content: flex-start;
      gap: 19px;

      .sticky-sidebar__button__image {
        margin-bottom: 41px;
        transition: transform 0.5s ease;
        width: 24px;
        height: 24px;
        color: #fff;
      }

      .dropdown-divider {
        margin: 0px auto;
        background: #d1d1d1;
        height: 1px;
        width: 100%;
      }
    }

    .sticky-sidebar__header,
    .sticky-sidebar__footer {
      display: flex;
      align-items: center;
      flex-direction: column;

      .sticky-sidebar__icons__container {
        background-color: transparent;
        padding: 9px;
        cursor: pointer;

        .sticky-sidebar__icons {
          width: 24px;
          height: 24px;
        }
      }

      .sticky-sidebar__icons__container.active {
        border-radius: 16px;
        background-color: #fff;

        .sticky-sidebar__icons {
          filter: brightness(0) saturate(100%);
        }
      }

      .sticky-sidebar__icons__container.active:hover {
        background-color: #fff;
        border-radius: 16px;
      }

      .sticky-sidebar__icons__container:hover {
        border-radius: 16px;
        background: rgba(240, 240, 240, 0.3);
      }
    }
  }
  .sticky-menu {
    position: fixed;
    justify-content: space-between;
    top: 0px;
    left: 58px;
    width: 360px;
    height: 100%;
    background: #fff;
    z-index: 12;
    padding: 32px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 4px 0px 8px -2px rgba(16, 24, 40, 0.1),
      2px 0px 4px -2px rgba(16, 24, 40, 0.06);

    .sticky-menu__section_button {
      display: flex;
      flex-direction: column;
      h3 {
        color: #151516;
        font-family: "Montserrat";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .sticky-menu__buttons__container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .sticky-menu__button {
          display: flex;
          color: #000;
          font-family: "Montserrat";
          text-decoration: none;
          font-size: 16px;
          font-style: normal;
          padding: 6px 12px;
          cursor: pointer;
          align-items: center;
          border-radius: 12px;
          font-weight: 400;
          justify-content: space-between;

          .sticky-menu__button__content {
            display: flex;
            align-items: center;
            gap: 8px;

            .sticky-menu__status {
              border-radius: 37px;
              border: 1px solid #d1d1d1;
              background: #e9e9e9;
              color: #000;
              font-family: "Montserrat";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              height: 18px;
            }
          }
        }

        .sticky-menu__button.active {
          font-weight: 600;
          background: linear-gradient(90deg, #b72446 0%, #a21398 100%);
          color: #fff;

          .turn {
            transform: rotate(-180deg);
            transition: transform 0.6s ease-in-out;
            filter: invert(100%) brightness(1000%);
          }
        }

        .sticky-menu__subroute__container {
          display: flex;
          align-items: center;
          padding-left: 20px;

          .sticky-menu__subroute {
            display: flex;
            color: #000;
            font-family: "Montserrat";
            text-decoration: none;
            font-size: 16px;
            font-style: normal;
            padding: 6px 12px;
            cursor: pointer;
            align-items: center;
            gap: 8px;
            border-radius: 12px;
            font-weight: 400;
            justify-content: space-between;

            .sticky-menu__status {
              border-radius: 37px;
              border: 1px solid #d1d1d1;
              background: #e9e9e9;
              color: #000;
              font-family: "Montserrat";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              height: 18px;
            }
          }

          .sticky-menu__button__list {
            width: 4px;
            height: 19px;
            border-radius: 0px 9999px 9999px 0px;
            background: #b72446;
          }
        }
      }
    }
    .power {
      width: 53.752px;
    }
  }
  .black-background {
    position: absolute;
    left: 300px;
    top: 64px;
    height: calc(100% - 64px);
    width: calc(100% - 300px);
    background-color: #6f7285;
    opacity: 0.5;
    z-index: 10;
  }
}
