.cancel-income-modal {
  .modal-content {
    width: 312px;
    height: 238px;
    margin: 150px auto;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;
    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }
    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;
      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 50%;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
        }
      }
      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 220px;
        margin: 0 auto;
        line-height: 30px;
      }
    }
    .modal-body {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      h3 {
        font-weight: normal;
        font-size: 16px;
        margin: 4px 0 10px;
        width: 248px;
      }
      a,
      h3 {
        font-family: "Montserrat";
        line-height: 24px;
      }
      a {
        font-weight: 500;
        font-size: 14px;
        text-decoration-line: underline;
        color: #2f80ed;
        margin-bottom: 24px;
      }
    }
    .modal-body,
    .modal-footer {
      padding-top: 0;
    }
    .modal-header,
    .modal-footer {
      border: 0px;
    }
    .modal-footer,
    .modal-body {
      text-align: center;
    }
    .modal-footer {
      display: flex;
      flex-flow: row;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 40px;
      .btn-orange,
      .btn-red {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 135px;
        height: 38px;
        padding: 0px;
        border-radius: 40px;
        p {
          color: #fff;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          margin: 0;
        }
      }
      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
      }
      .btn-red {
        background: #eb5757;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .cancel-income-modal {
    .modal-content {
      width: 540px;
      height: 208px;
      .modal-header {
        h2 {
          font-size: 20px;
          line-height: 30px;
          width: 220px;
        }
      }
      .modal-body {
        h3 {
          width: 457px;
          margin: 4px 0 2px;
        }
        a {
          margin-bottom: 30px;
        }
      }
    }
  }
}
