.complete-profile-container {
  background-color: var(--Utility-Colors-Primary-Background);
  display: flex;
  flex-flow: column;
  align-items: center;

  .join-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 40px;

    .complete-img {
      width: 200px;
    }

    .title-container {
      text-align: center;
      width: 312px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo-signup {
        width: 182.68px;
      }

      p {
        color: var(--Utility-Colors-Primary-Text);
        font-family: "Montserrat";
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }

    img {
      width: 312px;
    }

    a {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }

  .signup-form {
    padding: 40px 32px 80px;
    max-width: 478px;

    .checkbox-form {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .option {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        margin: 0 0 0 9px;
        color: var(--Utility-Colors-Secondary-Text);

        a {
          color: var(--Semantic-Colors-Link);
          text-decoration: underline;
        }
      }

      img {
        border-radius: 4px;
        width: 24px;
        height: 24px;
      }
    }

    .number-form {
      display: flex;
      flex-flow: column;
      margin: 3px 0 24px;

      .react-tel-input {
        display: flex;
        justify-content: space-between;

        .form-control {
          order: 2;
          display: flex;
          padding: 0 4px;
          border: 1px solid #828282;
          border-radius: 10px 40px 40px 10px;
          width: 80%;
          height: 41px;
          border-radius: 40px;
          background: var(--Utility-Colors-Secondary-Background);
          color: var(--Utility-Colors-Primary-Text);
        }

        .flag-dropdown {
          order: 1;
          position: relative;
          width: 15%;

          .selected-flag {
            width: 100%;
            border-radius: 40px;
            border: 2px solid var(--Brand-Colors-Secondary-Color);
            background: var(--Utility-Colors-Secondary-Background);
          }
        }
      }
    }

    .input-form {
      display: flex;
      flex-flow: column;
      margin: 3px 0 24px;

      .bootstrap-select > .dropdown-toggle.bs-placeholder {
        color: #000;
      }

      .lada-icon {
        width: 30px;
        margin-right: 5px;
      }

      .inv-input {
        width: 180px;
        height: 38px;
        text-align: center;
      }

      input {
        background: var(--Utility-Colors-Secondary-Background);
        padding: 8px 16px 9px;
        border: 1px solid #828282;
        border-radius: 40px;
      }

      .input-group {
        border-radius: 40px;

        input {
          height: 39px;
          border-right: 0px !important;
        }

        input:focus {
          box-shadow: none;
          border-color: #828282;
        }

        .input-group-addon {
          border: 1px solid #828282;
          background: #ffffff;
          border-radius: 0px 40px 40px 0px;
          border-left: 0px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15%;
          position: relative;
          right: 1px;
        }
      }

      input::placeholder {
        font-size: 14px;
        line-height: 24px;
        color: #828282;
      }

      input:focus,
      .input-group:focus-within {
        border-color: #828282;
        box-shadow: 0px 4px 18px rgba(209, 217, 230, 0.8);
      }

      input:active,
      input:hover,
      input:focus {
        outline: none !important;
      }

      input::placeholder,
      p {
        font-weight: normal;
      }

      label,
      p,
      input::placeholder {
        font-family: "Montserrat";
        font-weight: normal;
      }

      .progress {
        margin-top: 16px;
      }

      .number-input {
        width: 90%;
        text-align: center;
      }

      .lada {
        width: 95px !important;
        height: 38px;
        margin: 0px 15px 0 0;

        .selectpicker {
          display: none !important;
        }

        .dropdown-toggle {
          background: #f2f2f2;
          border-radius: 4px;
        }

        .bs-caret {
          display: none;
        }
      }

      .lada-des {
        display: none;
      }
    }

    .number-form,
    .input-form {
      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--Utility-Colors-Primary-Text);
        margin-bottom: 16px;
      }

      .alert-text {
        color: var(--Utility-Colors-Secondary-Text);
        font-size: 13px;
        line-height: 19px;
      }
    }

    .button-form {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .btn-orange {
        background: #828282;
        border-radius: 40px;
        width: 100%;
        height: 52px;
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        outline: none !important;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .complete-profile-container {
    flex-flow: row;
    align-items: flex-start;
    justify-content: center;
    padding-top: 100px;

    .join-container {
      width: 540px;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding-top: 0;

      .complete-img {
        width: 400px;
      }

      .title-container {
        width: 100%;
        text-align: left;

        .logo-signup {
          width: 211.32px;
        }

        p {
          font-size: 20px;
          line-height: 30px;
        }
      }

      img {
        width: 100%;
      }
    }

    .signup-form {
      width: 500px;
      margin-left: 8%;

      .input-form {
        .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
            .input-group-btn
          ) {
          width: 126px;
        }

        .number-input {
          width: 100%;
        }

        .lada {
          display: none;
        }

        .lada-des {
          display: block;
          height: 38px;
          margin: 0px 5px 0 0;

          .dropdown-toggle {
            background: #f2f2f2;
            border-radius: 4px;
          }

          .bs-caret {
            display: none;
          }
        }
      }

      .button-form {
        justify-content: flex-end;

        .btn-orange {
          width: 100%;
          height: 52px;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
