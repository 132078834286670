.car-insurance-modal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: scroll;

  .car-insurance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;
    padding: var(--spacing-3xl, 24px) var(--spacing-xl, 16px)
      var(--spacing-3xl, 24px) var(--spacing-xl, 16px);
    width: 350px;

    .car-data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .car-data__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h4 {
          font-family: "Montserrat";
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          text-align: center;
          color: #195154;
          margin: 0;
        }
      }
      .car-data-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 15px 0;

        .car-data-items__logo__container {
          margin: 24px 0;
          display: flex;
          flex-direction: column;
          gap: 12px;

          p {
            color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
            font-family: "Montserrat";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
          }

          img {
            width: 110px;
          }
        }

        .car-data-items__prices,
        .car-data-items__main__info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          p {
            font-family: "Montserrat";
            margin-bottom: 8px;

            span {
              font-weight: 700;
            }
          }
        }
      }

      .help {
        color: var(--Colors-Utility-colors-Tertiary-text, #6f7285);
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .list {
      border-radius: 8px;

      p {
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #195154;
        margin-top: 16px;
      }
      .divider {
        border-bottom: 1px solid #e0e0e0;
        margin: 12px 0;
        width: 100%;
      }

      ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 12px;

        li {
          font-family: "Montserrat";
          font-size: 16px;
          line-height: 24px;
          list-style: none;

          span {
            display: flex;
            width: 100%;
            color: var(--Colors-Utility-colors-Tertiary-text, #6f7285);
          }

          p {
            font-weight: 700;
            margin: 0;
          }
        }
      }
      .own-insurance {
        p {
          font-family: "Montserrat";
          font-size: 16px;
          line-height: 24px;
          font-style: normal;
          font-weight: 400;
          text-align: left;
        }

        .link-insurance {
          display: flex;
          justify-content: center;
          margin: 24px 0;
          svg {
            color: #3cb2b9;
          }
          button {
            border: none;
            background: inherit;
            font-family: "Montserrat";
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #3cb2b9;
          }
        }
      }
    }
  }

  .car-data-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    max-width: 800px;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;
    padding: 24px 60px;
    max-height: 280px;

    .car-data__title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        color: #195154;
        margin: 24px 0;
      }
    }

    p {
      font-family: "Montserrat";
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media only screen and (min-width: 991px) {
  .car-insurance-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .car-insurance-container {
      width: 660px;
      .car-data-items {
        div {
          display: flex;
          justify-content: center;
        }
      }
      .list {
        margin: 0;
        ul {
          li {
            display: flex;
            gap: 5px;
            span {
              width: inherit;
            }
          }
        }
      }
      max-width: 800px;

      .help {
        max-width: 600px;
      }
    }

    .car-data-empty {
      max-height: 220px;
    }
  }
}
