.first-message-modal {
  .modal-content {
    width: 340px;
    height: 720px;
    margin: 100px auto;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;

    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 50%;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
        }
      }

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 207px;
        margin: 0 auto 4px;
        line-height: 30px;
        color: var(--Utility-Colors-Primary-Text);
      }

      .title {
        margin: 0;
        text-align: left;
        width: 100%;
        max-width: 400px;
        margin: 10px 0 12px;
      }

      .sent-message-title {
        display: flex;
        margin: 20px 0 32px;
        max-width: 420px;

        .sent-title {
          display: flex;
          flex-direction: column;
          text-align: left;

          h2 {
            margin: 0 0 16px;
          }

          p {
            color: var(--Utility-Colors-Primary-Text);
            font-family: "Montserrat";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            max-width: 300px;
          }
        }

        .user-img {
          width: 56px;
          border-radius: 40px;
          object-fit: cover;
          height: 56px;
          margin: 10px 8px 0 0;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 0 12px;

      .card-container {
        width: 100%;
      }

      .card-car-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 30px 0 16px;
        gap: 44px;

        .card-information {
          display: flex;
          flex-direction: column;
          text-align: left;

          span,
          p {
            font-family: "Montserrat";
            font-size: 12px;
            font-weight: 800;
          }

          p {
            margin-bottom: 0;
          }

          span {
            margin-bottom: 9px;
          }

          .btn-reserve {
            background: var(--Brand-Colors-Primary-Color);
            border-radius: 40px;
            margin: auto;
            width: 122px;
            height: 30px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            color: #fff;
            font-size: 12px;
          }
        }

        .card-image {
          img {
            width: 122.811px;
            height: 64px;
            border-radius: 8px;
          }
        }
      }
    }

    .modal-body,
    .modal-footer {
      padding-top: 0;
    }

    .modal-header,
    .modal-footer {
      border: 0px;
    }

    .modal-footer,
    .modal-body {
      text-align: center;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;

      .line {
        width: 90%;
        border: 1px solid var(--Custom-Colors-Lines);
      }

      .remember {
        margin: 32px 0;
        text-align: left;
        color: var(--Utility-Colors-Primary-Text);
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }

      .message-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 32px 0 88px;
        align-items: flex-start;

        .title {
          color: var(--Utility-Colors-Primary-Text);
          font-family: "Montserrat";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .message-input {
          width: 100%;
          height: 132px;
          padding: 8px 16px;
          border-radius: 16px;
          border: 1px solid var(--Utility-Colors-Tertiary-Text);
          opacity: 0.8;

          &::placeholder {
            color: var(--Utility-Colors-Primary-Text);
          }
        }
      }

      .sent-buttons {
        display: flex;
        flex-direction: column;
        gap: 31px;
      }

      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: auto;
        width: 272px;
        height: 52px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .first-message-modal {
    .modal-content {
      width: 635px;
      .modal-header {
        h2 {
          font-size: 20px;
          line-height: 24px;
          width: 373px;
        }
      }
      .modal-body {
        .card-container {
          display: flex;
          justify-content: center;

          .user-card {
            width: 60%;
          }
        }
      }
      .modal-footer {
        width: 445px;
        margin: 0 auto;
        padding-bottom: 60px;

        .btn-orange,
        .btn-create {
          width: 445px;
        }
      }
    }
  }
}
