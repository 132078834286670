.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  border-radius: 20px !important;
  max-height: 30% !important;
}
::-webkit-scrollbar {
  display: none;
}

.add-car {
  width: 100%;

  .add-cars__join-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 21px 16px 32px 16px;

    .a-cars__title-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .a-cars__route {
        display: flex;
        gap: 8px;
        align-items: center;

        .a-cars__route__arrow {
          filter: invert(94%) sepia(7%) saturate(226%) hue-rotate(179deg)
            brightness(92%) contrast(88%);
          width: 10px;
          height: 10px;
        }

        .a-cars__route__first,
        .a-cars__route__second {
          color: #475467;
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .a-cars__title {
        font-family: "Montserrat";
        color: #151516;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
      }

      .a-cars__subtitle {
        color: #4a4a57;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }

  .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
    border-radius: 20px !important;
    max-height: 30% !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .add-car-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 199px);
    padding: 40px 0;
    background-color: #fafafa;

    .model-ubication {
      width: 100%;
      padding: var(--spacing-4xl, 32px) var(--spacing-3xl, 24px);
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
      background: var(--Colors-Utility-colors-Secondary-background, #fff);
      display: flex;
      flex-flow: column;
      align-items: center;

      .form-container {
        .info-container {
          margin-bottom: 40px;

          h2 {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #195154;
          }

          h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #3cb2b9;
          }

          h2,
          h3 {
            font-family: "Montserrat";
          }

          p {
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #195154;
          }
        }

        .form {
          .generic-input {
            width: 100%;
            margin-bottom: 24px;

            .select-label {
              font-weight: 600;
              line-height: 17px;
              color: #333333;
              margin-bottom: 8px;
              font-family: "Montserrat";
            }

            .dropdown {
              background: #fff;
              border-radius: 40px;
              height: 50px;
              margin-bottom: 16px;
              width: 100%;

              span {
                font-family: "Montserrat";
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
              }
            }

            .input-text-large {
              width: 100%;
              .MuiInputBase-formControl {
                font-family: "Montserrat";
                font-size: 14px;
                width: 100%;
                background: #ffffff;
                box-sizing: border-box;
                border-radius: 40px;
                margin-bottom: 16px;
                outline: none !important;
                font-weight: normal;
                line-height: 24px;
                color: #828282;
              }
              .input-error {
                border: 1px solid #dc3545;
              }
            }

            .dropdown-goals {
              width: 100%;
              background: #ffffff;
              border-radius: var(--radius-xl, 12px);
              margin-bottom: 16px;
              height: 50px;

              span {
                font-family: "Montserrat";
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
              }
            }

            .categorie {
              display: flex;
              align-items: center;

              img {
                height: fit-content;
              }
            }
          }

          .exp {
            flex-wrap: wrap;
          }

          .file-container {
            .information {
              display: flex;
              flex-flow: column;
              padding: 32px 0;

              .generic-input {
                margin-bottom: 24px;

                label {
                  font-weight: 600;
                  line-height: 17px;
                  color: #333333;
                  margin-bottom: 16px;
                }

                .generic-text {
                  font-family: "Montserrat";
                  font-weight: normal;
                  color: #333333;
                  padding: 0px 0px 6px 6px;
                  border-bottom: 1px solid #3cb2b9;
                }

                .generic-text,
                label {
                  font-size: 14px;
                  font-family: "Montserrat";
                }
              }

              .need-modify-alert {
                margin: 0;
              }
            }
            .option-title {
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              color: #195154;
              margin: 0;
              padding: 16px 0;
            }

            .drop-row {
              width: 12px;
            }

            .drop-options-important-details {
              display: flex;
              flex-flow: column;
              padding: 32px 0;

              .generic-input {
                .dropdown {
                  background: #fff;
                  border-radius: 40px;
                  height: 50px;
                  margin-bottom: 16px;
                  width: 100%;

                  span {
                    font-family: "Montserrat";
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                  }
                }
              }
            }

            .drop-options {
              display: flex;
              flex-flow: column;
              padding: 32px 0;

              .document-generic-input {
                padding: 0;

                .extra-info-p {
                  font-family: "Montserrat";
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  margin: 0 0 5px;
                  margin: 8px 0 16px 0;
                }

                .dropzone-ui {
                  width: 302px;
                  height: 174px;
                  background: #ecf7f8;
                  border: 1px dashed #8f9cb2;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  flex-flow: column;
                  align-items: center;

                  .dz-ui-label {
                    font-family: "Monserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #596178;
                  }

                  .dz-ui-footer {
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 12px;
                    color: #373a3c;
                  }
                }

                .alert-wait {
                  font-family: Montserrat;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  margin-bottom: 0;
                  margin-top: 15px;
                }

                .upload-later {
                  display: flex;
                  align-items: center;

                  p,
                  span {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    margin-bottom: 0;
                    color: #000;
                  }
                }

                .uploader {
                  .uploader__widget-base {
                    height: 183px;
                    top: 0;
                    left: 0;
                    width: 100%;

                    label {
                      background-color: transparent;
                      border: 0px;
                      color: var(--Gris-4, #596178);
                      text-align: center;
                      font-family: "Montserrat";
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                    }
                  }

                  .uploader__widget-base--draggable {
                    border-radius: 10px;

                    border: 1px dashed
                      var(--Colors-Brand-colors-Primary-color, #b72446);
                  }
                }

                .dz-uploaded {
                  width: 100%;
                  height: 183px;
                  max-width: none;
                  border-radius: 10px;
                  border: 1px dashed
                    var(--Colors-Brand-colors-Primary-color, #b72446);
                }

                .direction-input {
                  padding-top: 8px;
                  .btn-orange-des {
                    display: none;
                  }

                  .input-text-large {
                    width: 100%;
                    border-radius: 40px;
                    border: 1px solid;
                    padding-left: 10px;
                    .MuiInputBase-formControl {
                      background: #ffffff;
                      box-sizing: border-box;
                      border-radius: 40px;
                      width: 100%;
                      height: 50px;
                      font-family: "Montserrat";
                      font-weight: normal;
                      font-size: 16px;
                      line-height: 24px;
                      color: #333333;
                      outline: none !important;
                      padding: 7px 0px 7px 16px;
                      margin-bottom: 50px;
                    }
                  }

                  .input-error {
                    border: 1px solid #dc3545;
                  }
                }

                .map {
                  height: 400px;
                  margin: 16px 0;
                }

                h3 {
                  font-family: "Montserrat";
                  font-weight: 600;
                  font-size: 14px;
                  color: #828282;
                }

                h3 {
                  font-family: "Montserrat";
                }

                .where {
                  font-weight: 600;
                  font-size: 16px;
                  padding: 8px 0;
                }

                h5 {
                  font-weight: 500;
                  font-size: 14px;
                  padding: 12px 0;
                }

                .where,
                h5 {
                  color: #828282;
                  font-family: "Montserrat";
                  line-height: 24px;
                }
              }

              .dropdown-divider {
                width: 100%;
              }

              .rules {
                display: flex;
                flex-wrap: wrap;
                padding-top: 12px;
                .rule-container {
                  label {
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #373a3c;
                  }

                  .dropdown {
                    width: 100%;
                    background: #ffffff;
                    border-radius: 40px;
                    margin-bottom: 16px;

                    span {
                      font-family: "Montserrat";
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 24px;
                    }
                  }
                }

                .generic-input {
                }
              }
            }

            .serial-number {
              padding: 20px 0 0;

              .serial-info {
                max-width: 1000px;

                p {
                  margin-bottom: 5px;
                }
              }

              .serial-container-input {
                display: flex;
                flex-direction: column;
                margin: 15px 0 15px;

                .serial-label {
                  color: #373a3c;
                  font-family: Montserrat;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  margin-bottom: 8px;
                }

                .serial-number-input {
                  width: 100%;
                  max-width: 320px;
                  border: 1px solid var(--Aqua-puntos-destacados, #3cb2b9);
                  border-radius: var(--radius-xl, 12px);

                  .MuiInputBase-root {
                    height: 38px;

                    .MuiOutlinedInput-notchedOutline {
                      border: 0;
                    }
                  }
                }
              }
            }

            .gps-inputs {
              padding: 20px 0 0;

              .gps-info {
                max-width: 920px;
                margin-bottom: 15px;

                p {
                  margin-bottom: 5px;
                }
              }

              .generic-gps-dropdown {
                label {
                  color: #373a3c;
                  font-family: Montserrat;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                }

                .gps-dropdown {
                  width: 100%;
                  max-width: 720px;
                  background: #ffffff;
                  border-radius: var(--radius-md, 8px);
                  margin-bottom: 16px;

                  .MuiSelect-select {
                    white-space: normal;
                  }

                  span {
                    font-family: "Montserrat";
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .input-error {
      border: 1px solid #dc3545;
    }

    .documentation {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 100%;
      padding: var(--spacing-4xl, 32px) var(--spacing-3xl, 24px);
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
      background: var(--Colors-Utility-colors-Secondary-background, #fff);

      .form-container {
        .info-container {
          margin-bottom: 40px;

          h2 {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #195154;
          }

          h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #3cb2b9;
          }

          h2,
          h3 {
            font-family: "Montserrat";
          }
        }

        .form {
          .file-container {
            .option-title {
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              color: #195154;
              margin: 0;
              padding: 16px 0;
            }

            .drop-row {
              width: 12px;
            }

            .drop-options {
              display: flex;
              flex-flow: column;

              .car-images-des {
                display: none;
              }

              .car-images {
                display: flex;
                flex-wrap: wrap;
                width: 328px;

                .image-name-container {
                  display: flex;
                  width: 120px;
                  flex-flow: column;
                  justify-content: center;
                  margin-right: 40px;
                  text-align: center;

                  p {
                    font-family: "Montserrat";
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 24px;
                    color: #373a3c;
                  }

                  .image-container {
                    width: 120px;
                    height: 76px;
                    margin-top: 24px;
                    position: relative;
                    background: #fff;
                    display: flex;
                    border: 1px solid #8f9cb2;
                    box-sizing: border-box;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;

                    .image-in {
                      width: auto;
                      height: 50px;
                    }

                    .btn-options {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      top: 6px;
                      position: absolute;
                      width: 24px;
                      height: 24px;
                      border-radius: 50%;
                      padding: 0;
                      background: #ffffff;
                      box-shadow: 0px 2px 8px rgba(196, 209, 237, 0.51);

                      img {
                        width: 14px;
                      }
                    }
                  }
                }
              }

              .document-generic-input {
                margin: 40px 0;
                padding: 0;

                .dropzone-ui {
                  width: 328px;
                  height: 174px;
                  background: #ecf7f8;
                  border: 1px dashed #8f9cb2;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  flex-flow: column;
                  align-items: center;

                  .dz-ui-label {
                    font-family: "Monserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #596178;
                  }

                  .dz-ui-footer {
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 12px;
                    color: #373a3c;
                  }
                }

                .verifying {
                  display: flex;
                  justify-content: space-between;

                  span {
                    color: #ffab03;
                  }

                  p {
                    color: #eb5757;
                  }

                  span,
                  p {
                    font-size: 12px;
                    line-height: 24px;
                    font-weight: 600;
                    margin-top: 5px;
                    font-family: "Montserrat";
                  }

                  img {
                    margin-right: 5px;
                  }
                }

                .input-text-large {
                  background: #ffffff;
                  border: 1px solid #cccccc;
                  box-sizing: border-box;
                  border-radius: 40px;
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;
                  color: #333333;
                  outline: none !important;
                  padding: 7px 0px 7px 16px;
                  margin-bottom: 50px;
                  width: 100%;
                }
              }

              .information {
                background: rgba(235, 87, 87, 0.22);
                border-radius: 20px;
                text-align: center;
                padding: 16px 8px;
                margin-bottom: 40px;

                li {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 19px;
                  color: #333333;
                  margin-bottom: 4px;
                }
              }

              .accesories-container {
                text-align: start;
                margin-top: 24px;

                .more-container {
                  display: flex;
                  align-items: center;
                  margin: 14px 0;

                  .more {
                    font-family: "Montserrat";
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 30px;
                    margin-bottom: 0;
                  }

                  img {
                    width: 12px;
                    margin-left: 5px;
                  }
                }

                .only-accesories {
                  .accesories-content {
                    display: flex;
                    flex-direction: column;
                    .option {
                      display: flex;
                      align-items: center;
                      margin-bottom: 30px;

                      label {
                        font-family: "Montserrat";
                        font-weight: normal;
                        font-size: 16px;
                        color: #333333;
                        margin: 0 0 0 3px;
                      }

                      img {
                        width: 20px;
                      }
                    }
                  }
                }
              }

              .message-box {
                height: 172px;
                width: 100%;
                border: 1px solid #3cb2b9;
                box-sizing: border-box;
                border-radius: 10px;
                max-height: 255px;
                min-height: 130px;
                margin: 24px auto 8px;
                padding: 17px 15px;
                font-family: "Montserrat";
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: #333333;
              }

              .input-error {
                border: 1px solid #dc3545;
              }

              .max-text {
                text-align: end;
                font-family: "Montserrat";
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #195154;
                margin-bottom: 40px;
              }

              .dropdown-divider {
                width: 100%;
              }
            }

            #accesories {
              padding: 0 0 12px 8px;
            }
          }
        }
      }
    }

    .price-disponibility {
      display: flex;
      width: 100%;
      flex-flow: column;
      align-items: center;
      padding: var(--spacing-4xl, 32px) var(--spacing-3xl, 24px);
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
      background: var(--Colors-Utility-colors-Secondary-background, #fff);

      .form-container {
        width: 100%;
        .info-container {
          margin-bottom: 40px;

          h2 {
            font-weight: bold;
            font-size: 24px;
            color: #195154;
          }

          h3 {
            font-weight: 600;
            font-size: 18px;
            color: #3cb2b9;
          }

          h2,
          h3 {
            font-family: "Montserrat";
          }
        }

        .form {
          .file-container {
            .option-title {
              margin-left: 10px;
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              color: #195154;
              margin: 0;
              padding: 16px 0;
            }

            .drop-row {
              width: 12px;
            }

            .drop-options {
              display: flex;
              flex-flow: column;
              padding-left: 0px;

              .consult-pricing {
                display: flex;
                align-items: center;
                gap: 6px;

                svg {
                  color: var(--Colors-Custom-colors-hyperlinks, #0a7df5);
                  width: 16px;
                  height: 16px;
                }

                a {
                  color: var(--Colors-Custom-colors-hyperlinks, #0a7df5);
                  font-family: "Montserrat";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  cursor: pointer;
                }

                p {
                  color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                  font-family: "Montserrat";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  margin-bottom: 0;
                }
              }

              .day-discount {
                display: flex;
                flex-direction: column;
                margin: 16px 0 20px;
                gap: 24px;

                .price-d {
                  width: 100%;
                  display: flex;
                  flex-flow: column;

                  .label-price {
                    font-family: "Montserrat";
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #373a3c;
                  }

                  .input-price {
                    width: 50%;
                    .MuiInputBase-root {
                      outline: none !important;
                      height: 50px;
                      text-align: center;
                      background: #ffffff;
                      box-sizing: border-box;
                      border-radius: var(--radius-xl, 12px);
                    }
                  }

                  .price-d__static-input {
                    padding: 10px 14px;
                    color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                    font-family: "Montserrat";
                    font-size: 16px;
                    width: 100%;
                    font-style: normal;
                    font-weight: 400;
                    padding-bottom: 10px;
                    border-bottom: 1px solid
                      var(--Colors-Brand-colors-Alternate, #d1cfcf);
                  }
                }
              }
              .day-discount-insurance {
                display: flex;
                flex-direction: column;
                margin: 16px 0 20px;
                gap: 24px;

                .price-d-insurance {
                  width: 100%;
                  display: flex;
                  flex-flow: column;

                  .label-price {
                    font-family: "Montserrat";
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #373a3c;
                  }

                  img {
                    width: 105.143px;
                  }
                }
              }

              .doubts {
                display: flex;

                span {
                  color: var(--Gray-3, #828282);
                  font-family: "Montserrat";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                }
              }

              .insurance-text-container {
                padding: 12px 0;
                p {
                  font-family: "Montserrat";
                  font-size: 16px;
                  line-height: 24px;
                  color: #373a3c;
                  font-weight: 500;
                }
              }

              .insurance-inputs {
                .charge-container,
                .deposit-amount-container,
                .insurance-name-container {
                  display: flex;
                  flex-flow: column;
                  margin: 16px 0 30px;

                  label {
                    font-family: "Montserrat";
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #373a3c;
                  }
                  .depositAmount,
                  .charge,
                  .insurance-name {
                    margin: 8px 0 15px;
                    .MuiInputBase-formControl {
                      outline: none !important;
                      height: 40px;
                      text-align: center;
                      background: #ffffff;
                      box-sizing: border-box;
                      border-radius: 40px;
                    }
                  }

                  .insurance-recommendation {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                  }
                }
              }

              .extra-info {
                text-align: center;
                margin: 32px 0 50px;

                p {
                  color: #373a3c;
                  margin-bottom: 5px;
                }

                a {
                  text-decoration: underline;
                  color: #2f80ed;
                }

                p,
                a {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 24px;
                }
              }

              .btn-container {
                display: flex;
                justify-content: flex-end;
                margin-top: 32px;

                .btn-orange {
                  width: 160px;
                  height: 38px;
                  margin: 0;
                  font-family: "Montserrat";
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 21px;
                  color: #ffffff;
                  background: var(--Brand-Colors-Primary-Color);
                  border-radius: 40px;
                }
              }

              .date-container {
                .react-datepicker {
                  .react-datepicker__month-container {
                    width: 100%;
                  }
                }

                .MuiPickerStaticWrapper-root {
                  box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
                  padding: 8px 24px;
                  border-radius: 10px;
                  .MuiDialogActions-root {
                    display: none;
                  }

                  .Mui-selected {
                    background: var(
                      --Colors-Brand-colors-Primary-color,
                      #b72446
                    );
                  }

                  .MuiDateRangePickerDay-rangeIntervalDayHighlight {
                    background: var(--Colors-Semantic-colors-Error-bg, #ffebee);
                  }

                  svg:hover {
                    color: orange;
                  }
                }
              }

              .disponibility-text-container {
                text-align: start;
                margin: 16px 0 32px;

                p {
                  font-weight: normal;
                  margin-bottom: 5px;
                }

                a {
                  font-weight: 600;
                  text-decoration: underline;
                }

                p,
                a {
                  color: #373a3c;
                  font-family: "Montserrat";
                  font-style: normal;
                  font-size: 16px;
                  line-height: 24px;
                }

                p > span {
                  font-weight: 600;
                  text-decoration: underline;
                  font-family: "Montserrat";
                  font-size: 16px;
                }
              }

              .input-dates-container {
                display: flex;
                flex-flow: column;
                justify-content: center;
                margin-bottom: 20px;

                .btn-orange {
                  width: 200px;
                  height: 38px;
                  border-radius: var(--radius-xl, 12px);
                  border: 1px solid
                    var(--Colors-Brand-colors-Alternate, #d1cfcf);
                  background: var(--Colors-Brand-colors-Primary-color, #b72446);
                  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                  margin: 32px auto 30px;
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 21px;
                  color: #ffffff;
                }

                .dates-text {
                  font-family: "Montserrat";
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 21px;
                  color: #373a3c;
                }

                .dates-table {
                  display: flex;
                  flex-flow: column;
                  align-items: center;

                  .date-container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .input-date {
                      width: 273px;
                      height: 38px;
                      background: #ffffff;
                      border: 1px solid #cccccc;
                      box-sizing: border-box;
                      outline: none !important;
                      border-radius: 40px;
                      padding: 0px 8px;
                      text-align: center;
                    }

                    p {
                      font-family: "Montserrat";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 21px;
                      color: #000;
                      margin: 10px 0;
                    }
                  }

                  .btn-erase {
                    width: 38px;
                    height: 38px;
                    background: #bdbdbd;
                    border-radius: 50%;
                    padding: 0;
                    margin: 10px 0 25px;
                  }
                }
              }

              .ubication {
                h3 {
                  font-family: "Montserrat";
                  font-weight: 600;
                  color: #373a3c;
                  margin-top: 24px;
                }

                .direction {
                  font-family: "Montserrat";
                  font-weight: normal;
                  color: #333333;
                  text-align: center;
                  border-bottom: 1px solid #cccccc;
                }

                .direction-des {
                  display: none;
                }

                h3,
                .direction {
                  font-size: 16px;
                  line-height: 24px;
                  padding: 4px 0 8px 0;
                }

                .btn-orange {
                  width: 311px;
                  height: 38px;
                  background: var(--Brand-Colors-Primary-Color);
                  border-radius: 40px;
                  margin: 25px auto 30px;
                  display: flex;
                  justify-content: center;

                  p {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 21px;
                    color: #ffffff;
                    margin-right: 4px;
                  }

                  img {
                    width: 15px;
                  }
                }

                .enable-check {
                  display: flex;

                  p {
                    font-family: "Montserrat";
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    color: #333333;
                    margin: 0 4px 0 8px;
                  }

                  .info-icon {
                    width: 15px;
                  }
                }

                span {
                  text-align: left;
                  font-family: "Montserrat";
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                  color: #828282;
                }

                .enable-main-address {
                  margin: 12px 0;
                }
              }

              .extra-option {
                h3 {
                  font-family: "Montserrat";
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 24px;
                  color: #373a3c;
                  padding-top: 16px;
                }
                .extra-option-title {
                  font-weight: bold;
                  font-family: "Montserrat";
                  margin: 0;
                  padding-bottom: 4px;
                }

                .extra-address-container {
                  flex-direction: column;
                }

                .extra-info-h3 {
                  font-family: "Montserrat";
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                  color: #828282;
                }

                .option-input {
                  width: 100%;
                  height: 45px;
                  border: 1px solid #000;
                  background: #ffffff;
                  box-sizing: border-box;
                  outline: none !important;
                  border-radius: 40px;
                  padding: 7px 0 7px 16px;
                }

                .btn-container {
                  display: flex;
                  align-items: center;
                  margin: 16px 0 30px;
                  width: 100%;

                  .btn-erase {
                    width: 38px;
                    height: 38px;
                    background: #bdbdbd;
                    border-radius: 50%;
                    padding: 0;
                  }

                  .btn-orange-des {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    margin-right: 7px;
                    background: var(--Brand-Colors-Primary-Color);
                    padding: 0;
                    color: white;
                    font-weight: bold;
                    border-radius: 40px;
                    font-family: "Montserrat";
                    padding: 8px 18px;
                    font-size: 14px;

                    img {
                      margin-right: 8px;
                    }

                    p {
                      margin: 0;
                    }
                  }

                  .btn-orange {
                    width: 274px;
                    height: 38px;
                    background: var(--Brand-Colors-Primary-Color);
                    border-radius: 40px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;

                    p {
                      font-family: "Montserrat";
                      font-style: normal;
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 21px;
                      color: #ffffff;
                      margin-right: 4px;
                    }

                    img {
                      width: 15px;
                    }
                  }
                }

                .add-price {
                  bottom: 0;
                  span {
                    font-family: "Montserrat";
                    font-size: 14px;
                    line-height: 24px;
                    color: #828282;
                    font-weight: 600;
                  }

                  .delibery-price {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 8px 0 40px;

                    .delibery-input {
                      .MuiInputBase-formControl {
                        width: 104px;
                        height: 45px;
                        background: #ffffff;
                        box-sizing: border-box;
                        border-radius: 40px;
                        outline: none !important;
                        font-family: "Montserrat";
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        color: #333333;
                        margin-right: 14px;
                      }
                    }

                    .enable-check {
                      display: flex;
                      margin-bottom: 0px;
                      width: 22ch;

                      .check-icon {
                        width: 20px;
                        height: 20px;
                      }

                      span {
                        font-family: "Montserrat";
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0;
                      }
                    }
                  }
                }

                .enable-check {
                  display: flex;
                  margin-bottom: 40px;

                  .check-icon {
                    width: 20px;
                    height: 20px;
                  }

                  p {
                    font-family: "Montserrat";
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    color: #333333;
                    margin: 0 4px 0 8px;
                  }
                }

                .add-container {
                  display: flex;
                  justify-content: space-between;
                  margin: 24px 0 60px;

                  .btn-add-direction {
                    width: 290px;
                    height: 38px;
                    display: flex;
                    justify-content: center;
                    border-radius: var(--radius-xl, 12px);
                    border: 1px solid
                      var(--Colors-Brand-colors-Alternate, #d1cfcf);
                    background: var(
                      --Colors-Brand-colors-Primary-color,
                      #b72446
                    );
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                    p {
                      font-family: "Montserrat";
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 21px;
                      color: #ffffff;
                    }

                    .plus-icon {
                      width: 24px;
                      height: 24px;
                    }
                  }
                }

                .delivery-subtitle {
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;

                  h3 {
                    margin: 0;
                    padding: 0;
                    width: fit-content;
                  }
                }

                .home-delivery {
                  width: 100%;
                  flex-direction: column;

                  .enable-home-delivery {
                    .enable-check {
                      display: flex;
                      margin: 0;
                      padding: 10px 0;
                      .check-icon {
                        width: 20px;
                        height: 20px;
                      }

                      p {
                        font-family: "Montserrat";
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: #333333;
                        margin: 0 4px 0 8px;
                      }

                      .info-icon {
                        width: 15px;
                      }
                    }
                  }
                }
              }

              .dropdown-divider {
                width: 100%;
              }

              .rules {
                .rule-container {
                  label {
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #373a3c;
                  }

                  .dropdown {
                    width: 100%;
                    background: #ffffff;
                    border-radius: 40px;
                    margin-bottom: 16px;

                    span {
                      font-family: "Montserrat";
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 24px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .principal-btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 6px;
      flex-wrap: wrap;
      box-shadow: 0px 3px 3px 0px rgba(16, 24, 40, 0.1),
        0px 2px 2px 0px rgba(16, 24, 40, 0.06);
      background: var(--Colors-Utility-colors-Secondary-background, #fff);
      padding-bottom: 32px;

      .btn-orange {
        width: 311px;
        height: 52px;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
        background: var(--Colors-Brand-colors-Primary-color, #b72446);
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }

      .btn-back {
        width: 311px;
        height: 52px;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Colors-Custom-colors-divider-fg, #d1d1d1);
        background: var(--Colors-Utility-colors-Secondary-background, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
      }
    }

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
      box-shadow: unset;
    }

    .accordion-summary {
      border-bottom: 1px solid #3cb2b9;
      padding-left: 0;

      .title-container {
        display: flex;
        flex-direction: column;
      }
    }

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
      background-color: unset;
    }
  }

  .MuiTooltip-tooltip {
    background-color: #3cb2b9 !important;
    font-size: 14px;
  }

  .Mui-completed {
    color: var(--Colors-Brand-colors-Primary-color, #b72446) !important;
  }

  .Mui-active {
    color: var(--Colors-Brand-colors-Primary-color, #b72446) !important;
  }

  .MuiStepLabel-label {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .MuiStep-root {
    padding: 0 !important;
  }

  .font-family-montserrat {
    font-family: "Montserrat";
  }

  .blue-asterisk {
    color: #3aacb3;
  }

  .MuiPickerStaticWrapper-content {
    min-width: unset !important;
  }
}

@media only screen and (min-width: 773px) {
  .add-car {
    .add-car-container {
      .model-ubication,
      .documentation,
      .price-disponibility {
        width: 90%;
      }

      .principal-btn-container {
        width: 90%;
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .add-car {
    padding-left: 58px;

    .a-cars__join-container {
      max-width: 1254px;
      width: 100%;
      margin: 0;
      padding: 32px 0 24px;

      .title-container {
        text-align: left;

        h1 {
          margin: 0;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 42px;
          line-height: 46px;
          color: #195154;
          padding: 44px 0 0 0;
        }
      }

      .btn-container {
        display: flex;
        align-items: flex-end;
        .btn-add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 180px;
          height: 30px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;

          p {
            margin: 0;
            padding: 0 6px 0 4px;
          }
        }
      }
    }

    .add-car-container {
      .model-ubication {
        align-items: flex-start;
        width: 95%;
        max-width: 1206px;

        .form-container {
          width: 100%;

          .info-container {
            margin-bottom: 40px;

            h2 {
              font-size: 24px;
            }

            h3 {
              font-size: 18px;
              line-height: 29px;
              margin: 0 0 29px;
            }
          }

          .form {
            display: flex;
            flex-wrap: wrap;

            .generic-input {
              width: 445px;
              margin: 0 30px 24px 0;

              label {
                font-size: 16px;
                line-height: 24px;
              }
            }

            .categories {
              width: 800px;
            }

            .file-container {
              width: 100%;

              .information {
                display: flex;
                flex-flow: wrap;

                .generic-input {
                  width: 445px;
                  margin: 0 30px 24px 0;

                  label {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
              }

              .option-title {
                font-size: 24px;
                line-height: 24px;
              }

              .drop-row {
                width: 12px;
              }

              .drop-options-important-details {
                display: flex;
                flex-flow: wrap;
              }

              .drop-options {
                .document-generic-input {
                  margin-bottom: 40px;
                  padding: 0 0 0 8px;
                  display: flex;
                  flex-flow: column;

                  .dropzone-ui {
                    width: 445px;
                    height: 174px;
                    .dz-ui-label {
                      font-size: 19px;
                      line-height: 17px;
                    }
                  }

                  .direction-input {
                    display: flex;
                    order: 2;

                    .btn-orange-des {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 8px;
                      margin-left: 7px;
                      background: var(--Brand-Colors-Primary-Color);
                      padding: 0;
                      color: white;
                      font-weight: bold;
                      border-radius: 40px;
                      font-family: "Montserrat";
                      padding: 6px 30px;
                      font-size: 14px;
                    }

                    .btn-orange-des > img {
                      margin-right: 8px;
                    }

                    .input-text-large {
                      width: 539px;
                      .MuiInputBase-formControl {
                        width: 539px;
                        margin-bottom: 8px;
                      }
                    }
                  }

                  span {
                    display: block;
                    order: 1;
                  }

                  h5 {
                    order: 3;
                    margin-bottom: 40px;

                    br {
                      display: none;
                    }
                  }

                  .map {
                    width: 836px;
                    height: 292px;
                    order: 4;
                  }
                }
              }
            }
          }
        }
      }

      .documentation {
        align-items: flex-start;
        width: 95%;
        max-width: 1206px;

        .form-container {
          width: 100%;

          .info-container {
            margin-bottom: 40px;

            h2 {
              font-size: 24px;
            }

            h3 {
              font-size: 18px;
              margin: 0 0 29px;
            }
          }

          .form {
            .file-container {
              .option-title {
                font-size: 24px;
                line-height: 24px;
              }

              .drop-row {
                width: 12px;
              }

              .drop-options {
                display: flex;
                flex-flow: column;
                align-items: center;

                .car-images {
                  display: none;
                }

                .car-images-des {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;

                  .image-name-container {
                    display: flex;
                    width: 161px;
                    flex-flow: column;
                    justify-content: center;
                    margin-right: 0px;
                    text-align: center;

                    p {
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      font-family: "Montserrat";
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 24px;
                      color: #373a3c;
                    }

                    .image-container {
                      width: 161px;
                      height: 102px;
                      margin-top: 24px;
                      position: relative;
                      background: #fff;
                      display: flex;
                      border: 1px solid #8f9cb2;
                      box-sizing: border-box;
                      justify-content: center;
                      align-items: center;
                      border-radius: 10px;

                      .image-in {
                        height: 68px;
                      }

                      .btn-options {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: 6px;
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        padding: 0;
                        background: #ffffff;
                        box-shadow: 0px 2px 8px rgba(196, 209, 237, 0.51);

                        img {
                          width: 14px;
                        }
                      }
                    }
                  }
                }

                .document-generic-input {
                  margin: 40px 0;
                  padding: 0;

                  .dropzone-ui {
                    width: 920px;
                    .dz-ui-label {
                      font-size: 19px;
                      line-height: 17px;
                    }
                  }

                  span {
                    font-weight: 600;
                    font-size: 14px;
                    margin: 16px 0 8px;
                  }

                  h5 {
                    font-weight: 500;
                    font-size: 12px;
                  }

                  span,
                  h5 {
                    color: #828282;
                    font-family: "Montserrat";
                    line-height: 24px;
                  }
                }

                .information {
                  width: 100%;
                  padding-left: 23px;

                  li {
                    text-align: start;
                  }
                }

                .accesories-container {
                  width: 921px;

                  .more-container {
                    display: flex;
                    justify-content: center;
                    margin: 14px 0 40px;
                  }

                  .only-accesories {
                    display: flex;
                    justify-content: space-around;

                    .accesories-content {
                      .option {
                        display: flex;
                        align-items: center;
                        margin-bottom: 30px;

                        label {
                          font-family: "Montserrat";
                          font-weight: normal;
                          font-size: 16px;
                          color: #333333;
                          margin: 0 0 0 3px;
                        }

                        img {
                          width: 20px;
                        }
                      }
                    }
                  }
                }

                .message-box {
                  height: 172px;
                }

                .max-text {
                  width: 100%;
                }

                .dropdown-divider {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .price-disponibility {
        align-items: flex-start;
        width: 95%;
        max-width: 1206px;

        .form-container {
          width: 100%;

          .info-container {
            h3 {
              margin: 0 0 29px;
            }
          }

          .form {
            .file-container {
              .option-title {
                font-size: 24px;
                line-height: 24px;
              }

              .drop-options {
                margin: 0 auto;

                .day-discount {
                  flex-direction: row;
                  gap: var(--spacing-3xl, 24px);
                  padding-left: 48px;

                  .price-d {
                    gap: 6px;
                    width: 240px;
                  }
                }

                .doubts {
                  padding-left: 48px;
                }

                .insurance-inputs {
                  display: flex;
                  flex-direction: column;

                  .charge-container,
                  .deposit-amount-container,
                  .insurance-name-container {
                    width: 100%;

                    .depositAmount,
                    .charge {
                      width: 158px;
                      margin: 8px 0 15px;
                    }
                  }
                }

                .extra-info {
                  text-align: left;
                  display: flex;

                  p {
                    margin-right: 6px;
                  }
                }

                .input-dates-container {
                  margin: 0 auto 40px;
                  width: 702px;

                  .dates-text {
                    font-size: 18px;
                  }

                  .dates-table {
                    display: flex;
                    flex-flow: row;
                    align-items: center;
                    width: 702px;

                    .date-container {
                      display: flex;
                      justify-content: space-around;
                      width: 50%;

                      .input-date {
                        width: 273px;
                        height: 38px;
                        background: #ffffff;
                        border: 1px solid #cccccc;
                        box-sizing: border-box;
                        outline: none !important;
                        border-radius: 40px;
                        padding: 0px 8px;
                        text-align: center;
                      }

                      p {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: #000;
                        margin: 10px 0;
                      }
                    }

                    .btn-erase {
                      width: 38px;
                      height: 38px;
                      background: #bdbdbd;
                      border-radius: 50%;
                      padding: 0;
                      margin: 10px 0;
                    }
                  }
                }

                .date-container {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .react-datepicker {
                    max-width: 600px;
                    .react-datepicker__month-container {
                      width: 50%;
                    }
                  }
                }

                .disponibility-text-container {
                  display: flex;
                  justify-content: center;

                  p {
                    margin-right: 6px;
                  }

                  p,
                  a {
                    font-size: 18px;
                    line-height: 24px;
                  }
                }

                .ubication {
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 24px;

                  h3 {
                    width: 100%;
                  }

                  .direction-des {
                    display: flex;
                    .MuiInputBase-formControl {
                      width: 539px;
                      height: 45px;
                      background: #ffffff;
                      border-radius: 40px;
                      font-family: "Montserrat";
                      font-weight: normal;
                      color: #333333;
                      font-size: 16px;
                      padding: 7px 0 7px 10px;
                      line-height: 24px;
                      margin-right: 7px;
                    }
                  }

                  .direction {
                    display: none;
                  }

                  .btn-orange {
                    margin: 0;
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                    padding: 0;
                    justify-content: center;
                    align-items: center;

                    p {
                      display: none;
                    }
                  }

                  .enable-check,
                  .enable-main-address {
                    order: 4;
                    width: 100%;
                    margin: 22px 0 30px 0;
                  }

                  span {
                    order: 3;
                    width: 100%;
                  }

                  .enable-main-address > label > span {
                    width: unset;
                  }

                  .enable-more-directions {
                    margin-top: 20px;
                  }

                  .main-address-warning {
                    margin-top: 8px;
                  }
                }

                .extra-option {
                  display: flex;
                  flex-wrap: wrap;

                  .extra-address-container {
                    flex-direction: row;
                  }

                  h3 {
                    font-size: 16px;
                    width: 100%;
                  }

                  .option-input {
                    width: 400px;

                    .MuiInputBase-formControl {
                      width: 539px;
                      height: 38px;
                    }
                  }

                  .btn-container {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 10px 0 7px;
                    width: unset;
                    height: 38px;

                    .btn-orange {
                      margin: 0;
                      width: 38px;
                      height: 38px;
                      border-radius: 50%;
                      padding: 0;
                      justify-content: center;
                      align-items: center;

                      p {
                        display: none;
                      }
                    }
                  }

                  .add-price {
                    position: relative;
                    bottom: 20px;

                    .delibery-price {
                      justify-content: center;
                      margin: 8px 0 0;

                      .delibery-input {
                        .MuiInputBase-formControl {
                          width: 94px;
                        }
                      }
                    }
                  }

                  .add-container {
                    justify-content: center;
                    margin: 30px 0;
                    width: 100%;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #e0e0e0;

                    .btn-add-direction {
                      margin-right: 18px;
                    }
                  }

                  .home-delivery {
                    width: 70%;
                    flex-direction: row;
                    align-items: flex-end;

                    .enable-home-delivery {
                      margin: 0 8px 0 0;

                      .enable-check {
                        display: flex;
                        padding-top: 4px;

                        .check-icon {
                          width: 20px;
                          height: 20px;
                        }

                        p {
                          font-family: "Montserrat";
                          font-weight: normal;
                          font-size: 14px;
                          line-height: 24px;
                          color: #333333;
                          margin: 0 4px 0 8px;
                        }

                        .info-icon {
                          width: 15px;
                        }
                      }
                    }
                  }

                  .no-charge-recommendation {
                    font-size: 14px;
                    padding: 0 0 8px 12px;
                  }

                  #extra-divider {
                    display: none;
                  }
                }

                .dropdown-divider {
                  width: 100%;
                }

                .rules {
                  display: flex;
                  flex-wrap: wrap;

                  .rule-container {
                    width: 445px;
                    margin: 24px 30px 0 0;
                  }
                }
              }
            }
          }
        }
      }

      .principal-btn-container {
        width: 95%;
        max-width: 1206px;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 24px;
        gap: 6px;

        .btn-orange {
          order: 2;
        }

        .btn-back {
          order: 1;
        }
      }
    }
  }
}
