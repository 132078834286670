.modal-dialog {
  margin: 0 auto !important;
}
.not-found-modal {
  .modal-content {
    width: 345px;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 16px;
    padding: 20px 16px 12px 16px;
    gap: 24px;
    margin: 100px auto;
    background: var(--Utility-Colors-Primary-Background);

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: left;
      padding: 0;
      gap: 8px;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        align-self: end;

        img {
          width: 24px;
          filter: var(--Custom-Colors-Svg);
        }
      }

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: var(--Utility-Colors-Primary-Text);
        width: 100%;
        margin: 0;
      }
    }

    .modal-body {
      display: flex;
      align-items: center;
      padding: 0;

      .message {
        .subtitle {
          font-family: "Montserrat";
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: justify;
          color: var(--Utility-Colors-Primary-Text);
          padding: 0;
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      padding: 0;

      .d-flex {
        width: 100%;
        margin: 0;
      }

      .btn-orange,
      .btn-transparent {
        border-radius: 40px;
        margin: auto;
        width: 100%;
        height: 47px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
      }

      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
        color: var(--Brand-Colors-White-color-fix);
      }

      .btn-transparent {
        background: transparent;
        color: var(--Utility-Colors-Primary-Text);
      }
    }

    .modal-header,
    .modal-footer {
      border: none;
    }
  }
}

@media only screen and (min-width: 992px) {
  .not-found-modal {
    .modal-content {
      width: 520px;
      padding: 20px 24px 20px 24px;

      .modal-footer {
        flex-direction: row;

        .d-flex {
          width: 45%;
        }
      }
    }
  }
}
