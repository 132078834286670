.car-specs {
  display: flex;
  gap: 30px;
  .left,
  .right {
    display: flex;
    flex-direction: column;
    .car-data-item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      .icon-container {
        height: 19px;
        .icon,
        .car-types-icon {
          height: 19px;
          vertical-align: top;
          filter: var(--Custom-Colors-Svg);
        }
      }

      p {
        margin-bottom: 0;
        color: var(--Utility-Colors-Secondary-Text);
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .car-specs {
    display: flex;
    gap: 25%;
    margin-top: 10px;
  }
}
