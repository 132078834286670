.carousel-suggestions-container {
  max-width: 1400px;
  width: 90%;
  margin: 109px auto 69px;

  .title-center {
    color: var(--Utility-Colors-Primary-Text);
    text-align: center;
    font-size: 24px;
  }

  .title-left {
    color: var(--Utility-Colors-Primary-Text);
    text-align: left;
    font-size: 24px;
  }

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin: 0 0 16px 0;
  }

  .swiper.arrow-white {
    .swiper-button-next,
    .swiper-button-prev {
      background-color: transparent;
      opacity: 1;
    }
  }

  .swiper-suggestions {
    .card {
      background-color: var(--Utility-Colors-Secondary-Background);
      margin: 10px 0;
      width: 342px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 0;
      border-radius: 24px;
      font-family: "Montserrat";

      .car-img {
        max-height: 184px;
        object-fit: cover;
      }

      .car-image {
        height: 140px;

        .car {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 24px 24px 0 0;
        }

        .skeleton {
          height: 140px;
          object-fit: cover;
          width: 100%;
        }

        .default-car {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 140px;
        }
      }

      .heart {
        right: 16px;
        top: 16px;
        position: absolute;
      }

      .trips-tag {
        position: absolute;
        background-color: #f4f4f4;
        border-radius: 4px;
        margin: 12px;
        font-size: 11px;
        width: 90px;
        height: 21px;
        font-weight: 500;
      }

      .counter {
        left: 16px;
        top: 10px;
        position: absolute;
        color: var(--Brand-Colors-Primary-Color);
        background-color: #fff;
        padding: 2.5px;
      }

      .card-body-title {
        .card-header-div {
          padding-top: 12px;
          .card-title {
            color: var(--Utility-Colors-Primary-Text);
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 20px;
            margin: 0 8px 0 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-left: 15px;
          }

          img {
            width: 18px;
            height: 19px;
          }
        }

        .card-text {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 11.5451px;
          line-height: 14px;
          margin: 5px 0;
          padding-left: 10px;

          img {
            margin: 0 3px;
          }
        }

        hr {
          margin: 4px 0;
          color: #f0f2f3;
        }
      }

      .card-body {
        padding: 5px 8px 8px;
        height: 60px;
        max-height: 60px;
        border-top: 1px solid #13131326;

        .car-insurance-data > p:nth-child(1) {
          font-weight: 700;
          padding-right: 4px;
        }

        p {
          color: var(--Utility-Colors-Primary-Text);
        }

        .car-insurance-data > p:nth-child(1) > svg {
          font-size: 14px;
          margin-right: 4px;
        }

        .car-insurance-data {
          margin: 0;
          font-weight: normal;
          font-family: "Montserrat";
          padding: 6px 8px;

          p {
            margin: 0;
            font-size: 11px;
            line-height: 13px;
          }
        }

        .car-data-item {
          padding: 0 4px;
          align-items: center;
          p {
            margin: 0;
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
          }
          img,
          svg {
            filter: var(--Custom-Colors-Svg);
            border-radius: unset;
            size: 14px;
          }
        }

        .card-price-box {
          padding: 12px 8px 0 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          cursor: pointer;

          p {
            margin: 2px 0;
          }

          .card-price {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            margin: 0;
            line-height: 23px;
            text-align: end;
            margin: 0 0 0 4px;

            del {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 12px;
              margin-right: 8px;
            }
          }
        }

        .card-price-box > p:nth-child(2) {
          text-decoration: underline;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .carousel-suggestions-container {
    .title-center {
      text-align: center;
      font-size: 42px;
    }

    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      margin: 0 0 47px 80px;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .carousel-suggestions-container {
    .swiper-suggestions {
      .card {
        width: 447px;
        height: 326px;

        .car-image {
          height: 190px;

          .skeleton {
            height: 190px;
          }

          .default-car {
            height: 190px;
          }
        }
      }
    }
  }
}
