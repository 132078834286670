.reason-reject-modal {
  .modal-content {
    width: 312px;
    height: 655px;
    margin: 150px auto;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;

    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: left;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 50%;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
        }
      }

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        width: 277px;
        line-height: 30px;
      }
    }

    .modal-body {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;

      .reasons-modal-container {
        text-align: start;
        margin-top: 36px;

        div {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          label {
            font-family: "Montserrat";
            font-weight: normal;
            font-size: 16px;
            color: #333333;
            margin: 0 0 0 3px;
          }

          img {
            width: 20px;
          }
        }
      }

      .another-reason {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #195154;
        width: 100%;
      }

      .comment-box {
        width: 272px;
        height: 72px;
        border: 1px solid #3cb2b9;
        box-sizing: border-box;
        border-radius: 10px;
        max-height: 100px;
        min-height: 72px;
        margin-bottom: 40px;
        padding: 12px 10px;
      }

      .comment-box:active,
      .comment-box:hover,
      .comment-box:focus {
        outline: none !important;
      }

      .comment-box::placeholder {
        font-family: "Montserrat";
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #bdbdbd;
      }

      h3 {
        font-family: "Montserrat";
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 4px 0 20px;
        width: 248px;
      }
    }

    .modal-body,
    .modal-footer {
      padding-top: 0;
    }

    .modal-header,
    .modal-footer {
      border: 0px;
    }

    .modal-footer,
    .modal-body {
      text-align: center;
    }

    .modal-footer {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      padding-bottom: 40px;

      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 272px;
        height: 38px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;

        p {
          color: #fff;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .reason-reject-modal {
    .modal-content {
      width: 540px;
      height: 625px;

      .modal-header {
        padding-left: 24px;

        h2 {
          font-size: 20px;
          line-height: 30px;
          width: 492px;
          margin-bottom: 0;
        }
      }

      .modal-body {
        padding-left: 24px;

        h3 {
          width: 457px;
          margin: 4px 0 32px;
        }

        .comment-box {
          width: 480px;
          margin-bottom: 24px;
        }
      }

      .modal-footer {
        .btn-orange {
          width: 160px;
        }
      }
    }
  }
}
