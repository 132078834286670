.change-coupon-modal {
  display: flex !important;
  align-items: center;

  .modal-content {
    height: 838px;
    width: 370px;
    margin: 0 auto;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;

    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }

    .modal-header {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 50%;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
        }
      }

      .coupon-form-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .input-form {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 90%;
          border: 1px solid #3cb2b9;
          border-radius: 20px;
          padding: 19px 23px 19px 16px;

          .coupon-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #195154;
            margin-bottom: 16px;
          }

          .coupon-input {
            width: 70%;
            border: 1px solid #3cb2b9;
            border-radius: 40px;
            padding: 7px 0 7px 12px;
          }

          .btn-redeem {
            width: 25%;
            background: var(--Brand-Colors-Primary-Color);
            border-radius: 40px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            border: 0px;
            color: #fafbfc;
          }
        }
      }

      .warning {
        display: flex;
        align-self: self-start;
        align-items: center;
        padding: 16px 0 0 25px;
        gap: 6px;

        .status {
          width: 16px;
          height: 16px;
        }
        .status-text {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #282828;
          margin-bottom: 0;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;

      .title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        color: #3cb2b9;
      }

      .coupon-index {
        width: 100%;

        .coupon-container {
          display: flex;
          flex-direction: column;

          .valid-date {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            color: #575757;
            align-self: flex-end;
          }
          .coupon {
            display: flex;
            overflow: hidden;
            height: 100px;
            align-items: center;
            justify-content: space-between;
            background-image: url("../../../assets/images/modals/coupon.png");
            background-repeat: no-repeat;
            background-size: 100%;
            padding: 0 24px 0;

            .coupon-image {
              width: 100%;
            }

            .information {
              display: flex;
              align-items: center;
              width: 70%;
              gap: 43px;
              height: 100%;

              .price {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                color: #ffffff;
                margin-bottom: 0;
              }

              .coupon-prize {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                color: #ffffff;
                width: 120px;
                overflow: hidden;
                p {
                  margin-bottom: 0;
                }
              }
            }

            .btn-container {
              width: 30%;
              display: flex;
              align-items: center;
              justify-content: center;

              .btn-use {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                color: #fafbfc;
                background: #3cb2b9;
                border-radius: 26px;
                width: 78px;
                height: 40px;
                border: 0;
              }
            }
          }
        }
      }
    }

    .modal-body,
    .modal-footer {
      padding-top: 0;
    }

    .modal-header,
    .modal-footer {
      border: 0px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .change-coupon-modal {
    .modal-content {
      width: 540px;
      height: 838px;

      .modal-header {
        .coupon-form-container {
          .input-form {
            .btn-redeem {
              font-size: 16px;
            }
          }
        }
      }

      .modal-body {
        padding: 0 48px;

        .coupon-index {
          .coupon-container {
            .coupon {
              height: 140px;

              .information {
                height: auto;

                .price {
                  font-size: 18px;
                }

                .coupon-prize {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
