.verify-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fff;

  .resend-button {
    text-align: center;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 16px;
    width: 220px;
    color: var(--Semantic-Colors-Link);
    line-height: 24px;
    text-decoration-line: underline;
    border: 0;
    background-color: inherit;
    text-underline-offset: 3px;
  }

  .join-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    .title-container {
      text-align: center;
      width: 340px;
      margin: 40px auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      h1 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: var(--Utility-Colors-Primary-Text);
        margin: 0px;
      }

      p {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 16px;
        line-height: 19.5px;
        color: var(--Utility-Colors-Primary-Text);

        span {
          font-weight: 600;
        }
      }
    }
  }

  .verify-form {
    padding: 48px 32px 80px;

    label {
      font-family: "Montserrat";
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 19.5px;
      width: 100%;
      margin: 0;
      color: var(--Utility-Colors-Primary-Text);
    }

    .multy-inputs {
      display: flex;
      justify-content: space-around;
      .pincode-input-container {
        padding: 16px;
        .pincode-input-text {
          border: 1px solid var(--Utility-Colors-Tertiary-Text) !important;
          box-sizing: border-box;
          border-radius: 16px;
          margin: 0 10px !important;
          width: 47px !important;
          height: 64px !important;
          text-align: center;
          font-family: "Montserrat";
          font-weight: normal;
          font-size: 24px;
          color: var(--Brand-Colors-Secondary-Color);
          line-height: 24px;
          font-weight: 600;
        }

        input:focus {
          box-shadow: 0px 4px 18px rgba(209, 217, 230, 0.8);
          border-color: var(--Utility-Colors-Secondary-Text) !important;
        }
      }
    }

    p {
      font-family: "Montserrat";
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 8px;
      color: var(--Utility-Colors-Secondary-Text);
    }

    .error {
      color: var(--Semantic-Colors-Error);
      text-align: center;
      margin-top: 4px;
    }

    .button-form {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      margin-top: 48px;
      gap: 24px;

      button:active,
      button:hover,
      button:focus {
        outline: none !important;
      }

      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        width: 272px;
        height: 52px;
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: var(--Brand-Colors-White-color-fix);
      }

      a {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        color: #2f80ed;
      }
    }
  }

  .counter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--Utility-Colors-Primary-Text);
    font-weight: 600;
    font-size: 16px;
    font-family: "Montserrat";
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
}

@media only screen and (min-width: 1000px) {
  .verify-container {
    .join-container {
      width: 884px;
      .title-container {
        width: 689px;
        margin-top: 101px;
        gap: 24px;
        h1 {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 36px;
          line-height: 43.88px;
        }
      }
    }
  }
}
