.processing-payment-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding-top: 142px;

  .processing-payment-title {
    font-weight: 600;
    font-size: 42px;
    line-height: 46px;
    color: #195154;
  }

  .processing-payment-info {
    width: 80%;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
  }

  .processing-payment-title,
  .processing-payment-info {
    font-family: "Montserrat-Regular";
    margin-bottom: 24px;
  }

  .processing-payment-img {
    width: 218.87px;
    height: 231px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 900px) {
  .processing-payment-container {
    padding-top: 101px;

    .processing-payment-info {
      font-size: 20px;
    }

    .processing-payment-img {
      width: 218.87px;
      height: 231px;
    }
  }
}
