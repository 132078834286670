.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    .carousel-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    background: #ecf7f8;
    opacity: 0.9;
    width: 44px;
    height: 44px;
    border-radius: 40px;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 20px;
    color: var(--Utility-Colors-Primary-Text);
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background-color: #fff;
      opacity: 0.5;
      border: 1px solid #000;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .swiper {
    max-height: 800px;
    .swiper-slide {
      max-height: 800px;
    }
    .swiper-button-next,
    .swiper-button-prev {
      background: #ecf7f8;
      opacity: 0.9;
      width: 60px;
      height: 60px;
      border-radius: 40px;
    }

    .swiper-button-next {
      right: 35px;
      left: auto;
    }

    .swiper-button-prev {
      left: 35px;
      right: auto;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: 24px;
      font-weight: 700;
    }
  }
}
