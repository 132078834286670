.car-container-show-car {
  background-color: var(--Utility-Colors-Primary-Background);
  padding-bottom: 60px;

  .car-image {
    div {
      height: 180px;
    }
  }
  .input-group {
    .bootstrap-datetimepicker-widget table td.disabled,
    .bootstrap-datetimepicker-widget table td.disabled:hover {
      background: #e0e0e0;
      color: #828282;
    }
  }

  .show-car-carousel {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;

    .swiper-button-next,
    .swiper-button-prev {
      background: transparent;
    }
  }

  .show-car-not-found {
    background-color: rgba(249, 249, 249, 0.7);
    display: flex;
    justify-content: center;
    padding: 15px 0;

    img {
      width: 40%;
      max-width: 740px;
    }
  }

  .skeleton {
    width: 100%;
    height: 600px;
  }

  .skeleton {
    width: 100%;
    height: 243px;
  }

  .btn-container {
    width: 100%;
    background-color: var(--Utility-Colors-Primary-Background);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;

    .btn-orange {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      margin: 11px 0;
      width: 136px;
      height: 38px;

      img {
        margin-bottom: 2px;
      }

      p {
        color: #fff;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        margin: 0 8px 0 0;
      }
    }

    .btn-container-icon {
      width: 50%;
      max-width: 204px;
      display: flex;
      gap: 16px;
      justify-content: space-around;
      align-items: center;

      .share {
        display: none;
      }

      .btn-orange-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--Utility-Colors-Primary-Text);
        border-radius: 50%;
        width: 32.69px;

        img {
          filter: var(--Custom-Colors-Svg);
        }
      }
    }
  }

  .car-form {
    display: flex;
    flex-flow: column;
    align-items: center;

    .card-info {
      width: 375px;
      margin: 0 auto;
      padding: 24px 24px 42px;

      .car-info-des {
        display: none;
      }

      .car-experiences {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .MuiChip-outlined {
          background: var(--Brand-Colors-Alternate);
          color: var(--Utility-Colors-Primary-Text);

          span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }

          img {
            filter: var(--Custom-Colors-Svg);
          }

          .MuiChip-avatar {
            width: auto;
            height: auto;
          }
        }
      }

      .card-title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 46px;
        margin: 0;
        color: var(--Utility-Colors-Primary-Text);
      }

      .rating-container {
        display: flex;
        align-items: center;
        gap: 6px;
        span,
        p {
          font-family: "Montserrat";
          font-style: normal;
        }
        p {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
        }

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 30px;
        }

        img {
          height: 15px;
        }
      }

      .card-text,
      .card-user {
        font-family: "Montserrat";
      }
    }

    .card {
      width: 95%;
      max-width: 510px;
      background: var(--Utility-Colors-Secondary-Background);
      border-radius: 20px;

      .car-price {
        display: flex;
        flex-flow: column;
        text-align: center;

        .price {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 24px;
          line-height: 46px;
          margin: 24px 0 17px;
          color: var(--Utility-Colors-Primary-Text);
        }

        del {
          font-weight: 600;
          font-size: 16px;
          margin: 0 0 4px 10px;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          color: var(--Brand-Colors-Secondary-Color);
        }

        h1,
        del {
          color: #333333;
        }

        p,
        del {
          font-family: "Montserrat";
          line-height: 24px;
        }
      }

      .card-body {
        label {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
          margin-bottom: 4px;
        }

        input {
          margin-bottom: 16px;
        }

        .where-container {
          border-radius: 32px;
          background: var(--Utility-Colors-Primary-Background);
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 22px;
          padding: 22px 0 22px 22px;
          white-space: nowrap;
          overflow: hidden;

          img {
            width: 12px;
            height: 20px;
            filter: var(--Custom-Colors-Svg);
          }

          .where {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: var(--Utility-Colors-Primary-Text);
            margin-bottom: 0;
          }
        }

        .ubication-input {
          div {
            width: 100%;

            input {
              width: 80%;
              height: 38px;
              background: #ffffff;
              border: 1px solid #cccccc;
              box-sizing: border-box;
              border-radius: 40px;
              padding: 7px 11px;
            }
          }
        }

        .ubication-input-dev {
          div {
            width: 100%;

            input {
              width: 80%;
              height: 38px;
              background: #ffffff;
              border: 1px solid #cccccc;
              box-sizing: border-box;
              border-radius: 40px;
              padding: 7px 11px;
            }
          }
        }

        .date,
        .hour > .dropdown-toggle {
          background-color: #fff;
          box-sizing: border-box;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;

          .bs-caret {
            display: none;
          }
        }

        .form-control {
          text-align: center;
          margin-bottom: 0px;
          height: 34px;
          border-radius: 8px 0px 0px 8px;
          border: 1px solid #e0e0e0;
          width: 51%;
        }

        .form-control::placeholder {
          color: #333333;
        }

        .dates {
          width: 100%;
          .hour > .dropdown-toggle {
            border-radius: 0px 8px 8px 0px;
            border: 1px solid #e0e0e0;

            .filter-option {
              text-align: center;
            }
          }

          .un-desde,
          .un-hasta {
            margin-bottom: 16px;

            .datepicker {
              width: 50%;
              .MuiOutlinedInput-root {
                height: 40px;
                input {
                  border-radius: 8px 0px 0px 8px;
                  height: 40px;
                  text-align: center;
                  margin-bottom: 0px;
                  width: 100%;
                  border: 0px;
                  padding: 0;
                  text-align: center;
                  color: var(--Utility-Colors-Primary-Text);
                  border-right: 1px solid
                    var(--Utility-Colors-Secondary-Background);
                  background: var(--Utility-Colors-Primary-Background);
                }
              }
              .MuiOutlinedInput-notchedOutline {
                border: 0px;
              }
            }
            .hour {
              width: 50%;
              border-radius: 0px 8px 8px 0px;
              border: 0px;
              height: 40px;
              color: var(--Utility-Colors-Primary-Text);
              background: var(--Utility-Colors-Primary-Background);
              text-align: center;
              border-right: 1px solid var(--Utility-Colors-Secondary-Background);

              fieldset {
                border: 0px;
              }

              svg {
                filter: var(--Custom-Colors-Svg);
              }
              .ant-picker-input {
                input {
                  margin-bottom: 0;
                }
              }
            }
          }

          label {
            color: var(--Utility-Colors-Primary-Text, #fff);
          }
        }

        .dropdown-toggle::after {
          content: none;
        }

        .btn-place {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          padding: 0;
          border-radius: 50%;
          background: var(--Brand-Colors-Primary-Color);
        }

        .delibery {
          label {
            color: var(--Utility-Colors-Primary-Text);
          }

          .dropdown {
            width: 100%;
            background: var(--Utility-Colors-Primary-Background);
            color: var(--Utility-Colors-Primary-Text);
            height: 40px;
            border-radius: 40px;
            margin-bottom: 16px;

            svg {
              filter: var(--Custom-Colors-Svg);
            }
          }

          .info-alert {
            display: none;
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: #828282;
            margin-bottom: 12px;
          }
        }

        .list-prices {
          .dropdown-divider {
            margin: 17px 0;
            border-bottom: 1px solid #e0e0e0;
          }
          .discount {
            a {
              text-decoration: underline;
            }

            h5 {
              margin: 0;
            }

            .add-discount {
              margin: 28px 0;
              display: flex;
              justify-content: center;
              flex-flow: column;
              text-align: center;
            }

            h5,
            a {
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
            }

            p {
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              text-align: end;
            }

            span {
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: #219653;
              margin-left: 10px;
            }

            h5,
            p,
            span,
            a {
              font-family: "Montserrat";
            }

            img {
              margin-right: 5px;
              width: 12px;
            }
          }
          .extra {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h5 {
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 0;
              color: var(--Utility-Colors-Primary-Text);
            }

            .insurance-day,
            .price-day {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: var(--Brand-Colors-Secondary-Color);
              margin-bottom: 0;
            }

            .day-tag {
              display: flex;
              align-items: center;
              gap: 10px;
            }

            svg {
              filter: var(--Custom-Colors-Svg);
            }

            span {
              font-family: "Montserrat";
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
              color: var(--Utility-Colors-Primary-Text);
            }

            .tooltip-form {
              color: #828282;
              width: 16px;
              height: 16px;
            }

            .final-total-static {
              display: flex;
              font-weight: 300;
              gap: 9px;
              align-items: center;
              p {
                font-weight: 700;
                margin-bottom: 0;
              }
            }
          }

          .total {
            text-align: end;

            h5 {
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        .warning {
          margin-top: 30px;

          .text-container {
            display: flex;
            align-items: center;
            background-color: var(--Semantic-Colors-Warning-Background);
            border-radius: 8px;
            margin: 0 auto;
            width: 309px;
            padding: 6px;
            gap: 12px;

            .alert-triangle {
              width: 25px;
              height: 25px;
            }

            p {
              font-family: "Montserrat";
              font-weight: 400;
              font-size: 12px;
              margin-bottom: 0;
              color: var(--Brand-Colors-Black-color-fix);
              opacity: 1;
            }
          }
        }

        .terms {
          display: flex;
          flex-direction: column;
          margin: 24px 24px 30px;
          gap: 10px;

          .text-container {
            text-align: center;

            a {
              text-decoration: underline;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: var(--Utility-Colors-Primary-Text);
            }
          }
        }

        .btn-des {
          display: none;
        }

        .form-indicator {
          display: flex;
          border-top: 1px solid #e0e0e0;
          padding: 16px 13px;

          img {
            width: 29px;
            height: 29px;
            filter: var(--Custom-Colors-Svg);
          }

          .indicator-information {
            margin-left: 10px;
            .indicator-name {
              font-family: "Montserrat";
              font-weight: 700;
              font-size: 13px;
              color: var(--Utility-Colors-Primary-Text);
              margin-bottom: 0;
            }

            .extra {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 11.5px;
              color: var(--Utility-Colors-Primary-Text);
            }
          }
        }
      }
    }

    .car-info {
      margin: 60px auto 10px;
      width: 87%;
      max-width: 510px;

      .perks-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
          font-family: "Montserrat";
          font-weight: 800;
          font-size: 20px;
          line-height: 24px;
          color: var(--Utility-Colors-Primary-Text);
          margin-bottom: 35px;
          display: flex;
          align-self: flex-start;
        }

        .comercial-perks,
        .host-perks {
          display: flex;
          flex-direction: column;

          .perks {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            .perk-info {
              margin-left: 10px;
              .perk-name {
                font-family: "Montserrat";
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                color: var(--Utility-Colors-Primary-Text);
                margin-bottom: 0;
              }

              .extra {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: var(--Utility-Colors-Secondary-Text);
              }
            }

            img {
              width: 34px;
              height: 34px;
              filter: var(--Custom-Colors-Svg);
            }
          }

          .comercial-contact {
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
            text-align: center;
          }
        }
      }

      .ubication,
      .rules,
      .accesories,
      .description {
        margin: 10px 0 30px;

        h4 {
          font-family: "Montserrat";
          font-weight: 800;
          font-size: 20px;
          line-height: 24px;
          color: var(--Utility-Colors-Primary-Text);
        }

        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: var(--Utility-Colors-Secondary-Text);
          margin-bottom: 0;
        }

        h6 {
          color: var(--Utility-Colors-Primary-Text, #fff);
          font-weight: 500;
          font-size: 16px;
        }

        h6,
        p {
          font-family: "Montserrat";
        }

        .more-container {
          display: flex;
          margin: 14px 0;

          .more {
            font-weight: bold;
            font-size: 16px;
            line-height: 30px;
          }

          img {
            width: 12px;
            margin-left: 5px;
          }
        }

        .charac {
          display: flex;
          width: 50%;
          align-items: center;
          margin-bottom: 10px;

          h6 {
            margin: 0 0 0 8px;
          }

          img {
            filter: var(--Custom-Colors-Svg);
          }
        }
      }

      .accesories {
        display: flex;
        flex-wrap: wrap;

        h4 {
          width: 100%;
        }

        img {
          width: 16px;
        }
      }

      .ubication {
        .ubication-dropdown {
          .input-delibery {
            width: 100%;
            border-radius: 40px;
            margin-bottom: 16px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
            border: 1px solid #cccccc;
            margin: 2px 0 0 3px;
            padding-left: 12px;
          }
        }

        .map-info {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 0;
          font-weight: 500;
          font-family: "Montserrat";
          color: var(--Utility-Colors-Primary-Text);
          margin-top: 8px;
          font-style: italic;
        }
      }
    }
  }

  .ubication-des {
    display: none;
  }

  .map-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1354px;

    .map {
      height: 180px;
      width: 90%;
      max-width: 1214px;
      margin: 0 auto;
    }

    .card-delivery-points {
      background: var(--Utility-Colors-Secondary-Background);
      padding: 21px 11px;
      width: 90%;
      max-width: 391px;
      border-radius: 0 0 16px 16px;

      h2 {
        color: var(--Utility-Colors-Primary-Text);
      }

      .main-delibery-container {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 0 13px;
        .main-delibery-ubication {
          display: flex;
          justify-content: space-between;

          p {
            width: 228px;
          }
          .icon-comercial-container {
            display: flex;
            gap: 5px;
          }
        }
      }

      .extra-ubication {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 21px 0 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .extra-delibery-ubication-container {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          max-height: 200px;
          overflow: scroll;
          &::-webkit-scrollbar {
            width: 10px;
          }

          .extra-direction {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            p {
              width: 228px;
            }

            .icon-comercial-container {
              display: flex;
              gap: 5px;
            }
          }
        }
      }

      .home-delivery-container {
        .home-address {
          margin-top: 14px;
          display: flex;
          justify-content: space-between;
        }
      }

      p,
      span,
      h2 {
        font-family: "Montserrat";
        font-style: normal;
        color: var(--Utility-Colors-Primary-Text);
      }

      strong {
        color: var(--Utility-Colors-Primary-Text);
      }

      p {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0;
      }

      span {
        font-weight: 500;
        font-size: 12px;
      }

      h2 {
        font-weight: 600;
        font-size: 14px;
        margin: 0 0 16px;
      }
    }
  }

  .alert-map {
    width: 90%;
    max-width: 1110px;
    margin: 0 auto;

    span {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #828282;
      margin-bottom: 12px;
    }
  }

  .total-fixed {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--Utility-Colors-Secondary-Background);
    z-index: 3;
    position: fixed;
    padding: 15px 16px;
    bottom: 0;

    h1 {
      font-weight: 600;
      margin-right: 5px;
    }

    h1,
    p {
      color: var(--Utility-Colors-Primary-Text);
      font-family: "Montserrat";
      margin-bottom: 0;
      margin-top: 0;
    }

    h1,
    p,
    .btn-orange {
      font-size: 16px;
      line-height: 20px;
    }

    .btn-orange {
      width: 157px;
      height: 50px;
      font-weight: 700;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      color: #fff;
      font-family: "Montserrat";
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .total-container {
      display: flex;
      gap: 2px;

      h2 {
        color: var(--Utility-Colors-Primary-Text);
        margin-bottom: 0;
      }

      .final-total {
        display: flex;
        align-items: center;
        font-weight: 300;
        margin-right: 0;

        p {
          font-weight: 700;
          gap: 2px;
          margin-bottom: 0;
        }
      }
    }
  }

  .preview-fixed {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    z-index: 3;
    height: 108px;
    position: fixed;
    padding: 14px 26px 18px;
    bottom: 0;

    .btn-close-orange {
      background: var(--Brand-Colors-Primary-Color);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 179px;
      height: 40px;
      border-radius: 40px;
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }

    .preview-text {
      display: flex;
      justify-content: center;
      margin: 0 10px 10px 0;

      h1 {
        font-weight: 600;
        margin-left: 5px;
        color: #333333;
        font-family: "Montserrat";
        margin-bottom: 0;
        margin-top: 0;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .swiper-suggestions {
    .swiper-button-next,
    .swiper-button-prev {
      background: var(--Utility-Colors-Secondary-Background);
    }
  }
}

@media only screen and (min-width: 1000px) {
  .car-container-show-car {
    .btn-container {
      .btn-container-icon {
        max-width: 310px;
        gap: 24px;

        .share {
          display: block;
          font-family: "Montserrat";
          font-weight: 800;
          font-size: 18px;
          line-height: 22px;
          color: var(--Utility-Colors-Primary-Text);
          margin: 0 40px 0 0;
        }
      }
    }

    .skeleton {
      height: 600px;
    }

    .car-form {
      flex-flow: row;
      align-items: flex-start;
      justify-content: center;
      padding-top: 60px;

      .card {
        .car-price {
          flex-flow: row;
          align-items: flex-end;
          justify-content: space-around;

          .price {
            font-size: 36px;
            line-height: 46px;
            margin-bottom: 0;

            del {
              font-size: 18px;
              line-height: 24px;
            }
          }

          p {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 0;
          }
        }

        .card-body {
          padding: 40px 24px;

          .un-container-date {
            display: flex;
            align-items: center;
          }

          .where-container {
            gap: 25px;
            img {
              width: 18px;
              height: 29px;
            }
          }

          .dates {
            display: flex;
            .un-hasta {
              .datepicker {
                .MuiOutlinedInput-root {
                  input {
                    border-radius: 0px 0px 0px 0px;
                  }
                }
              }
            }

            .un-hasta,
            .un-desde {
              width: 50%;

              .bootstrap-select {
                width: 50% !important;
              }
            }

            .un-desde {
              .datepicker {
                .MuiOutlinedInput-root {
                  input {
                    border-radius: 8px 0px 0px 8px;
                  }
                }
              }
              .hour {
                border-radius: 0px 0px 0px 0px;
              }
            }
          }

          .ubication-input {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
              width: 75%;

              label {
                margin: 0 36px 12px 0;
              }

              input {
                width: 283px;
              }
            }
          }

          .ubication-input-dev {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
              width: 75%;

              label {
                margin: 0 36px 12px 0;
              }

              input {
                width: 283px;
              }
            }
          }

          .warning {
            .text-container {
              background-color: var(--Semantic-Colors-Warning-Background);
              color: var(--Brand-Colors-Black-color-fix);
              border-radius: 8px;
              margin: 0 auto;
              width: 380px;
            }
          }

          .list-prices {
            padding: 15px 40px 25px;
            .discount {
              .add-discount {
                justify-content: center;
                flex-flow: row;

                h5 {
                  margin-right: 2px;
                }
              }
            }
          }

          .delibery {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .dropdown {
              width: 79%;
            }

            label {
              margin: 0 0 12px 0;
            }
          }

          .btn-des {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn-orange {
              width: 240px;
              height: 52px;
              background: rgb(130, 130, 130);
              border-radius: 40px;
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 20px;
              line-height: 25px;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .form-indicator {
            margin-top: 33px;
            align-items: center;
            .indicator-information {
              .indicator-name {
                font-size: 14px;
              }

              .extra {
                font-size: 14px;
              }
            }
          }
        }
      }

      .car-info {
        display: none;
      }

      .card-info {
        width: 50%;
        max-width: 640px;
        margin: 0 90px 0 0;
        padding: 0 17px 42px;

        .card-title {
          font-size: 42px;
          line-height: 46px;
        }

        .car-info-des {
          display: block;
          max-width: 541px;

          .perks-container {
            display: flex;
            flex-direction: column;

            h4 {
              font-family: "Montserrat";
              font-weight: 800;
              font-size: 20px;
              line-height: 24px;
              color: var(--Utility-Colors-Primary-Text);
              margin-bottom: 35px;
            }

            .comercial-perks,
            .host-perks {
              display: flex;
              flex-direction: column;

              .perks {
                display: flex;
                align-items: center;
                margin-bottom: 24px;

                .perk-info {
                  margin-left: 10px;
                  .perk-name {
                    font-family: "Montserrat";
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    color: var(--Utility-Colors-Primary-Text);
                    margin-bottom: 0;
                  }

                  .extra {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: var(--Utility-Colors-Secondary-Text);
                  }
                }

                img {
                  width: 34px;
                  height: 34px;
                  filter: var(--Custom-Colors-Svg);
                }
              }

              .comercial-contact {
                font-family: "Montserrat";
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                margin: 39px 0 20px;
                color: #333333;
                text-align: left;
              }
            }
          }

          .ubication,
          .rules,
          .accesories,
          .description {
            margin: 30px 0 50px;

            h4 {
              font-family: "Montserrat";
              font-weight: 800;
              font-size: 20px;
              line-height: 24px;
              color: var(--Utility-Colors-Primary-Text);
            }

            p {
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: var(--Utility-Colors-Secondary-Text);
              margin-bottom: 0;
            }

            h6 {
              color: var(--Utility-Colors-Primary-Text, #fff);
              font-weight: 500;
              font-size: 16px;
            }

            h6,
            p {
              font-family: "Montserrat";
            }

            .more-container {
              display: flex;
              margin: 14px 0;

              .more {
                font-weight: bold;
                font-size: 16px;
                line-height: 30px;
              }

              img {
                width: 12px;
                margin-left: 5px;
              }
            }

            .charac {
              display: flex;
              width: 50%;
              align-items: center;
              margin-bottom: 10px;

              h6 {
                margin: 0 0 0 8px;
              }
              img {
                filter: var(--Custom-Colors-Svg);
              }
            }
          }

          .accesories {
            display: flex;
            flex-wrap: wrap;

            h4 {
              width: 100%;
            }

            img {
              width: 16px;
            }
          }

          .ubication {
            .ubication-dropdown {
              .dropdown {
                width: 100%;
                background: #ffffff;
                border-radius: 40px;
                margin-bottom: 16px;

                button {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  background: #ecf7f8;
                  border-radius: 40px;
                }

                span {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  margin: 2px 0 0 3px;
                }

                .disabled {
                  background: #ecf7f8;
                }

                .bs-caret {
                  display: none;
                }

                .dropdown-menu {
                  top: 40px;
                }
              }
            }

            .map-info {
              font-size: 14px;
              line-height: 24px;
              margin-bottom: 0;
              font-weight: 500;
              font-family: "Montserrat";
              color: var(--Utility-Colors-Primary-Text);
              font-style: italic;
            }
          }
        }
      }
    }

    .ubication-des {
      display: flex;
      flex-flow: column;
      max-width: 1214px;
      width: 90%;
      margin: 0 auto 5px;

      h4 {
        font-family: "Montserrat";
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: var(--Utility-Colors-Primary-Text);
      }

      .dropdowns {
        display: flex;

        .ubication-dropdown {
          display: flex;
          align-items: center;
          width: 50%;

          label {
            margin: 0 15px 0 0;
          }

          .input-delibery-des {
            width: 300px;
            background: #ffffff;
            border-radius: 40px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
            margin: 2px 0 0 3px;
            border: 1px solid #cccccc;
            padding: 4px 0px 4px 15px;
          }

          .deliberyHomeAddressDes {
            align-items: center;

            label {
              margin-right: 69px;
            }
          }

          .deliberyHomeDevAddressDes {
            align-items: center;

            label {
              margin-right: 94px;
            }
          }
        }
      }

      .map-info {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-weight: 500;
        font-family: "Montserrat";
        color: var(--Utility-Colors-Primary-Text);
        margin-top: 8px;
        font-style: italic;
      }
    }

    .map-container {
      position: relative;
      .map {
        height: 665px;
      }

      .card-delivery-points {
        padding: 14px 22px;
        position: absolute;
        left: 130px;
      }
    }

    .total-fixed {
      display: none;
    }
    .preview-fixed {
      justify-content: flex-end;
      .preview-text {
        margin-bottom: 0px;

        h1 {
          margin-right: 16px;
          margin-left: 9px;
        }
      }
    }
  }
}
