.details {
  background-color: var(--Utility-Colors-Primary-Background);
  .details-container {
    padding: 40px 0 80px;

    .details-navbar {
      display: flex;
      align-items: center;
      max-width: 1108px;
      width: 100%;
      height: 65px;
      background: var(--Utility-Colors-Secondary-Background);
      position: absolute;
      top: 0px;

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: var(--Utility-Colors-Primary-Text);
        margin-bottom: 0;
      }

      img {
        filter: var(--Custom-Colors-Svg);
        margin: 0 36px 0 28px;
      }
    }

    .return-container-des {
      display: none;
    }

    .checkout-host-card {
      display: flex;
      align-items: center;
      width: 343px;
      margin: 42px auto 16px;
    }

    .rent-info {
      height: 920px;
    }

    .rent-info-des {
      display: none;
    }

    .card {
      width: 343px;
      margin: 0 auto 20px;
      border: 0px;
      height: fit-content;
      border-radius: 8px;

      .card-header {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--Custom-Colors-Title-Bar);
        border-radius: 8px 8px 0px 0px;
        height: 49px;

        h2 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: var(--Brand-Colors-White-color-fix);
          margin: 0;
        }
      }

      .card-body {
        width: 100%;
        background: var(--Utility-Colors-Secondary-Background);
        border-radius: 0px 0px 8px 8px;

        .delibery,
        .devolution {
          .main-delibery {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #828282;
            display: block;
            margin-bottom: 20px;
          }
        }

        h5 {
          margin: 0;
        }

        .car-img,
        .default {
          width: 315.11px;
          height: 252.94px;
          object-fit: cover;
          border-radius: 16px;
        }

        .default {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid rgba(0, 0, 0, 0.08);

          img {
            width: 140px;
          }
        }

        .car-name {
          margin: 19px 0 13px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: var(--Utility-Colors-Primary-Text);
        }

        .create-trip {
          padding: 20px 10px;
        }

        label {
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: var(--Utility-Colors-Primary-Text);
          margin-bottom: 4px;
        }

        .un-desde,
        .un-hasta {
          margin-bottom: 16px;
        }

        .input-container {
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #e0e0e0;

          .input-text {
            width: 48%;
            padding: 8px;

            p {
              font-family: "Montserrat";
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              color: var(--Utility-Colors-Primary-Text);
            }
          }

          .dropdown-divider {
            height: 28px;
            width: 1px;
            margin: 0 5px;
            border-top: 0px;
            border-right: 1px solid #e0e0e0;
          }
        }

        .input-text-large {
          width: 100%;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e0e0e0;
          padding: 8px 20px;
          justify-content: flex-start;
          margin-bottom: 5px;

          .deliberyPrice {
            font-family: "Montserrat";
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--Utility-Colors-Primary-Text);
            white-space: nowrap;
            margin: 0 0 0 8px;
          }

          .deliberyAddress {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            color: var(--Utility-Colors-Primary-Text);
            font-size: 16px;
            line-height: 24px;
          }
        }

        .alert-main-container {
          margin-bottom: 10px;
          .alert-main {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #979797;
          }
        }

        .dropdown-divider {
          margin: 16px 0 18px;
        }

        .extra {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 0;
          border-bottom: 1px solid #bdbdbd;

          .day-tag {
            display: flex;
            align-items: center;
            gap: 10px;

            .discount {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: var(--Utility-Colors-Secondary-Text);

              .discount-name {
                font-size: 14px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                margin: 0 5px;
              }

              a {
                margin-left: 5px;
                color: #2f80ed;
                text-decoration: underline;
              }
            }
          }

          .insurance-day,
          .price-day {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: var(--Brand-Colors-Secondary-Color);
            margin-bottom: 0;
          }

          h5 {
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 16px;
            color: var(--Utility-Colors-Primary-Text);

            img {
              margin-bottom: 5px;
            }
          }

          .discount-code {
            width: 221px;
          }

          h6 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #4f4f4f;

            img {
              margin-bottom: 5px;
            }
          }

          span {
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--Utility-Colors-Primary-Text);
          }

          b {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #3cb2b9;
          }

          span,
          b,
          h6 {
            font-family: "Montserrat";
          }
        }

        .subtotal {
          margin-top: 50px;
        }

        .total {
          display: none;
        }

        .warning {
          margin-top: 30px;
          .text-container {
            display: flex;
            align-items: center;
            background-color: var(--Semantic-Colors-Warning-Background);
            border-radius: 8px;
            margin: 0 auto;
            width: 309px;
            padding: 6px;
            gap: 12px;

            .alert-triangle {
              width: 25px;
              height: 25px;
            }

            p {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              text-align: left;
              color: var(--Brand-Colors-Black-color-fix);
              opacity: 1;
              margin-bottom: 0;
            }
          }
        }

        .terms {
          padding-top: 0px;

          .text-container {
            margin: 24px;
            text-align: center;

            a {
              text-decoration: underline;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #333333;
            }
          }
        }

        .checkboxes {
          display: flex;
          flex-direction: column;
          margin-top: 37px;

          .checkbox-form {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 15px;

            .check-error {
              position: relative;
              display: flex;
              font-weight: 600;
              font-size: 12px;
              line-height: 24px;
              color: #eb5757;
              margin: 0 0 0 9px;
            }

            .check-terms-form {
              margin: 0;
              .label-terms {
                display: flex;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                p {
                  margin-bottom: 0px;
                }
              }
            }

            img {
              width: 24px;
              height: 24px;
            }
          }
        }

        .pay-and-change {
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin: 20px 0;

          .pay-method {
            span {
              margin-top: 3px;
            }

            img {
              width: 40px;
            }

            .pay-method-label {
              width: 100%;
            }

            .card-item {
              width: 100%;
              display: flex;
              margin: 12px 0;
              align-items: center;
              border-radius: 5px;
              padding: 12px 6px;

              .card-data-container {
                display: flex;
                flex-direction: column;
                text-align: left;
                width: 100%;

                .card-data {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .card-data-left {
                    display: flex;
                  }
                  .card-brand {
                    width: 35px;
                    margin: 0 12px;
                  }
                }
                .card-num {
                  font-size: 16px 0;
                  font-weight: normal;
                  margin: 0;
                  padding-right: 8px;
                  width: 138px;
                }
                .card-exp {
                  font-family: "Montserrat";
                  font-size: 14px;
                  color: rgba(105, 115, 134, 0.5);
                  font-weight: normal;
                }
              }
            }
          }

          .btn-change {
            align-self: flex-end;
            width: 178px;
            height: 38px;
            background: #3cb2b9;
            border-radius: 40px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }

          .btn-change:active,
          .btn-change:hover,
          .btn-change:focus {
            outline: none !important;
          }

          .pay-options-label {
            margin-right: 40px;
          }
        }
      }
    }

    .total-fixed {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--Utility-Colors-Secondary-Background);
      z-index: 3;
      position: fixed;
      padding: 15px 26px;
      bottom: 0;

      .btn-confirm {
        width: 140px;
        height: 45px;
        background: #828282;
        border-radius: 40px;
        color: #fff;
        font-family: "Montserrat";
      }

      .btn-confirm:active,
      .btn-confirm:hover,
      .btn-confirm:focus {
        outline: none !important;
      }

      .total-price-information {
        display: flex;

        h5 {
          font-weight: bold;
        }

        p,
        h5 {
          color: var(--Utility-Colors-Primary-Text);
          display: flex;
          font-family: "Montserrat";
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 0px;
        }

        p {
          font-weight: 300;
          span {
            font-weight: bold;
            margin-right: 4px;
          }
        }
      }
    }

    .confirm-des {
      display: none;
    }
  }
}

.stripe-modal {
  .modal-content {
    width: 350px;
    height: 600px;

    iframe {
      width: 100%;
      height: 600px;
    }
  }
}

@media only screen and (min-width: 1108px) {
  .details {
    .details-container {
      display: flex;
      flex-flow: column;
      width: 1108px;
      margin: 0 auto;

      .details-navbar,
      .return-car {
        display: none;
      }

      .return-container-des {
        display: block;

        .return {
          display: flex;
          align-items: center;
          margin: 32px 0 45px 0;

          img {
            width: 5px;
            margin-right: 15px;
            filter: var(--Custom-Colors-Svg);
          }

          span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            color: var(--Utility-Colors-Primary-Text);
          }
        }

        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 42px;
          line-height: 46px;
          color: var(--Utility-Colors-Primary-Text);
          margin-bottom: 0px;
        }
      }

      .checkout-host-card {
        margin: 42px 0;
        width: 540px;
      }

      .rent-info {
        display: none;
      }

      .rent-info-des {
        display: block;
        width: 100%;
        height: fit-content;
        margin: 0 auto 40px;
        border-radius: 8px;

        .card-header {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--Custom-Colors-Title-Bar);
          border-radius: 8px 8px 0px 0px;
          height: 49px;

          h2 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: var(--Brand-Colors-White-color-fix);
            margin: 0;
          }
        }

        .card-body {
          width: 100%;
          padding: 0 35px;
          background: var(--Utility-Colors-Secondary-Background);
          display: flex;
          flex-wrap: wrap;
          border-radius: 0px 0px 8px 8px;

          h5 {
            margin: 0;
          }

          .card-politics {
            width: 381px;
            overflow: visible;

            .car-img,
            .default {
              width: 381px;
              height: 218px;
              object-fit: cover;
              border-radius: 16px;
            }

            .default {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid rgba(0, 0, 0, 0.08);

              img {
                width: 140px;
              }
            }

            .car-name {
              margin: 24px 0 20px;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: bold;
              font-size: 20px;
              line-height: 24px;
              text-align: left;
              color: var(--Utility-Colors-Primary-Text);
            }
          }

          .terms {
            width: 69%;
            display: flex;
            justify-content: space-between;

            .text-container {
              margin: 24px 0px 22px 0;

              a {
                text-decoration: underline;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #333333;
              }
            }
          }

          .fields-container {
            display: flex;
            flex-flow: column;
            margin: 71px 0 0 64px;
            width: 588px;

            label {
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              color: var(--Utility-Colors-Primary-Text);
              margin-bottom: 4px;
            }

            .un-container-date {
              display: flex;
              width: 100%;
            }

            .un-desde,
            .un-hasta {
              margin-bottom: 16px;
              width: 272px;
            }

            .input-container {
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: 1px solid #e0e0e0;

              .input-text {
                width: 48%;
                padding: 8px;

                p {
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: var(--Utility-Colors-Primary-Text);
                }
              }

              .dropdown-divider {
                height: 28px;
                width: 1px;
                margin: 0 5px;
                border-top: 0px;
                border-right: 1px solid #e0e0e0;
              }
            }

            .delibery-container {
              display: flex;
              justify-content: space-between;
              width: 588px;

              .delibery {
                width: 272px;

                .main-delibery {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  color: var(--Utility-Colors-Primary-Text);
                }

                .input-text-large {
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #e0e0e0;
                  padding: 8px 20px;
                  justify-content: flex-start;
                  margin-bottom: 7px;

                  .deliberyPrice {
                    font-family: "Montserrat";
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    white-space: nowrap;
                    color: var(--Utility-Colors-Primary-Text);
                    margin: 0 0 0 8px;
                  }

                  .deliberyAddress {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--Utility-Colors-Primary-Text);
                  }
                }

                .alert-main-container {
                  .alert-main {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #979797;
                  }
                }
              }
            }
          }
        }
      }

      .card {
        width: 100%;
        margin: 0 auto 40px;
        border-radius: 8px;

        .card-header {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--Custom-Colors-Title-Bar);
          border-radius: 8px 8px 0px 0px;
          height: 49px;

          h2 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: var(--Brand-Colors-White-color-fix);
            margin: 0;
          }
        }

        .card-body {
          width: 100%;
          padding: 20px 95px 0px;
          background: var(--Utility-Colors-Secondary-Background);
          border-radius: 0px 0px 8px 8px;

          h5 {
            margin: 0;
          }

          .car-img,
          .default {
            width: 315.11px;
            height: 252.94px;
            object-fit: cover;
            border-radius: 16px;
          }

          .default {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(0, 0, 0, 0.08);

            img {
              width: 140px;
            }
          }

          .car-name {
            margin: 16px 0 40px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: left;
            color: var(--Utility-Colors-Primary-Text);
          }

          label {
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: var(--Utility-Colors-Primary-Text);
            margin-bottom: 4px;
          }

          .un-desde,
          .un-hasta {
            margin-bottom: 16px;
          }

          .input-container {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #e0e0e0;

            .input-text {
              width: 48%;
              padding: 8px;

              p {
                font-family: "Montserrat";
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: var(--Utility-Colors-Primary-Text);
              }
            }

            .dropdown-divider {
              height: 28px;
              width: 1px;
              margin: 0 5px;
              border-top: 0px;
              border-right: 1px solid #e0e0e0;
            }
          }

          .input-text-large {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e0e0e0;
            padding: 8px 20px;
            justify-content: flex-start;
            margin-bottom: 32px;

            .deliberyPrice {
              font-family: "Montserrat";
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
              color: var(--Utility-Colors-Primary-Text);
              white-space: nowrap;
              margin: 0 0 0 8px;
            }

            .deliberyAddress {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
            }
          }

          .alert-main-container {
            .alert-main {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #979797;
            }
          }

          .dropdown-divider {
            margin: 16px 0 18px;
          }

          .extra {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h5 {
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 16px;
              color: var(--Utility-Colors-Primary-Text);

              img {
                margin-bottom: 5px;
              }
            }

            .discount-code {
              width: 494px;
            }

            h6 {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              color: var(--Utility-Colors-Primary-Text);

              img {
                margin-bottom: 5px;
              }
            }

            span {
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
              color: var(--Utility-Colors-Primary-Text);
            }

            b {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: var(--Utility-Colors-Primary-Text);
            }

            span,
            b,
            h6 {
              font-family: "Montserrat";
            }
          }

          .subtotal {
            margin-top: 25px;
          }

          .total {
            margin-top: 16px;
            display: flex;
            justify-content: flex-end;
            text-align: end;

            h5 {
              font-weight: bold;
            }

            p,
            h5 {
              display: flex;
              font-family: "Montserrat";
              font-size: 18px;
              line-height: 22px;
              color: var(--Utility-Colors-Primary-Text);
            }

            p {
              font-weight: 300;
              span {
                font-weight: bold;
                margin-left: 4px;
              }
            }
          }

          .warning {
            align-items: flex-start;

            .text-container {
              display: flex;
              justify-content: center;
              background-color: var(--Semantic-Colors-Warning-Background);
              color: var(--Brand-Colors-Black-color-fix);
              border-radius: 8px;
              margin: 0;
              width: 67%;

              p {
                font-size: 12px;
              }
            }
          }

          .terms {
            padding-top: 16px;

            .text-container {
              margin: 24px;
              text-align: center;

              a {
                text-decoration: underline;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #333333;
              }
            }
          }

          .checkboxes {
            display: none;
          }

          .pay-and-change {
            display: flex;
            justify-content: flex-start;

            .pay-method {
              display: flex;
              align-items: center;

              .card-num {
                display: inline;
              }

              p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: var(--Utility-Colors-Primary-Text);
                margin: 0 22px 0 0;
              }

              img {
                margin-right: 10px;
                width: 50px;
              }
            }
            .form-msi-total {
              color: #666;
              margin: -8px 0 0 28px;
              font-weight: unset;
              font-size: 12px;
            }

            .btn-change {
              align-self: flex-end;
              width: 100px;
              height: 38px;
              background: var(--Brand-Colors-Secondary-Color);
              border-radius: 40px;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
              margin-left: 22px;
            }

            .btn-change:active,
            .btn-change:hover,
            .btn-change:focus {
              outline: none !important;
            }
          }
        }
      }

      .total-fixed {
        display: none;
      }

      .insurance-container-mobile {
        display: none;
      }

      .confirm-des {
        display: flex;
        flex-direction: column;
        margin: 14px 0 50px;

        .insurance-container-desktop {
          padding-top: 59px;
        }

        .check-button {
          display: flex;
          flex-direction: column;
          width: 360px;
          align-self: flex-end;

          .checkboxes {
            display: flex;
            flex-direction: column;

            .checkbox-form {
              display: flex;
              flex-direction: column;
              margin: 10px 0 19px;

              .check-error {
                position: relative;
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                color: #eb5757;
                margin: 0 0 0 9px;
              }

              .check-terms-form {
                display: flex;
                margin: 0;
                width: 310px;
                .label-terms {
                  display: flex;
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  p {
                    color: var(--Utility-Colors-Primary-Text);
                    margin-bottom: 0px;
                  }
                }
              }

              img {
                width: 24px;
                height: 24px;
              }
            }
          }

          .btn-confirm {
            width: 322px;
            height: 50px;
            background: var(--Brand-Colors-Primary-Color);
            border-radius: 40px;
            font-weight: 700;
            color: var(--Brand-Colors-White-color-fix);
            font-family: "Montserrat";
          }

          .btn-confirm:active,
          .btn-confirm:hover,
          .btn-confirm:focus {
            outline: none !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .stripe-modal {
    .modal-content {
      width: 600px;
    }
  }
  .form-msi {
    width: 50%;
  }
}

@media only screen and (max-width: 1110px) {
  .pay-and-change {
    flex-direction: column;
    .pay-method {
      width: 100%;
    }
    .form-msi-total {
      color: #666;
      margin: -8px 0 0 28px;
      font-weight: unset;
      font-size: 12px;
    }
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: var(--Utility-Colors-Primary-Text);
      margin: 0 22px 0 0;
    }
  }
}
