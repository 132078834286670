.client-kyc-container {
  width: 95%;
  padding: 21px 16px 32px 16px;
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Colors-Custom-colors-Disabled-bg, #e9e9e9);
  background: var(--Colors-Utility-colors-Secondary-background, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  .client-kyc__information__title {
    h2 {
      font-family: "Montserrat";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
    }

    p {
      color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
      font-family: "Montserrat";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .client-kyc__main-status {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h3 {
      color: var(--Colors-Utility-colors-Primary-text, #151516);
      font-family: "Montserrat";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
    }

    .client-kyc__status-item-container {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      .client-kyc__status-item {
        display: flex;
        flex-direction: column;
        width: 240px;
        gap: 6px;

        .status-item__chip {
          width: fit-content;
        }

        .status-item__title {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }

  .not-paid-kyc {
    position: relative;

    .client-kyc__status-item-container {
      filter: blur(5px);
    }

    .modal-not-paid-kyc {
      position: absolute;
      display: flex;
      top: 39%;
      flex-direction: column;
      gap: 20px;
      width: 306px;
      padding: 16px 20px;
      border-radius: 12px;
      background: var(--Colors-Brand-colors-White-color-fix, #fff);
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .kyc-shield {
        align-self: center;
        width: 50px;
      }

      .modal-not-paid-kyc__body {
        display: flex;
        flex-direction: column;
        gap: 5px;

        h4 {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
        p {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
        }
      }
      .modal-not-paid-kyc__footer {
        width: 100%;

        .btn-plans {
          width: 100%;
          color: var(--Colors-Brand-colors-White-color-fix, #fff);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          border-radius: var(--radius-xl, 12px);
          border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
          background: var(--Colors-Brand-colors-Primary-color, #b72446);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
      }
    }
  }

  .dropdown-divider {
    margin: 16px auto;
    background: #d1d1d1;
    height: 1px;
    width: 100%;
  }

  .client-kyc__security-docs {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    h3 {
      color: var(--Colors-Utility-colors-Primary-text, #151516);
      font-family: "Montserrat";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .client-kyc__security-docs-item {
      display: flex;
      flex-direction: column;

      .docs-item-title {
        display: flex;
        align-items: center;
        gap: 10px;

        h4 {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }

        .status {
          display: flex;

          svg {
            width: 16px;
            height: 16px;
          }

          p {
            color: var(--Colors-Semantic-colors-Success-fg, #2e7d32);
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
      }

      .docs-item-body {
        margin: 10px 0 14px;

        .doc-position {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }

        .dz-uploaded {
          width: 358px;
        }

        .kyc-images {
          border-radius: var(--radius-sm, 6px);
          background: var(--Colors-Brand-colors-White-color-fix, #fff);
          box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
            0px 1px 2px 0px rgba(16, 24, 40, 0.06);
          width: 158px;
          height: 100px;
          object-fit: cover;
        }
      }

      .decline {
        color: var(--Colors-Custom-colors-hyperlinks, #0a7df5);
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  .client-kyc__information {
    h3 {
      color: var(--Colors-Utility-colors-Primary-text, #151516);
      font-family: "Montserrat";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .client-kyc__information-container {
      .client-kyc__information-text {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin-bottom: 36px;

        .information-text-item {
          width: 240px;

          p {
            color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
            font-family: "Montserrat";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
          }

          span {
            color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .address {
          width: 340px;
        }
      }

      .client-kyc__information-images {
        p {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 0;
        }

        .information-images-container {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          margin-bottom: 10px;

          .information-images-item-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .information-images-item {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: var(--radius-sm, 6px);
              background: var(--Colors-Brand-colors-White-color-fix, #fff);
              box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
                0px 1px 2px 0px rgba(16, 24, 40, 0.06);
              width: 180px;
              height: 100px;

              img {
                width: 170px;
                object-fit: cover;
                height: 90px;
              }
            }
          }
        }
      }
    }
  }

  .client-kyc__report-ids {
    h3 {
      color: var(--Colors-Utility-colors-Primary-text, #151516);
      font-family: "Montserrat";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .client-kyc__report-ids-container {
      .report-ids-item {
        display: flex;
        align-items: center;
        gap: 12px;

        p {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 0;
        }

        a {
          color: var(--Colors-Custom-colors-hyperlinks, #0a7df5);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .client-kyc-container {
    width: 100%;
    max-width: 1254px;

    .client-kyc__main-status {
      gap: 32px;

      h3 {
        font-size: 24px;
      }

      .client-kyc__status-item-container {
        gap: 32px;
      }
    }

    .client-kyc__information {
      h3 {
        font-size: 24px;
      }

      .client-kyc__information-container {
        .client-kyc__information-text {
          gap: 32px;

          .address {
            width: 360px;
          }
        }
      }
    }
  }
}
