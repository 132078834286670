.portal-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fafafa;
  min-height: calc(100vh - 64px);

  .portal__join-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 21px 16px 32px 16px;

    .portal__title-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .portal__route {
        display: flex;
        gap: 8px;
        align-items: center;

        .portal__route__arrow {
          filter: invert(94%) sepia(7%) saturate(226%) hue-rotate(179deg)
            brightness(92%) contrast(88%);
          width: 10px;
          height: 10px;
        }

        .portal__route__first,
        .portal__route__second {
          color: #475467;
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .portal__title {
        font-family: "Montserrat";
        color: #151516;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
      }

      .portal__subtitle {
        color: #4a4a57;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }

  .portal-customize__information {
    width: 95%;
    padding: 21px 16px 32px 16px;
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Custom-colors-Disabled-bg, #e9e9e9);
    background: var(--Colors-Utility-colors-Secondary-background, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .portal-customize__information__title {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 32px;

      h2 {
        font-family: "Montserrat";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 0;
      }

      p {
        color: var(--Colors-Utility-colors-Primary-text, #151516);
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .portal-customize__basic-settings {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .portal-customize__basic-settings-title {
        h3 {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 0;
        }
      }

      .portal-customize__basic-settings-container {
        .portal-customize__basic-settings-assets {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .basic-settings-assets__title {
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;

            p {
              color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
              font-family: "Montserrat";
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 0;
            }

            a {
              color: var(--Colors-Custom-colors-hyperlinks, #0a7df5);
              font-family: "Montserrat";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
            }
          }

          .basic-settings-assets__color-picker-container {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .color-picker-container {
              display: flex;
              flex-direction: column;
              gap: 12px;

              .logo-input {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                width: 165px;
                height: 88px;
                margin: 0;
              }

              .logo-icon-input {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                width: 96px;
                height: 96px;
                margin: 0;
              }

              .color-picker,
              .ant-color-picker-color-block {
                padding: 0;
                border: 0px;
                width: 96px;
                height: 96px;
              }

              .color-picker-text {
                display: flex;
                flex-direction: column;

                p {
                  color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                  font-family: "Montserrat";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  margin-bottom: 0;
                }

                span {
                  color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                  font-family: "Montserrat";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .portal-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: 65px;
    gap: 24px;

    .portal__join-container {
      max-width: 1254px;
      width: 100%;
      margin: 0;
      padding: 32px 0 24px;

      .portal__title-container {
        text-align: left;

        .portal__title {
          font-size: 30px;
        }
      }
    }

    .portal-customize__information {
      max-width: 1254px;
      width: 100%;
      margin: 0;

      .portal-customize__information__title {
        h2 {
          font-size: 30px;
        }

        p {
          max-width: 680px;
        }
      }

      .portal-customize__basic-settings {
        display: flex;

        .portal-customize__basic-settings-assets {
          width: 50%;
        }
        .portal-customize__basic-settings-container {
          display: flex;
          justify-content: space-between;

          .portal-customize__basic-settings-preview {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 50%;

            .basic-settings-preview__title {
              p {
                color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                font-family: "Montserrat";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 0;
              }

              span {
                color: var(--Colors-Utility-colors-Tertiary-text, #6f7285);
                font-family: "Montserrat";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
              }
            }

            .portal-preview {
              width: 100%;
              height: 524px;
              overflow: hidden;
              border: 1px solid #ccc;
              border-radius: 8px;
              position: relative;

              .portal-preview-iframe {
                width: 1524px;
                height: 1368px;
                transform: scale(0.4);
                pointer-events: none;
                transform-origin: top left;
                border: none;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}
