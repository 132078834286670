.destinations {
  margin-top: 10px;
  width: 100%;
  .next-destinations {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 11.4182px;
    line-height: 11px;
    color: #3cb2b9;
    margin: 10px 0 0 13px;
  }

  .btn-state {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12.8455px;
    line-height: 17px;
    border: 0px;
  }
  .btn-state:hover {
    background: #ecf7f8;
    border-radius: 2.85456px;
  }

  .btn-place {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;
    margin-right: 10px;
    border-radius: 50%;
    background: var(--Brand-Colors-Primary-Color);
    img {
      width: 15px;
    }
  }
}
