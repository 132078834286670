.modal-dialog {
  max-width: none;
}
.trip-complete-profile-modal {
  display: flex !important;
  align-items: center;

  .modal-content {
    background-color: var(--Utility-Colors-Primary-Background);
    width: 327px;
    height: 279px;
    margin: 0 auto;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;

    .modal-header,
    .modal-body {
      padding: 10px 14px 0;
    }

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 210px;
        color: var(--Utility-Colors-Primary-Text);
        margin: 17px auto 24px;
        line-height: 30px;
      }
    }

    .modal-body {
      display: flex;
      flex-flow: column;
      align-items: center;

      p {
        font-family: "Montserrat";
        font-weight: normal;
        font-size: 16px;
        color: var(--Utility-Colors-Primary-Text);
        line-height: 24px;
      }
    }

    .modal-body,
    .modal-footer {
      padding-top: 0;
    }

    .modal-header,
    .modal-footer {
      border: 0px;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;

      .information {
        text-align: left;
      }

      .btn-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 272px;
        height: 52px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        color: #fff;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        margin: 0 11px 0 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .trip-complete-profile-modal {
    .modal-content {
      width: 720px;
      height: 571px;

      .modal-header {
        h2 {
          font-size: 20px;
          line-height: 30px;
          width: 373px;
          margin-bottom: 7px 0 28px;
        }
      }

      .modal-body {
        justify-content: center;
        padding: 0 48px;
        gap: 32px;
      }

      .modal-footer {
        padding-bottom: 50px;

        .btn-orange {
          width: 223px;
        }
      }
    }
  }
}
