.reservation-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 32px;

  .join-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    .title-container {
      text-align: center;

      h1 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #195154;
        margin: 32px 0;
      }
    }

    .card-trip {
      .list-group {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }

  .reservation-card {
    .title-rental {
      margin: 30px 0 40px;

      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        margin: 0;
        text-align: center;
        color: #4f4f4f;
      }
    }
    .card-trip {
      .list-group {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .list-group-item {
          .car-image {
            img {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .card::-webkit-scrollbar {
      width: 8px;
      height: 100%;
    }

    .card::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #f2f2f2;
      width: 8px;
      margin: 30px 0;
    }

    .card::-webkit-scrollbar-thumb {
      background: #3cb2b9;
      width: 8px;
      border-radius: 4px;
    }
  }
}

.empty-trip {
  display: flex;
  justify-content: center;
  padding: 40px 0;

  .empty-container {
    .empty_cars {
      width: 309.88px;
      height: 237px;
    }

    .empty-text {
      text-align: center;
      font-size: 18px;
      width: 310px;
      line-height: 30px;
      color: #3cb2b9;
    }

    .empty-text,
    .empty-btn-orange {
      font-family: "Montserrat";
      font-weight: bold;
    }

    .empty-btn-orange {
      width: 311px;
      height: 52px;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 24px;
      color: #ffffff;
    }
  }
}

@media only screen and (min-width: 1130px) {
  .reservation-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 32px;

    .join-container {
      width: 90%;
      max-width: 1215px;

      .title-container {
        text-align: left;
        width: 100%;

        h1 {
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 42px;
          line-height: 46px;
          color: #195154;
          margin: 32px 0;
        }
      }

      .card-trip {
        width: 100%;
        .list-group {
          .empty-trip {
            .empty-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              .empty_cars {
                width: 444px;
                height: 341.22px;
              }
            }
          }
        }
      }
    }

    .reservation-card {
      width: 90%;
      max-width: 1215px;

      .title-rental {
        width: 100%;
        margin: 10px 0 32px;

        h3 {
          font-size: 30px;
          line-height: 46px;
          margin: 0;
          text-align: left;
          color: #4f4f4f;
        }
      }

      .card-trip {
        width: 100%;
      }
    }
  }

  .empty-trip {
    .empty-container {
      .empty_cars {
        width: 540px;
        height: 413px;
      }

      .empty-text {
        line-height: 30px;
        text-align: center;
        color: #3cb2b9;
        width: 100%;
      }

      .empty-text,
      .empty-btn-orange {
        font-size: 20px;
        font-family: "Montserrat";
        font-weight: bold;
      }

      .empty-btn-orange {
        width: 285px;
        height: 52px;
        line-height: 25px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        color: #ffffff;
        margin: 32px auto 0px;
        display: block;
      }
    }
  }
}
