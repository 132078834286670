.modal-dialog {
  margin: 0 auto !important;
}
.login-continue-modal {
  .modal-content {
    width: 340px;
    height: 600px;
    margin: 100px auto;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;

    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 50%;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
        }
      }

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 207px;
        margin: 0 auto 4px;
        line-height: 30px;
      }
    }

    .modal-body {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 0 12px;

      h3 {
        font-family: "Montserrat";
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 8px 0 26px;
        width: 283px;
      }

      .login-content {
        width: 100%;

        .form {
          display: flex;
          justify-content: flex-start;
          flex-flow: column;

          p {
            text-align: left;
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #373a3c;
            margin-bottom: 16px;
            padding: 0 0 0 2px;
          }

          .dropdown-item {
            background-color: transparent;
          }

          .log-input {
            background: #ffffff;
            padding: 8px 16px 9px;
            border: 1px solid #828282;
            border-radius: 40px;
            margin-bottom: 32px;
          }

          .input-group {
            border-radius: 40px;
            width: 100%;

            input {
              height: 43px;
              border: 1px solid #828282;
              border-right: 0px;
              width: 80%;
              padding: 8px 16px 9px;
              border-radius: 40px 0px 0px 40px;
            }

            input:focus {
              box-shadow: none;
              border-color: #828282;
            }

            .input-group-addon {
              border: 1px solid #828282;
              background: #ffffff;
              border-radius: 0px 40px 40px 0px;
              border-left: 0px;
              height: 43px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 19%;
            }
          }

          input::placeholder {
            font-size: 14px;
            line-height: 24px;
            color: #828282;
          }

          input:focus,
          .input-group:focus-within {
            border-color: #828282;
            box-shadow: 0px 4px 18px rgba(209, 217, 230, 0.8);
          }

          input:active,
          input:hover,
          input:focus {
            outline: none !important;
          }
        }

        .forgot {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          text-decoration-line: underline;
          color: #2f80ed;
          display: flex;
          justify-content: flex-start;
          margin: 10px 0 16px;
        }
      }
    }

    .modal-body,
    .modal-footer {
      padding-top: 0;
    }

    .modal-header,
    .modal-footer {
      border: 0px;
    }

    .modal-footer,
    .modal-body {
      text-align: center;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;

      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: auto;
        width: 272px;
        height: 38px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        color: #fff;
        font-size: 14px;
      }

      .btn-create {
        background: #3cb2b9;
        border-radius: 40px;
        margin: auto;
        width: 272px;
        height: 38px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        color: #fff;
        font-size: 14px;
      }

      .or {
        margin: 0 auto;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }

      .btn-social {
        margin: auto;
        width: 312px;
        height: 38px;
        padding-left: 54px;
        font-family: "Montserrat";
        font-weight: 600;
        color: #828282;
        font-size: 14px;
        border: 1px solid #bdbdbd;
        box-sizing: border-box;
        border-radius: 40px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .login-continue-modal {
    .modal-content {
      width: 635px;
      .modal-header {
        h2 {
          font-size: 20px;
          line-height: 24px;
          width: 373px;
        }
      }
      .modal-body {
        h3 {
          width: 445px;
          margin: 0px 0 32px;
        }
        .login-content {
          width: 450px;
        }
      }
      .modal-footer {
        width: 445px;
        margin: 0 auto;
        padding-bottom: 60px;
        .btn-orange,
        .btn-create {
          width: 445px;
        }
      }
    }
  }
}
