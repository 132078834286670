.results-container {
  background: var(--Utility-Colors-Primary-Background);

  .filter-container-desktop {
    display: none;
  }

  .car-container-index {
    padding-top: 24px;
    max-width: 1490px;

    .filter-car {
      .resultsM {
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--Utility-Colors-Primary-Text);
        margin-bottom: 26px;
        padding-left: 16px;
      }

      .btn-order {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--Utility-Colors-Secondary-Background);
        border-radius: 40px;
        width: 312px;
        margin: 16px auto 23px;

        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--Utility-Colors-Primary-Text);
          margin: 2px 0 0 3px;
        }

        img {
          filter: var(--Custom-Colors-Svg);
        }
      }
    }

    .filter-car-desktop {
      display: none;
    }

    .car-index {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .empty-state__container {
        width: 80%;
        margin: 0 auto;
        text-align: center;

        h3,
        p,
        a {
          font-family: "Montserrat";
          font-style: normal;
          color: var(--Utility-Colors-Primary-Text);
        }

        h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 24px;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24.38px;
        }

        a {
          text-underline-offset: 3px;
        }

        img {
          width: 100%;
        }
      }

      .car-img {
        max-height: 180px;
        width: 100%;
        object-fit: cover;
      }

      .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 275px;
        width: 342px;
        margin-bottom: 31px;
        border-radius: 8px;
        font-family: "Montserrat";
        background: var(--Utility-Colors-Secondary-Background);

        .heart {
          right: 16px;
          top: 16px;
          position: absolute;
        }

        .trips-tag {
          position: absolute;
          background-color: #f4f4f4;
          border-radius: 4px;
          margin: 12px;
          font-size: 11px;
          width: 90px;
          height: 21px;
          font-weight: 500;
        }

        .counter {
          left: 16px;
          top: 10px;
          position: absolute;
          color: var(--Brand-Colors-Primary-Color);
          background-color: #fff;
          padding: 2.5px;
        }

        .car-image {
          height: 140px;

          .car {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
          }

          .skeleton {
            height: 140px;
            object-fit: cover;
            width: 100%;
          }

          .default-car {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 140px;
          }
        }

        .card-body-title {
          .card-header-div {
            padding: 12px 0 5px;
            .card-title {
              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              line-height: 20px;
              margin: 0 8px 0 0;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              padding-left: 10px;
              color: var(--Utility-Colors-Primary-Text);
            }

            img {
              padding-right: 8px;
            }
          }

          .card-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 11.5451px;
            line-height: 14px;
            margin: 5px 0;
            padding-left: 10px;
            color: var(--Utility-Colors-Primary-Text);

            img {
              margin: 0 3px;
            }
          }

          hr {
            margin: 4px 0;
            color: var(--Custom-Colors-Lines);
          }
        }

        .card-body {
          height: 60px;
          padding: 0 8px;
          max-height: 60px;

          .car-data-box {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .car-insurance-data > p:nth-child(1) {
              font-weight: 700;
              padding-right: 4px;
            }

            .car-insurance-data > p:nth-child(1) > svg {
              font-size: 14px;
              margin-right: 4px;
            }

            .car-insurance-data {
              margin: 0;
              font-weight: normal;
              font-family: "Montserrat";
              padding: 6px 8px;

              p {
                margin: 0;
                font-size: 11px;
                line-height: 13px;
                color: var(--Utility-Colors-Primary-Text);
              }
            }
          }

          .car-data-item {
            padding: 0 4px;
            align-items: center;
            color: var(--Utility-Colors-Primary-Text);
            p {
              margin: 0;
              font-weight: 500;
              font-size: 11px;
              line-height: 16px;
              color: var(--Utility-Colors-Primary-Text);
            }
            img {
              border-radius: unset;
              size: 14px;
              filter: var(--Custom-Colors-Svg);
            }
          }

          .card-price-box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;

            p {
              margin: 2px 0;
              color: var(--Utility-Colors-Primary-Text);
            }

            .card-price {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              margin: 0;
              line-height: 23px;
              text-align: end;
              margin: 0 0 0 4px;

              del {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                margin-right: 8px;
              }
            }
          }

          .card-price-box > p:nth-child(2) {
            text-decoration: underline;
            font-size: 12px;
            line-height: 16px;
            text-underline-offset: 3px;
          }
        }
      }
    }

    .car-pagination {
      display: flex;
      align-items: center;

      .MuiPagination-ul {
        .MuiSvgIcon-root {
          filter: var(--Custom-Colors-Svg);
        }

        .MuiButtonBase-root {
          font-size: 18px;
        }

        .css-1ysyrvn-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
          font-weight: 700;
          color: var(--Utility-Colors-Primary-Text);
          background: var(--Utility-Colors-Secondary-Background);
          border: none;
        }
      }
    }
  }
}

.btn-filter {
  display: none;
}

.price-popover-item > p {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 16px;
  padding: 4px 0;
}

.price-popover-item > p:nth-child(1) {
  font-weight: 700;
  margin: 0;
}

.price-popover-item p > span:nth-child(1) {
  padding-right: 16px;
}

.price-popover-box {
  padding: 10px 0;
}

.price-popover-box > p {
  font-family: "Montserrat";
  font-weight: 700;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}

.modal-content-order {
  background: var(--Utility-Colors-Primary-Background);

  .select {
    width: 100%;
    background: var(--Utility-Colors-Secondary-Background);
    border-radius: 40px;
    margin-bottom: 16px;
    height: 50px;
    background: var(--Utility-Colors-Secondary-Background);
    margin-bottom: 16px;
    height: 50px;
    color: var(--Utility-Colors-Secondary-Text);
    border-radius: 40px;
    font-family: "Montserrat";

    .MuiSvgIcon-root {
      filter: var(--Custom-Colors-Svg);
    }
  }

  p {
    font-family: "Montserrat";
    color: var(--Utility-Colors-Secondary-Text);
  }

  .MuiAccordion-root {
    background: var(--Utility-Colors-Primary-Background);

    .MuiFormControlLabel-label {
      color: var(--Utility-Colors-Secondary-Text);
    }

    .MuiAccordionSummary-expandIconWrapper {
      color: var(--Utility-Colors-Secondary-Text);
    }
  }

  .price-input {
    .MuiSlider-valueLabelOpen {
      background-color: #3cb2b9;
      border-radius: 8px;
      font-family: "Montserrat";
      font-weight: 400;
    }

    label {
      margin: 0 16px 4px 16px;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var(--Utility-Colors-Secondary-Text);
    }
    .MuiInputBase-root {
      border-radius: 40px;
      height: 44px;
      margin: 0 8px;
      background: var(--Utility-Colors-Secondary-Background);
      font-family: "Montserrat";
      color: var(--Utility-Colors-Primary-Text);

      fieldset {
        border: none;
      }
    }
  }

  .bags-seats {
    label {
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--Utility-Colors-Secondary-Text);
      margin-bottom: 8px;
    }
  }

  .modal-filter-header {
    padding: 12px 0;
    button {
      width: 12px;
    }
    span {
      padding-right: 14px;
    }
  }

  .option-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    color: var(--Utility-Colors-Primary-Text);
  }
}

.MuiTypography-root,
.MuiPaper-root {
  font-family: "Montserrat" !important;
}

@media only screen and (min-width: 700px) {
  .results-container {
    .car-container-index {
      .car-index {
        justify-content: flex-start;
        padding: 8px 16px;

        .card {
          margin: 0 10px 31px;
        }

        .empty-state__container {
          width: 80%;
          margin: 0 auto;
          text-align: center;

          h3 {
            font-size: 32px;
          }

          p {
            font-size: 20px;
            font-weight: 600;
          }

          img {
            width: 70%;
          }

          .text-1 {
            margin-bottom: 53px;
          }

          .text-2 {
            margin-top: 38px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .results-container {
    display: flex;

    .filter-container-desktop {
      display: block;
      width: 380px;
      height: 100%;
      position: sticky;
      top: 10px;
      margin: 70px 8px 0 8px;

      p {
        font-family: "Montserrat";
        color: var(--Utility-Colors-Secondary-Text);
      }

      .results-filter-checkout {
        svg {
          color: var(--Brand-Colors-Secondary-Color);
        }
      }

      .results-filter-switch {
        .MuiSwitch-thumb {
          background-color: #000;
        }
        .Mui-checked + .MuiSwitch-track {
          background-color: var(--Brand-Colors-Secondary-Color);
          opacity: 1;
        }
      }

      .results-filter-slider {
        .MuiSlider-track,
        .MuiSlider-rail,
        .MuiSlider-thumb {
          background-color: var(--Brand-Colors-Secondary-Color);
          color: var(--Brand-Colors-Secondary-Color);
        }

        .MuiSlider-thumb {
          .MuiSlider-valueLabelOpen {
            background-color: var(--Brand-Colors-Secondary-Color);
          }
        }
      }

      .MuiAccordion-root {
        background: var(--Utility-Colors-Primary-Background);

        .MuiFormControlLabel-label {
          color: var(--Utility-Colors-Secondary-Text);
        }

        .MuiAccordionSummary-expandIconWrapper {
          color: var(--Utility-Colors-Secondary-Text);
        }
      }

      .MuiFormLabel-root {
        color: var(--Utility-Colors-Primary-Text);
        font-family: "Montserrat";
      }

      .dropdown {
        width: 100%;
        margin-bottom: 16px;
        background: var(--Utility-Colors-Secondary-Background);
        border-radius: 40px;
        color: var(--Utility-Colors-Secondary-Text);
        font-family: "Montserrat";

        .dropdown-toggle {
          background: #ecf7f8;
          border-radius: 10px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          .bs-caret {
            display: none;
          }

          span {
            color: #195154;
          }
        }

        .dropdown-menu {
          span {
            font-weight: normal;
            color: #3e4142;
          }
        }

        .dropdown-toggle::after {
          margin: 0 0 -0.4em -0.4em;
        }

        .MuiSvgIcon-root {
          filter: var(--Custom-Colors-Svg);
        }
      }

      .option-title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24.38px;
        margin: 0;
        color: var(--Utility-Colors-Primary-Text);
      }

      .dropdown-divider {
        margin-bottom: 16px;
      }

      .drop-options {
        flex-flow: column;
        justify-content: flex-start;

        .option {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #3e4142;
          margin-bottom: 24px;
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 18px;

          input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .round {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #e0e0e0;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }

          .round:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: -3px;
            bottom: -1px;
            background: #4f4f4f;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }

          input:checked + .round {
            background: #3cb2b9;
          }

          input:checked + .round:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
            background: #195154;
          }

          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        }
      }

      .values-container {
        margin-top: 62px;

        b {
          font-size: 12px;
          line-height: 16px;
          color: #333333;
        }
      }

      .slider-horizontal {
        width: 100%;
        margin-top: 34px;

        .slider-handle {
          border: 4px solid var(--Brand-Colors-Primary-Color);
          background: #fff;
        }

        .slider-track {
          height: 5px;
          top: 12px;

          .slider-selection {
            background-image: none;
            background-color: var(--Brand-Colors-Primary-Color);
          }
        }

        .tooltip-inner {
          background-color: var(--Brand-Colors-Primary-Color);
        }

        .arrow,
        .arrow::before {
          border-top-color: var(--Brand-Colors-Primary-Color);
          border-bottom-color: var(--Brand-Colors-Primary-Color);
        }
      }

      .btn-orange {
        display: block;
        width: 255px;
        height: 52px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: 48px auto 0;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;
        color: var(--Brand-Colors-White-color-fix);
      }

      .select {
        width: 100%;
        background: var(--Utility-Colors-Secondary-Background);
        border-radius: 40px;
        margin-bottom: 16px;
        height: 50px;
        background: var(--Utility-Colors-Secondary-Background);
        margin-bottom: 16px;
        height: 50px;
        color: var(--Utility-Colors-Secondary-Text);
        border-radius: 40px;
        font-family: "Montserrat";

        .MuiSvgIcon-root {
          filter: var(--Custom-Colors-Svg);
        }
      }

      .price-input {
        .MuiSlider-valueLabelOpen {
          background-color: #3cb2b9;
          border-radius: 8px;
          font-family: "Montserrat";
          font-weight: 400;
        }

        label {
          margin: 0 16px 4px 16px;
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: var(--Utility-Colors-Secondary-Text);
        }
        .MuiInputBase-root {
          border-radius: 40px;
          height: 44px;
          margin: 0 8px;
          background: var(--Utility-Colors-Secondary-Background);
          font-family: "Montserrat";
          color: var(--Utility-Colors-Primary-Text);

          fieldset {
            border: none;
          }
        }
      }

      .bags-seats {
        label {
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--Utility-Colors-Secondary-Text);
          margin-bottom: 8px;
        }
      }
    }

    .car-container-index {
      margin: 69px 0 0 0;
      width: 100%;
      max-width: 1490px;

      .filter-car {
        display: none;
      }

      .filter-car-desktop {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        .results {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 30px;
          color: #f76f8e;
        }
        .sort-by-container {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-bottom: 14px;
          justify-content: space-between;

          p {
            margin: 0;
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            color: var(--Utility-Colors-Primary-Text);
          }

          .form__select-sort {
            width: 160px;
            height: 48px;
            .MuiFormLabel-root {
              color: var(--Utility-Colors-Primary-Text);
              font-family: "Montserrat";
            }
            .dropdown {
              width: 160px;
              height: 48px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: var(--Utility-Colors-Secondary-Background);
              border-radius: 40px;
              color: var(--Utility-Colors-Secondary-Text);
              font-family: "Montserrat";

              span {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #333333;
                margin: 2px 0 0 3px;
              }

              .MuiOutlinedInput-notchedOutline {
                border: 0;
              }

              .dropdown-menu {
                top: 40px;
              }

              .MuiSvgIcon-root {
                filter: var(--Custom-Colors-Svg);
              }
            }
          }
        }
      }

      .car-index {
        .card {
          width: 447px;
          height: 326px;
          box-shadow: 0.5px 0px 4px rgba(0, 0, 0, 0.1),
            0px 3px 4px rgba(0, 0, 0, 0.1);
          border-radius: 8px;

          .car-image {
            height: 190px;

            .skeleton {
              height: 190px;
            }

            .default-car {
              height: 190px;
            }
          }

          .car-img {
            max-height: 184px;
            width: 447px;
            object-fit: cover;
          }

          .card-body {
            .card-header-div {
              .card-title {
                font-size: 18px;
                line-height: 22px;
              }
            }

            .card-text {
              font-size: 12px;
              line-height: 20px;
              img {
                width: 14px;
              }
            }

            .card-text:nth-child(2) {
              padding: 0;
            }

            hr {
              margin: 4px 0;
            }

            .card-price-box {
              display: flex;
              flex-direction: column;
              align-items: center;

              .card-price {
                font-size: 24px;

                del {
                  font-size: 14px;
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sort-list {
  text-align: right;
  justify-content: flex-end !important;
  width: 100%;
  gap: 2px;
}
