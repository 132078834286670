.client-reservation-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 95%;
  position: relative;
  padding: 21px 16px 32px 16px;

  .client-reservation-card {
    width: 100%;
    .client-reservation-card__title-container {
      display: flex;
      flex-direction: column;

      .client-reservation-card__title {
        h3 {
          color: var(--Colors-Utility-colors-Primary-text, #151516);
          font-family: "Montserrat";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }

        p {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }

      .client-reservation-card__filters {
        display: flex;
        justify-content: space-between;
        gap: 6px;

        .client-reservation-card__filters__select {
          width: 100%;
          max-width: 350px;
          border-radius: var(--radius-xl, 12px);
          background: var(--Colors-Utility-colors-Secondary-background, #fff);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        .client-reservation__search__add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 12px;
          border: 1px solid #d1d1d1;
          background: #fff;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

          svg {
            color: #4a4a57;
          }
        }
      }
    }

    .card-trip {
      .list-group {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .list-group-item-trips {
          position: relative;

          .car-image {
            img {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .card::-webkit-scrollbar {
      width: 8px;
      height: 100%;
    }

    .card::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #f2f2f2;
      width: 8px;
      margin: 30px 0;
    }

    .card::-webkit-scrollbar-thumb {
      background: #3cb2b9;
      width: 8px;
      border-radius: 4px;
    }
  }
}

@media only screen and (min-width: 1170px) {
  .client-reservation-container {
    width: 100%;
    max-width: 1254px;
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Custom-colors-Disabled-bg, #e9e9e9);
    background: var(--Colors-Utility-colors-Secondary-background, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .client-reservation-card {
      max-width: 1215px;

      .client-reservation-card__title-container {
        width: 100%;
        flex-direction: row;
        margin: 0px 0 32px;

        .client-reservation-card__title {
          h3 {
            font-size: 30px;
            margin: 0;
            text-align: left;
            color: #4f4f4f;
          }
          p {
            margin: 0;
          }
        }

        .client-reservation-card__filters {
          width: calc(100% - 315px);

          .client-reservation-card__filters__select {
            width: 145px;
            height: 40px;
            margin-left: 20px;
          }
          .btn-add {
            display: flex;
            align-items: center;
            gap: 6px;
            border-radius: var(--radius-xl, 12px);
            border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
            background: var(--Colors-Brand-colors-Primary-color, #b72446);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            width: 240px;
            height: 40px;

            p {
              color: var(--Colors-Brand-colors-White-color-fix, #fff);
              font-family: "Montserrat";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 0;
            }

            img {
              width: 28px;
            }
          }
        }
      }

      .card-trip {
        width: 100%;
        height: 500px;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: block;
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--Colors-Custom-colors-divider-fg, #d1d1d1);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: #fff;
        }
      }
    }
  }
}
