.list-checkboxes {
  display: flex;
  flex-direction: column;

  .list-checkboxes__title {
    width: 100%;
    border-bottom: 1px solid #d1d1d1;
    color: var(--Colors-Utility-colors-Secondary-background, #fff);
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .list-checkboxes__list__container {
    display: flex;
    z-index: 9;
    flex-direction: column;
    padding: 16px 16px;
    row-gap: 12px;
    width: 249px;

    .list-checkboxes__list__container__form {
      display: flex;
      margin: 0;
      height: fit-content;
      gap: 12px;

      .list-checkboxes__list__container__check {
        padding: 0;

        .icons {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
