.profile-boxes {
  .boxes-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .profile-update {
      margin-bottom: 40px;

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: var(--Utility-Colors-Primary-Text);
        margin: 0 0 14px;
        width: 328px;
      }
    }

    .anfitrion-info,
    .pay-method {
      display: flex;
      flex-flow: column;
      width: 328px;
      height: 352px;
      margin-bottom: 50px;
      text-align: center;
      align-items: center;
      background: var(--Utility-Colors-Secondary-Background);
      box-sizing: border-box;
      border-radius: 10px;

      .method-img {
        width: 183px;
        height: 199.77px;
      }

      h4 {
        font-weight: 600;
      }

      .p {
        font-weight: normal;
      }

      h4,
      p {
        color: var(--Utility-Colors-Primary-Text);
        font-family: "Montserrat";
        font-size: 16px;
        line-height: 24px;
      }

      .btn-select {
        width: 117px;
        height: 38px;
        border-radius: 40px;
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 14px;
        color: #fff;
        line-height: 21px;
        background: var(--Brand-Colors-Secondary-Color);
      }

      .pay-and-change-box {
        flex-direction: column;
        width: 100%;
        .pay-and-change {
          margin-top: 12px;
          width: 100%;
          display: flex;
          flex-direction: row;
          padding-right: 8px;
          justify-content: center;

          .img-brand {
            width: 46px;
            margin-right: 14px;
          }

          .pay-card {
            display: flex;
            margin-right: 8px;
            align-items: center;

            p {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              color: var(--Utility-Colors-Primary-Text);
            }

            .form-default-card {
              strong {
                color: var(--Utility-Colors-Primary-Text);
              }
            }

            .card-num {
              display: none;
            }

            .check {
              justify-content: flex-end;
              width: 16px;
              height: 11px;
              margin-left: 85px;
            }
          }
          span {
            display: inline-block;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            height: 11px;
            color: #219653;
          }
        }
      }
      .btn-change {
        margin-top: 12px;
        width: 88px;
        height: 38px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        margin-left: 8px;
      }

      .btn-change:active,
      .btn-change:hover,
      .btn-change:focus {
        outline: none !important;
      }
    }

    .anfitrion-comercial-info {
      width: 335px;
      margin-bottom: 50px;
      display: flex;

      .contact-us {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: var(--Utility-Colors-Primary-Text);
        a {
          text-decoration: underline;
          color: #008de0;
        }
      }

      .card {
        border: 0px;
        padding: 10px;
        background: var(--Utility-Colors-Secondary-Background);

        .title-status-container {
          .title {
            font-family: "Montserrat";
            text-align: left;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: var(--Utility-Colors-Primary-Text);
            margin: 30px 0 5px;
            order: 2;
          }
          .verifying-comercial-host {
            order: 1;
            width: 100%;
            display: flex;
            margin-top: 20px;

            .verifying {
              display: flex;
              justify-content: space-between;

              span {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
              }

              .validated {
                color: var(--Semantic-Colors-Success);
              }

              .verify {
                color: var(--Semantic-Colors-Warning-Yellow);
              }

              .refused,
              .not_uploaded {
                color: var(--Semantic-Colors-Error);
              }

              img {
                margin-right: 5px;
              }
            }

            img {
              margin-right: 5px;
            }
          }
        }

        .anfitrion-container {
          padding: 0;
          border-radius: 4px;
          margin: 20px 0;

          .profile-info {
            display: flex;
            flex-flow: wrap;

            .profile-generic-info {
              width: 302px;
              margin: 0 0 24px;

              label {
                color: var(--Utility-Colors-Primary-Text);
                font-family: "Montserrat";
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
              }

              .input-text-large {
                margin-top: 16px;
                border-bottom: 1px solid #bdbdbd;

                p {
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: var(--Utility-Colors-Secondary-Text);
                  margin: 0 0 6px 14px;
                }
              }

              .verifying {
                display: flex;
                justify-content: space-between;

                span {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 20px;
                }

                .validated {
                  color: var(--Semantic-Colors-Success);
                }

                .verify {
                  color: var(--Semantic-Colors-Warning-Yellow);
                }

                .refused,
                .not_uploaded {
                  color: var(--Semantic-Colors-Error);
                }

                img {
                  margin-right: 5px;
                }
              }

              .document-generic-input {
                margin-bottom: 40px;
                padding: 0;

                p {
                  font-family: "Montserrat";
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #373a3c;
                }

                .dropzone-ui {
                  width: 302px;
                  height: 174px;
                  min-height: initial;
                  background: #ecf7f8;
                  border: 1px dashed #8f9cb2;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  flex-flow: column;
                  align-items: center;

                  .dz-ui-label {
                    font-family: "Monserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #596178;
                  }

                  .dz-ui-footer {
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 12px;
                    color: #373a3c;
                  }
                }
              }
            }
          }

          .profile-info-form {
            display: flex;
            flex-flow: wrap;

            .profile-generic-info {
              width: 311px;
              margin: 0 0 24px;

              label {
                color: var(--Utility-Colors-Primary-Text);
                font-family: "Montserrat";
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
              }

              .change-links {
                font-weight: 500;
                text-decoration-line: underline;
                color: #2f80ed;
                font-family: "Montserrat";
                font-size: 12px;
                line-height: 24px;
              }

              .dropdown {
                width: 100%;
                margin-top: 16px;
                border-radius: 40px;
                margin-top: 0;
                border: 1px solid #bdbdbd;
                color: var(--Utility-Colors-Primary-Text);

                svg {
                  filter: var(--Custom-Colors-Svg);
                }
              }

              .input-text-large {
                margin-top: 16px;

                input {
                  width: 100%;
                  height: 38px;
                  background: var(--Utility-Colors-Secondary-Background);
                  border: 1px solid #bdbdbd;
                  box-sizing: border-box;
                  border-radius: 40px;
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;
                  padding: 7px 0 7px 16px;
                  color: var(--Utility-Colors-Primary-Text);
                }

                input:active,
                input:hover,
                input:focus {
                  color: var(--Utility-Colors-Primary-Text);
                  border: 1px solid #828282;
                  box-shadow: 0px 4px 18px rgba(209, 217, 230, 0.8);
                  outline: none !important;
                }
              }

              .verifying {
                display: flex;
                justify-content: space-between;

                span {
                  font-weight: 600;
                }

                .validated {
                  color: var(--Semantic-Colors-Success);
                }

                .verify {
                  color: var(--Semantic-Colors-Warning-Yellow);
                }

                .refused,
                .not_uploaded {
                  color: var(--Semantic-Colors-Error);
                }

                a {
                  font-weight: 500;
                  text-decoration-line: underline;
                  color: #2f80ed;
                }

                a,
                span {
                  font-family: "Montserrat";
                  font-size: 12px;
                  line-height: 24px;
                }

                img {
                  margin-right: 5px;
                }
              }

              .document-generic-input {
                margin-bottom: 40px;
                padding: 0;

                p {
                  font-family: "Montserrat";
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #373a3c;
                }

                .dropzone-ui {
                  width: 302px;
                  height: 231px;
                  min-height: initial;
                  background: #ecf7f8;
                  border: 1px dashed #8f9cb2;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  flex-flow: column;
                  align-items: center;

                  .dz-ui-label {
                    font-family: "Monserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 19px;
                    line-height: 17px;
                    color: #596178;
                  }

                  .dz-ui-footer {
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 12px;
                    color: #373a3c;
                  }
                }

                .verifying {
                  display: flex;
                  justify-content: space-between;

                  .validated {
                    color: var(--Semantic-Colors-Success);
                  }

                  .verify {
                    color: var(--Semantic-Colors-Warning-Yellow);
                  }

                  .refused,
                  .not_uploaded {
                    color: var(--Semantic-Colors-Error);
                  }

                  span,
                  p {
                    font-size: 12px;
                    line-height: 24px;
                    font-weight: 600;
                    font-family: "Montserrat";
                    display: flex;
                  }

                  img {
                    margin-right: 5px;
                  }
                }
              }
            }
          }

          .btn-container__edit-host {
            .btn-edit {
              display: flex;
              align-items: center;
              justify-content: space-around;
              width: 187px;
              height: 38px;
              margin: 0 auto;
              background: var(--Brand-Colors-Primary-Color);
              border-radius: 40px;

              p {
                font-family: "Montserrat";
                font-weight: bold;
                font-size: 14px;
                line-height: 19px;
                color: #ffffff;
                margin: 0;
              }

              img {
                width: 16px;
              }
            }

            .btn-save {
              display: flex;
              align-items: center;
              justify-content: space-around;
              width: 187px;
              height: 38px;
              margin: 0 auto;
              background: #219653;
              border-radius: 40px;

              p {
                font-family: "Montserrat";
                font-weight: bold;
                font-size: 14px;
                line-height: 19px;
                color: #ffffff;
                margin: 0;
              }

              img {
                width: 16px;
              }
            }
          }
        }
      }
    }

    .invite-friends {
      display: block;
      margin-bottom: 50px;

      .card {
        width: 328px;
        height: 455px;
        background: var(--Utility-Colors-Secondary-Background);
        box-shadow: 2px 8px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 20px;

        .card-body {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;

          h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 46px;
            color: var(--Utility-Colors-Primary-Text);
            margin-top: 0;
          }

          p {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: var(--Utility-Colors-Secondary-Text);
            width: 231px;
          }

          h3,
          p {
            font-family: "Montserrat";
            text-align: center;
          }

          .inv-img {
            width: 255px;
            height: 169px;
          }

          .btn-copy {
            width: 160px;
            height: 38px;
            margin: 28px 0;
            background: var(--Brand-Colors-Primary-Color);
            border-radius: 40px;
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
          }

          .social-media {
            display: flex;
            justify-content: space-between;
            width: 214px;

            .fb-btn {
              width: 15px;
            }

            img {
              width: 25px;
              height: 25px;
            }

            .linkedin {
              height: auto;
              width: 28px;
            }
          }
        }
      }
    }

    .anfitrion-call-to-action {
      .share-car {
        display: flex;
        width: 328px;
        height: 455px;
        flex-flow: column;
        align-items: center;
        margin-bottom: 50px;
        text-align: center;
        background: var(--Utility-Colors-Secondary-Background);
        box-shadow: 2px 8px 4px 0px rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        border-radius: 20px;

        h2 {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: var(--Utility-Colors-Primary-Text);
          margin: 43px 0 8px;
        }

        span {
          font-size: 18px;
          line-height: 30px;
          color: var(--Utility-Colors-Secondary-Text);
          margin-bottom: 42px;
        }

        h2,
        span {
          font-family: "Montserrat";
        }

        img {
          width: 208px;
          height: 180px;
          margin-bottom: 42px;
        }

        .btn-convert {
          width: 241px;
          height: 38px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: var(--Brand-Colors-White-color-fix);
        }
      }

      .anfitrion-comercial {
        width: 328px;
        height: 455px;
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;
        margin-bottom: 50px;
        box-sizing: border-box;
        background: var(--Utility-Colors-Secondary-Background);
        box-shadow: 2px 8px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 20px;

        h2 {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: var(--Utility-Colors-Primary-Text);
          width: 270px;
          margin: 40px 0 8px;
        }

        span {
          font-size: 18px;
          line-height: 24px;
          color: var(--Utility-Colors-Secondary-Text);
          width: 267px;
          margin-bottom: 42px;
        }

        h2,
        span {
          font-family: "Montserrat";
        }

        img {
          width: 238.62px;
          height: 101.57px;
          margin-bottom: 64px;
        }

        .btn-convert {
          width: 286px;
          height: 38px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: var(--Brand-Colors-White-color-fix);
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .profile-boxes {
    .dropdown-divider {
      margin: 60px 0;
      width: 100%;
    }

    .boxes-container {
      flex-flow: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 95%;
      max-width: 1108px;
      margin: 0 auto;

      .profile-update {
        width: 100%;
        margin-bottom: 29px;

        h2 {
          font-size: 22px;
          line-height: 24px;
          width: 100%;
          text-align: start;
        }
      }

      .btn-change {
        margin: 0;
      }

      .pay-method,
      .anfitrion-info {
        width: 473px;

        .pay-and-change-box {
          flex-direction: row;
          width: unset;
          .pay-and-change {
            .pay-card {
              .card-num {
                display: inline;
              }
            }
          }
        }
      }

      .anfitrion-comercial-info {
        width: 100%;
        .contact-us {
          font-size: 20px;
        }

        .card {
          .title-status-container {
            display: flex;
            align-items: center;
            .title {
              width: 350px;
              font-size: 22px;
              line-height: 24px;
              margin: 0;
              order: 1;
            }
            .verifying-comercial-host {
              order: 2;
              margin: 0;
              width: 400px;
            }
          }

          .dropdown-divider {
            margin: 17px 0;
          }
          .anfitrion-container {
            padding: 7px 0px 0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .btn-container__edit-host {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              .btn-edit,
              .btn-save {
                margin: 0 0 24px;
              }
            }

            .profile-info,
            .profile-info-form {
              order: 3;
              gap: 15px;

              .profile-generic-info {
                width: 355px;
              }

              .name,
              .social-reason,
              .rfc {
                width: 32%;
              }

              .fiscal-location,
              .own-insurance {
                width: 49%;
              }
              .profile-image {
                width: 100%;

                .document-generic-input {
                  margin-bottom: 0px;

                  .dropzone-ui {
                    width: 100%;
                  }
                }
              }
            }

            .verifying-comercial-host {
              margin-top: 0;
            }
          }
        }
      }

      .invite-friends {
        display: none;
      }

      .anfitrion-call-to-action {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .share-car {
          width: 474px;
          height: 471px;
          margin-bottom: 85px;
        }

        .anfitrion-comercial {
          width: 474px;
          height: 471px;
          margin-bottom: 85px;
        }
      }
    }
  }
}
