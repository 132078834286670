.list-group-item-trips-client {
  display: flex;
  justify-content: flex-start;
  border: 0px;
  flex-direction: column;
  border-radius: 8px;
  background: var(--Colors-Utility-colors-Secondary-background, #fff);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  width: 327px;
  padding: 0;
  margin: 12px 0;
  position: relative;

  p {
    margin: 0;
  }

  .car-and-user-details {
    .reservation-text {
      max-width: 280px;
      white-space: nowrap;

      h2 {
        font-weight: bold;
        margin-top: 11px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
      }

      .reservation-text-icon {
        width: 16px;
        height: 16px;
        margin: 0 5px;
      }

      p {
        color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      h2,
      p {
        font-family: "Montserrat";
      }
    }
  }

  .trip-container {
    padding: 24px 16px 35px;
    border-radius: 8px 8px 0 0;

    .trip-data {
      .reservation-details {
        margin-top: 24px;

        h2 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--Utility-Colors-Primary-Text, #000);
        }

        .dates {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          margin-bottom: 9px;
        }

        .direction {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .reservation-details-total {
        margin-top: 24px;

        h2 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #3cb2b9;
        }

        .total {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
  }

  .car-image {
    width: 100%;
    height: 180px;
    background-color: #f9f9f9;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }

    .empty-image {
      width: 112px;
      object-fit: contain;
    }
  }
}

@media only screen and (min-width: 991px) {
  .list-group-item-trips-client {
    width: 527px;
  }
}

@media only screen and (min-width: 1170px) {
  .list-group-item-trips-client {
    flex-direction: row;
    width: 98%;
    height: 145px;
    margin: 0 0 32px;

    .trip-container {
      display: flex;
      justify-content: space-between;
      padding: 24px 16px 15px 30px;
      height: 100%;
      width: calc(100% - 300px);

      .car-and-user-details {
        width: 33%;

        .reservation-text {
          max-width: 320px;
          white-space: nowrap;

          h2 {
            font-weight: bold;
            margin-top: 11px;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 800;
            font-size: 16px;
            line-height: 22px;
            margin: 0;
            padding: 0px;
          }

          p {
            font-size: 14px;
          }

          h2,
          p {
            font-family: "Montserrat";
          }
        }
      }

      .trip-data {
        display: flex;
        padding-left: 12px;
        gap: 20px;

        .reservation-details {
          margin-top: 0px;
          width: 200px;
          overflow: hidden;

          h2 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #3cb2b9;
          }

          .dates {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            margin-bottom: 9px;
          }

          .direction {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
          }
        }

        .reservation-details-total {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin-top: 0;

          h2 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #3cb2b9;
          }
        }
      }
    }

    .car-image {
      width: 260px;
      height: 100%;

      img {
        border-radius: 8px 0 0 8px;
      }
    }
  }
}
