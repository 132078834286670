.cards-modal {
  .modal-content {
    max-width: 512px;
    min-width: 360px;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;
    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }
    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;
      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 50%;
        padding: 0 0 2px;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
        }
      }
      h6 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 207px;
        margin: 0 auto;
        line-height: 30px;
        padding-bottom: 8px;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      margin: 24px 12px;
      .card-item {
        width: 100%;
        display: flex;
        margin: 6px 0;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        padding: 0;
        border: 1px solid rgba(163, 172, 185, 0.2);
        height: 120px;

        .card-data-container {
          display: flex;
          flex-direction: column;
          text-align: left;
          padding: 16px 0 0 20px;
          .card-data {
            display: flex;
            .img-brand {
              width: 35px;
              margin-right: 12px;
            }
          }
          .card-num {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            padding-right: 8px;
          }
          .card-exp {
            font-size: 14px;
            margin: 0;
            color: rgba(105, 115, 134, 0.5);
            padding: 6px 0 4px 0;
          }
          .card-plans-alert {
            color: #3cb2b9;
            font-size: 14px;
            margin: 0;
            padding: 0;
            height: 16px;
            font-weight: bolder;
          }
        }
        .btn-select {
          font-weight: bolder;
          background-color: var(--Brand-Colors-Primary-Color);
          font-size: 12px;
          width: 100px;
          height: 38px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          color: #fff;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          line-height: 21px;
          margin: 0 11px 0 0;
        }
      }
      .modal-card-input {
        width: 100%;
        padding: 24px;
      }
    }
    .modal-body,
    .modal-footer {
      padding-top: 0;
    }
    .modal-header,
    .modal-footer {
      border: 0px;
    }
    .modal-footer,
    .modal-body {
      text-align: center;
    }
    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 44px;
      .btn-blue {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 38px;
        color: #fff;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        margin: 0 11px 0 0;
        background: #3cb2b9;
        border-radius: 40px;
      }
    }
  }
}
