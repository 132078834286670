.loading-container {
  width: 100%;
  display: grid;
  place-content: center;

  .progress {
    color: var(--Brand-Colors-Primary-Color);
    background-color: inherit;
    svg {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
