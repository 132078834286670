.modal-map {
  .modal-dialog {
    width: 100%;
    align-items: flex-start;
    margin: 15px 0 0 !important;
    max-width: 100%;
    .modal-content {
      position: relative;
      border-radius: 0;
      width: 100%;
      height: 100vh;
      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 32px;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.08);
        button {
          margin: 0;
          padding: 0;
        }
        h2 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: #333333;
          margin: 0;
        }
        span {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #bdbdbd;
        }
        .close {
          opacity: 1;
          width: 24px;
          height: 24px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 50%;
          padding: 0 0 2px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 24px;
          }
        }
      }
      .modal-header:after,
      .modal-header::before {
        content: none;
      }
      .modal-alert {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 22px 0 14px;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.08);
        h3 {
          font-family: "Montserrat";
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #13283a;
          margin: 0;
        }
      }
      .modal-body {
        padding: 0;
        .map3 {
          width: 100%;
          height: 608px;
        }
      }
      .modal-footer {
        display: flex;
        justify-content: space-around;
        border-top: 0px;
        height: 80px;
        .btn-cancel {
          color: #828282;
          background: transparent;
        }
        .btn-orange {
          width: 152px;
          height: 38px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          color: #ffffff;
        }
        .btn-orange,
        .btn-cancel {
          outline: none !important;
          font-size: 14px;
          line-height: 19px;
          font-weight: bold;
          font-family: "Montserrat";
        }
      }
    }
  }
}

@media only screen and (min-width: 380px) {
  .modal-map {
    .modal-dialog {
      margin: 0 auto !important;
    }
  }
}
@media only screen and (min-width: 992px) {
  .modal-map {
    .modal-dialog {
      max-width: 916px;
      justify-content: center;
      align-items: center;
      .modal-content {
        margin-top: 100px;
        width: 916px;
        height: 537px;
        box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
        border-radius: 20px;
        .modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-shadow: 0px 0px 0px 0px;
          padding-left: 40px;
          border-bottom: 0px;
          height: 80px;
          h2 {
            align-self: flex-end;
            font-size: 20px;
            line-height: 30px;
          }
          .arrow-left {
            display: none;
          }
          .close {
            align-self: flex-start;
          }
        }
        .modal-header:after,
        .modal-header::before {
          content: none;
        }
        .modal-alert {
          justify-content: flex-start;
          padding: 8px 40px 24px;
          box-shadow: 0px 0px 0px 0px;
          h3 {
            font-size: 16px;
          }
        }
        .modal-body {
          padding: 0 40px;
          .map3 {
            width: 100%;
            height: 292px;
          }
        }
        .modal-footer {
          display: flex;
          justify-content: flex-end;
          border-top: 0px;
          height: 80px;
          .btn-cancel {
            height: 38px;
            margin-right: 40px;
            background: #3cb2b9;
            border-radius: 40px;
          }
          .btn-orange,
          .btn-cancel {
            color: #ffffff;
            width: 160px;
          }
        }
      }
    }
  }
}
