.client-index-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fafafa;
  min-height: calc(100vh - 64px);

  .client-index__join-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 21px 16px 32px 16px;

    .client-index__title-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .client-index__route {
        display: flex;
        gap: 8px;
        align-items: center;

        .client-index__route__arrow {
          filter: invert(94%) sepia(7%) saturate(226%) hue-rotate(179deg)
            brightness(92%) contrast(88%);
          width: 10px;
          height: 10px;
        }

        .client-index__route__first,
        .client-index__route__second {
          color: #475467;
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .client-index__title {
        font-family: "Montserrat";
        color: #151516;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
      }

      .client-index__subtitle {
        color: #4a4a57;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .client-index__search__container {
      display: flex;
      margin-top: 20px;
      gap: 6px;

      .client-index__search {
        max-width: 345px;
        width: calc(100% - 40px);
        display: flex;
        border-radius: 12px;
        border: 1px solid #d1d1d1;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .client-index__search__icon {
          color: #6298d5;
        }

        .client-index__search__input {
          width: calc(100% - 40px);
        }
      }

      .client-index__search__filter {
        margin-right: 6px;
      }

      .client-index__search__filter,
      .client-index__search__add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 12px;
        border: 1px solid #d1d1d1;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        svg {
          color: #4a4a57;
        }
      }
    }
  }

  .client-index-container__table {
    max-width: 1254px;
    width: 100%;
    height: 530px;
    padding: 0px 20px;
  }

  .clients-empty-list {
    width: 100%;
    padding: 0 16px;
    display: flex;
    justify-content: center;

    .clients-empty-list__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: var(--radius-xl, 12px);
      border: 1px solid var(--Colors-Custom-colors-Disabled-bg, #e9e9e9);
      background: var(--Colors-Utility-colors-Secondary-background, #fff);
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
      padding: 70px 40px 48px;
      min-width: 343px;
      max-width: 1254px;
      width: 100%;

      img {
        width: 112.394px;
        height: 44.24px;
      }

      .clients-empty-list__information {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        margin: 54px 0 32px;
        text-align: center;

        .clients-empty-list__title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        .clients-empty-list__subtitle {
          font-size: 14px;
          font-weight: 400;
          width: 386px;
        }

        .clients-empty-list__title,
        .clients-empty-list__subtitle {
          color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
          font-family: "Montserrat";
          font-style: normal;
        }
      }

      .btn-add {
        display: flex;
        align-items: center;
        gap: 6px;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
        background: var(--Colors-Brand-colors-Primary-color, #b72446);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 230px;
        height: 40px;

        p {
          color: var(--Colors-Brand-colors-White-color-fix, #fff);
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0;
        }

        img {
          width: 28px;
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .client-index-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: 65px;

    .client-index__join-container {
      max-width: 1254px;
      width: 100%;
      margin: 0;
      padding: 32px 0 24px;

      .title-container {
        text-align: left;

        h1 {
          margin: 0;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 42px;
          line-height: 46px;
          color: #195154;
          padding: 44px 0 0 0;
        }
      }

      .btn-container {
        display: flex;
        align-items: flex-end;

        .btn-add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 180px;
          height: 30px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;

          p {
            margin: 0;
            padding: 0 6px 0 4px;
          }
        }
      }

      .client-index__search__container {
        display: flex;
        justify-content: space-between;

        .client-index__search {
          width: 320px;
        }

        .btn-add {
          display: flex;
          align-items: center;
          gap: 6px;
          border-radius: var(--radius-xl, 12px);
          border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
          background: var(--Colors-Brand-colors-Primary-color, #b72446);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          width: 230px;
          height: 40px;

          p {
            color: var(--Colors-Brand-colors-White-color-fix, #fff);
            font-family: "Montserrat";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
          }

          img {
            width: 28px;
          }
        }
      }
    }

    .client-index-container__table {
      padding: 0;
    }

    .clients-empty-list {
      .clients-empty-list__container {
        padding: 113px 40px 78px;
      }
    }
  }
}
