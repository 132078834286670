.car-options-modal {
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;

  .car-options-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 20px;
    text-align: center;
    position: relative;
    max-width: 360px;
    padding: 30px 40px;

    .btn-close {
      position: absolute;
      right: 20px;
      top: 20px;
      img {
        position: absolute;
      }
    }

    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: #195154;
    }

    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 20px 0;

      span {
        font-weight: 700;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: space-around;
    padding-top: 24px;
    flex-direction: column;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 280px;
      height: 52px;
      background: var(--Brand-Colors-Primary-Color);
      border-radius: 40px;
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    .outlined {
      background-color: inherit;
      color: var(--Brand-Colors-Primary-Color);
    }
  }

  .empty {
    max-height: 260px;
  }
}

.empty {
  max-height: 100px;
}

@media only screen and (min-width: 1130px) {
  .car-options-modal {
    .car-options-container {
      max-width: 800px;
      padding: 40px 80px;
    }

    .btns {
      flex-direction: row;

      .outlined {
        margin-top: 6px;
      }
    }
    .empty {
      max-height: 220px;
    }
  }
}
