.email-verification-modal {
  display: flex !important;
  align-items: center;
  .modal-content {
    background-color: var(--Utility-Colors-Primary-Background);
    width: 312px;
    height: 490px;
    margin: 0 auto;
    box-shadow: 2px 8px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px 5px;

    .modal-header,
    .modal-body {
      padding: 10px 15px 0;
    }

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: transparent;
        padding: 0 0 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        margin: 0 0 0 auto;

        img {
          filter: var(--Custom-Colors-Svg);
        }
      }

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        width: 207px;
        margin: 0 auto 4px;
        color: var(--Utility-Colors-Primary-Text);
        line-height: 30px;
      }

      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--Utility-Colors-Secondary-Text);
        margin: 20px 0 24px;

        b {
          font-weight: 600;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 0 12px;

      h3 {
        font-family: "Montserrat";
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 8px 0 26px;
        width: 283px;
      }

      .email-verification-content {
        width: 100%;

        .form {
          display: flex;
          justify-content: flex-start;
          flex-flow: column;

          p {
            text-align: left;
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--Utility-Colors-Primary-Text);
            margin-bottom: 16px;
            padding: 0 0 0 2px;
          }

          .email-code-error {
            font-size: 13px;
            line-height: 24px;
            margin-bottom: 0;
            font-weight: 500;
            font-family: "Montserrat";
            color: red;
            font-style: italic;
          }

          .dropdown-item {
            background-color: transparent;
          }

          .bill-input,
          .street-input {
            text-align: center;
            width: 100%;
            background: var(--Utility-Colors-Secondary-Background);
            padding: 8px 16px 9px;
            border: 1px solid #828282;
            border-radius: 40px;
            color: var(--Utility-Colors-Primary-Text);
          }

          .address-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .no-input {
              width: 48%;
              background: #ffffff;
              padding: 8px 16px 9px;
              border: 1px solid #828282;
              border-radius: 40px;
              margin-bottom: 32px;
            }
          }

          input::placeholder {
            font-size: 14px;
            line-height: 24px;
            color: var(--Utility-Colors-Secondary-Text);
          }

          input:focus,
          .input-group:focus-within {
            border-color: #828282;
            box-shadow: 0px 4px 18px rgba(209, 217, 230, 0.8);
          }

          input:active,
          input:hover,
          input:focus {
            outline: none !important;
          }
        }

        .resend-email {
          text-align: center;
          margin: 8px 0 10px;

          p {
            color: var(--Utility-Colors-Secondary-Text);
          }

          p,
          a {
            font-family: "Montserrat";
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 2px;
          }

          a {
            text-decoration: underline;
            color: #2f80ed;
          }
        }
      }
    }

    .modal-body,
    .modal-footer {
      padding-top: 0;
    }

    .modal-header,
    .modal-footer {
      border: 0px;
    }

    .modal-footer,
    .modal-body {
      text-align: center;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 52px;

      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
        border-radius: 40px;
        margin: auto;
        width: 272px;
        height: 38px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .email-verification-modal {
    .modal-content {
      height: 405px;
      width: 535px;

      .modal-header {
        h2 {
          font-size: 20px;
          line-height: 24px;
          width: 373px;
        }
      }

      .modal-body {
        h3 {
          width: 445px;
          margin: 0px 0 32px;
        }

        .email-verification-content {
          width: 86%;

          .form {
            .address-container {
              .bill-input {
                width: 48%;
                background: #ffffff;
                padding: 8px 16px 9px;
                border: 1px solid #828282;
                border-radius: 40px;
                margin-bottom: 32px;
              }
            }
          }
        }
      }

      .modal-footer {
        width: 445px;
        margin: 0 auto;
        padding-bottom: 40px;

        h3 {
          margin: 10px 0 20px;
        }

        .btn-orange {
          width: 445px;
        }
      }
    }
  }
}
