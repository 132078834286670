.trip-client-card {
  display: flex;
  border-radius: 16px;
  max-width: 540px;

  .client-info {
    display: flex;
    flex-direction: column;
    padding: 14px 0;
    justify-content: flex-start;
    overflow: hidden;

    .host-indicator-container {
      .name,
      .more,
      .hostC-indicator {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
      }

      .hostC-indicator {
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 12px;
      }
    }

    .name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 5px;
      font-size: 17px;
    }

    .more {
      margin-bottom: 0;
      font-size: 12px;
    }
  }

  .client-img {
    width: 56px;
    border-radius: 40px;
    object-fit: cover;
    height: 56px;
    margin: 10px 8px 0 0;
  }
}

@media only screen and (min-width: 1130px) {
  .trip-client-card {
    padding-left: 0;
  }
}
