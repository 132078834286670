.insurance-container-banner {
  .insurance-summary {
    .card {
      background: var(--Utility-Colors-Secondary-Background);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 27px;
      padding: 16px 24px 16px 24px;
      border: none;

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 35px 0 58px;
      }

      .card-body {
        display: flex;
        flex-wrap: wrap;
        background-color: transparent;
        padding: 0px;

        .title {
          font-family: "Montserrat";
          font-weight: 700;
          font-size: 20px;
          line-height: 24.38px;
          text-align: center;
          color: var(--Utility-Colors-Primary-Text);
          margin-bottom: 24px;
        }

        .main-information {
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 24px;
        }

        .main-information,
        .extra-information {
          font-family: "Montserrat";
          font-style: normal;
          color: var(--Utility-Colors-Secondary-Text);
        }

        .extra-information {
          font-weight: 400;
          font-size: 12px;
          margin-top: 16px;
          line-height: 24px;
        }

        .list-right,
        .list-left {
          margin-left: 16px;;

          .point {
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--Utility-Colors-Secondary-Text);
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            img {
              margin-right: 8px;
            }
          }
        }

        .know-more {
          display: flex;
          gap: 10px;
          margin-top: 16px;
          margin-bottom: 12px;
          width: 100%;

          a {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            text-decoration-line: underline;
            color: var(--Semantic-Colors-Link);
            text-underline-offset: 3px;
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }

    .card-h-i {
      padding: 16px 24px 24px 24px;
      height: 420px;

      .image-container {
        margin: 16px 0 40px;

        img {
          height: 72px;
        }
      }

      .card-body {
        flex-wrap: nowrap;
        flex-flow: column;
        align-items: flex-start;

        .know-more {
          display: none;
        }
      }

      .know-more-o {
        display: flex;
        gap: 10px;
        margin: 0;
        width: 100%;

        a {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          text-decoration-line: underline;
          color: var(--Semantic-Colors-Link);
          text-underline-offset: 3px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1108px) {
  .insurance-container-banner {
    padding-top: 59px;

    .insurance-summary {
      flex-direction: column;

      .card {
        display: flex;
        flex-direction: row;
        margin-bottom: 0;
        padding: 24px 64px 24px 32px;
        gap: 48px;

        .image-container {
          margin: 0;
        }

        .card-body {
          .main-information {
            margin-bottom: 1rem;
          }

          .list-right,
          .list-left {
            width: 50%;
            margin-left: 0;

            .point {
              margin-bottom: 6px;
            }
          }
        }
      }

      .card-h-i {
        padding: 32px 64px 32px 32px;
        height: 230px;
  
        .image-container {
          margin: 0 33px;
  
          img {
            height: 96px;
          }
        }
  
        .card-body {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .know-more {
            display: initial;
            margin: 0;
          }
        }

        .know-more-o {
          display: none;
        }
      }
    }
  }
}
