.code-request-modal {
  .modal-content {
    width: 345px;
    margin: 100px auto;
    box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
    border-radius: 16px;
    background: var(--Utility-Colors-Primary-Background);
    padding: 20px 16px 20px 16px;

    .modal-header {
      display: flex;
      flex-flow: column;
      text-align: center;
      text-align: left;
      padding: 0;
      gap: 8px;

      .close {
        opacity: 1;
        width: 24px;
        height: 24px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        align-self: end;

        img {
          filter: var(--Custom-Colors-Svg);
        }
      }

      h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: var(--Utility-Colors-Primary-Text);
        width: 100%;
        margin: 0;
      }
    }

    .modal-body {
      display: flex;
      align-items: flex-start;
      padding: 0;
      gap: 24px;
      flex-direction: column;
      margin: 16px 0 24px 0;

      h3 {
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: justify;
        color: var(--Utility-Colors-Primary-Text);
        padding: 0;
        margin: 0;
      }

      a {
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: justify;
        color: var(--Semantic-Colors-Link);
        text-underline-offset: 3px;
      }
    }

    .modal-header,
    .modal-footer {
      border: none;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      .btn-orange {
        border-radius: 40px;
        margin: auto;
        width: 100%;
        height: 47px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        background: var(--Brand-Colors-Primary-Color);
        color: var(--Brand-Colors-White-color-fix);
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .code-request-modal {
    .modal-content {
      width: 520px;
      padding: 20px 24px 20px 24px;

      .modal-footer {
        .btn-orange {
          width: 310px;
        }
      }
    }
  }
}
