.add-car-completed {
  min-height: 60vh;
  width: 100%;
  position: relative;
  font-family: "Montserrat";

  .top-control {
    text-decoration: none;
    color: inherit;
    width: 100%;
    padding-top: 16px;
    position: relative;
    left: 5%;
    img {
      width: 10px;
    }
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      padding-left: 12px;
    }
  }

  .main {
    padding-top: 44px;
    font-family: "Montserrat";
    img {
      width: 312px;
    }
    h4 {
      font-family: inherit;
      font-weight: 700;
      font-size: 24px;
      line-height: 46px;
      color: #195154;
      padding: 12px;
      margin-top: 16px;
    }
    p {
      font-family: inherit;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      max-width: 74ch;
      text-align: center;
      color: #828282;
      padding: 12px;
    }

    .btn-container {
      width: 100%;
      margin: 44px 0 90px 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .btn-preview,
      button {
        border: none;
        margin: 0;
        font-family: inherit;
        font-size: 14px;
        line-height: 21px;
        border-radius: 40px;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 311px;
        height: 52px;
        border-radius: 40px;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      .btn-orange {
        background: var(--Brand-Colors-Primary-Color);
      }
      .btn-aqua {
        background: #3cb2b9;
        margin: 0 0 14px 0;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .add-car-completed {
    .top-control {
      left: 10%;
    }
    .main {
      h4 {
        font-size: 38px;
        margin: 48px 0 32px 0;
      }
      .btn-container {
        flex-direction: row;
        justify-content: center;
        .btn-orange {
          margin: 0 0 0 12px;
        }
        .btn-aqua {
          margin: 0 12px 0 0;
        }
      }
    }
  }
}
