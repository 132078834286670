.send-email-password {
  width: 100%;
  position: relative;
  font-family: "Montserrat";
  background: var(--Utility-Colors-Primary-Background);

  .main {
    padding: 44px 0;
    font-family: "Montserrat";

    .send-email-image {
      width: 312px;
    }

    .title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: var(--Utility-Colors-Primary-Text);
      padding: 12px 0 0;
      margin: 40px 0 16px;
      text-align: center;
    }

    .subtitle {
      width: 90%;
      max-width: 730px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: var(--Utility-Colors-Primary-Text);
      padding-bottom: 48px;
      margin: 0;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;

      .button-container {
        .btn-send {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: var(--Brand-Colors-White-color-fix);
          width: 225.52px;
          height: 52px;
          background: var(--Brand-Colors-Primary-Color);
          border-radius: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .send-email-password {
    .main {
      padding: 90px 0 144px 0;
      .title {
        font-size: 42px;
        font-weight: 600;
        margin: 16px 0 24px;
      }

      .subtitle {
        font-weight: 500;
      }
    }
  }
}
