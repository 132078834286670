.edit-car-container {
  overflow: hidden;

  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded,
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type,
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .accordion-summary {
    padding-left: 0;
  }

  .back-menu {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: 0px 5.77255px 18.0392px rgba(209, 217, 230, 0.8);

    .nav-join {
      background: #ecf7f8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 19px 25px 21px;

      h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        color: #195154;
      }

      img {
        width: 15px;
      }
    }

    .MuiCollapse-vertical {
      width: 100%;
      .nav-filter {
        width: 100%;
        background: #ecf7f8;

        .un-form {
          width: 100%;
          border: 0px;
          background-color: transparent;

          .card-body {
            width: 100%;

            .option-container {
              .option {
                a {
                  font-family: "Montserrat";
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 20px;
                  color: #195154;
                }

                .drop_arrow {
                  width: 15px;
                }

                .close-container {
                  display: flex;
                  justify-content: space-between;
                }

                .dropdown-divider {
                  margin: 20px 0;
                }
              }
            }

            .btn-orange {
              display: flex;
              align-items: center;
              justify-content: center;
              background: var(--Brand-Colors-Primary-Color);
              border-radius: 40px;
              margin: 20px auto;
              width: 160px;
              height: 38px;
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .back-menu-des {
    display: none;
  }

  .return-prev-page {
    display: flex;
    width: 100%;
    max-width: 1109px;
    align-items: center;
    padding: 50px 0px 50px 32px;

    .prev-arrow {
      width: 7px;
    }

    p {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      margin: 0 0 0 15px;
      color: #828282;

      b {
        color: #195154;
      }
    }
  }

  .model-ubication {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 50px;

    .form-container {
      width: 333px;

      .info-container {
        margin-bottom: 40px;

        .title {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: #195154;
        }

        h2 {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #3cb2b9;
        }

        .title,
        h2 {
          font-family: "Montserrat";
        }

        p {
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #195154;
        }
      }

      .form {
        .file-container {
          box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%);
          .MuiAccordionSummary-root {
            border-bottom: 1px solid rgb(0 0 0 / 20%);
          }
          .edit-text-model {
            margin: 30px 0 24px;
            font-family: "Montserrat";
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            color: #000000;

            a {
              text-decoration: underline;
              color: #3cb2b9;
            }
          }

          .goals {
            .goal-container {
              label {
                font-family: "Montserrat";
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #373a3c;
              }

              .dropdown {
                width: 100%;
                background: #ffffff;
                border-radius: 40px;
                margin-bottom: 16px;

                span {
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                }

                .disabled {
                  background: #ecf7f8;
                }

                .bs-caret {
                  span {
                    margin: 0 15px 0 0;
                  }
                }

                .dropdown-menu {
                  top: 40px;
                }
              }
            }
          }

          .information {
            display: flex;
            flex-flow: column;

            .generic-input {
              margin-bottom: 24px;

              label {
                font-weight: 600;
                line-height: 17px;
                color: #333333;
                margin-bottom: 16px;
              }

              .generic-text {
                font-family: "Montserrat";
                font-weight: normal;
                color: #333333;
                padding: 0px 0px 6px 6px;
                border-bottom: 1px solid #3cb2b9;
              }

              .eco {
                display: flex;
                align-items: center;
                gap: 7px;
              }

              .input-bags {
                width: 100%;
                background: #ffffff;
                border-radius: 40px;
                margin-bottom: 16px;
                height: 50px;

                .MuiOutlinedInput-root {
                  font-family: "Montserrat";
                  font-size: 14px;
                  width: 100%;
                  height: 50px;
                  background: #ffffff;
                  box-sizing: border-box;
                  border-radius: 40px;
                  margin-bottom: 16px;
                  padding: 8px 9px;
                  outline: none !important;
                  font-weight: normal;
                  line-height: 24px;
                  color: #828282;
                }
              }

              .input-text-large {
                width: 100%;
                .MuiInputBase-formControl {
                  font-family: "Montserrat";
                  font-size: 14px;
                  width: 100%;
                  height: 40px;
                  background: #ffffff;
                  box-sizing: border-box;
                  border-radius: 40px;
                  margin-bottom: 16px;
                  padding: 8px 9px;
                  outline: none !important;
                  font-weight: normal;
                  line-height: 24px;
                  color: #828282;
                }
                .input-error {
                  border: 1px solid #dc3545;
                }
              }

              .dropdown {
                width: 100%;
                background: #ffffff;
                border-radius: 40px;
                margin-bottom: 16px;
                height: 50px;

                span {
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                }
              }

              .generic-text,
              label {
                font-size: 14px;
                font-family: "Montserrat";
              }
            }
          }

          .option-title {
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #195154;
          }

          .drop-row {
            width: 12px;
          }

          .drop-options {
            display: flex;
            flex-flow: column;

            .document-generic-input {
              width: 100%;
              margin-bottom: 40px;
              padding: 0;

              p {
                font-family: "Montserrat";
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #828282;
                margin: 32px 0 5px;
              }

              .extra-info-p {
                font-size: 14px;
                margin: 0 0 5px;
              }

              .direction-input {
                .btn-orange-des {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 38px;
                  border-radius: var(--radius-xl, 12px);
                  border: 1px solid
                    var(--Colors-Brand-colors-Alternate, #d1cfcf);
                  background: var(--Colors-Brand-colors-Primary-color, #b72446);
                  padding: 0;
                  margin-bottom: 30px;

                  p {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;
                    margin: 0 0 0 10px;
                  }
                }

                .input-text-large {
                  width: 100%;
                  background: #ffffff;
                  box-sizing: border-box;
                  border-radius: 40px;
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 16px;
                  height: 50px;
                  line-height: 24px;
                  color: #333333;
                  outline: none !important;
                  padding: 7px 0px 7px 16px;
                  margin-bottom: 15px;
                }
              }

              .dropzone-ui {
                width: 328px;
                height: 174px;
                background: #ecf7f8;
                border: 1px dashed #8f9cb2;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                flex-flow: column;
                align-items: center;

                .dz-ui-label {
                  font-family: "Monserrat";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 17px;
                  color: #596178;
                }

                .dz-ui-footer {
                  font-family: "Montserra";
                  font-weight: 500;
                  font-size: 12px;
                  color: #373a3c;
                }
                .file-item-name {
                  font-family: "Montserrat";
                  font-weight: 500;
                  font-size: 12px;
                  color: #373a3c;
                }
              }

              .verifying {
                display: flex;
                justify-content: space-between;

                span,
                p {
                  font-size: 12px;
                  line-height: 24px;
                  font-weight: 600;
                  margin-top: 5px;
                  font-family: "Montserrat";
                  display: flex;
                  white-space: nowrap;
                }

                img {
                  margin-right: 5px;
                }
                .alert-error {
                  font-size: 12px;
                  line-height: 24px;
                  font-family: "Montserrat";
                  color: #eb5757;
                  margin: 0;
                }
                .alert-wait {
                  font-size: 12px;
                  line-height: 24px;
                  font-family: "Montserrat";
                  color: #ffab03;
                  margin: 0;
                }
                .alert-verified {
                  font-size: 12px;
                  line-height: 24px;
                  font-family: "Montserrat";
                  color: green;
                  margin: 0;
                }
              }

              .map {
                height: 400px;
              }

              span {
                font-weight: 600;
                font-size: 14px;
                margin: 16px 0 8px;
              }

              h5 {
                font-weight: 500;
                font-size: 14px;
              }

              span,
              h5 {
                color: #828282;
                font-family: "Montserrat";
                line-height: 24px;
              }
            }

            .dropdown-divider {
              width: 100%;
            }
          }

          .serial-number {
            padding: 20px 0 20px;

            .serial-info {
              max-width: 1000px;

              p {
                margin-bottom: 5px;
              }
            }

            .serial-container-input {
              display: flex;
              flex-direction: column;
              margin: 15px 0 15px;

              .serial-label {
                color: #373a3c;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                margin-bottom: 8px;
              }

              .serial-number-input {
                width: 100%;
                max-width: 447px;
                border: 1px solid var(--Aqua-puntos-destacados, #3cb2b9);
                border-radius: 40px;

                .MuiInputBase-root {
                  height: 38px;

                  .MuiOutlinedInput-notchedOutline {
                    border: 0;
                  }
                }
              }

              .generic-text {
                font-family: "Montserrat";
                font-weight: normal;
                color: #333333;
                padding: 0px 0px 6px 6px;
                width: 100%;
                max-width: 440px;
                border-bottom: 1px solid #3cb2b9;
              }
            }
          }

          .gps-inputs {
            padding: 20px 0 20px;

            .gps-info {
              max-width: 920px;
              margin-bottom: 15px;

              p {
                margin-bottom: 5px;
              }
            }

            .generic-gps-dropdown {
              label {
                color: #373a3c;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
              }

              .gps-dropdown {
                width: 100%;
                max-width: 920px;
                background: #ffffff;
                border-radius: 40px;
                margin-bottom: 16px;

                .MuiSelect-select {
                  white-space: normal;
                }

                span {
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                }
              }

              .underline-text {
                margin-top: 16px;
                padding: 0 20px 10px;
                border-bottom: 1px solid #3cb2b9;
                width: fit-content;
              }
            }
          }
        }

        .deactivate-container {
          display: flex;
          flex-flow: column;

          .deactivate-button {
            display: flex;
            justify-content: space-between;
            margin-top: 60px;

            p {
              font-family: "Montserrat";
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #4f4f4f;
            }

            .switch {
              position: relative;
              display: inline-block;
              width: 40px;
              height: 18px;

              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #e0e0e0;
                -webkit-transition: 0.4s;
                transition: 0.4s;
              }

              .slider:before {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                left: -3px;
                bottom: -1px;
                background: #4f4f4f;
                -webkit-transition: 0.4s;
                transition: 0.4s;
              }

              input:checked + .slider {
                background: #3cb2b9;
              }

              input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
                background: #195154;
              }

              .slider.round {
                border-radius: 34px;
              }

              .slider.round:before {
                border-radius: 50%;
              }
            }

            .switch input {
              opacity: 0;
              width: 0;
              height: 0;
            }
          }

          a {
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            text-decoration-line: underline;
            color: #2f80ed;
          }
        }
      }
    }
  }

  .documentation {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 50px;

    .form-container {
      width: 333px;

      .info-container {
        margin-bottom: 40px;

        .title {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: #195154;
        }

        h2 {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #3cb2b9;
        }

        .title,
        h2 {
          font-family: "Montserrat";
        }
      }

      .form {
        .file-container {
          box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%);
          .MuiAccordionSummary-root {
            border-bottom: 1px solid rgb(0 0 0 / 20%);
          }
          .option-title {
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #195154;
            padding: 8px 0;
          }

          .drop-row {
            width: 12px;
          }

          .drop-options {
            display: flex;
            flex-flow: column;

            .car-images-des {
              display: none;
            }

            .car-images {
              display: flex;
              flex-wrap: wrap;
              width: 328px;

              .image-name-container {
                display: flex;
                flex-flow: column;
                width: 120px;
                justify-content: center;
                margin-right: 40px;
                text-align: center;

                p {
                  font-family: "Montserrat";
                  font-weight: 500;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  font-size: 12px;
                  line-height: 24px;
                  color: #373a3c;
                }

                .image-container {
                  width: 120px;
                  height: 76px;
                  margin-top: 24px;
                  position: relative;
                  background: #fff;
                  display: flex;
                  border: 1px solid #8f9cb2;
                  box-sizing: border-box;
                  justify-content: center;
                  align-items: center;
                  border-radius: 10px;

                  .image-in {
                    width: auto;
                    height: 50px;
                  }

                  .btn-options {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 6px;
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    padding: 0;
                    background: #ffffff;
                    box-shadow: 0px 2px 8px rgba(196, 209, 237, 0.51);

                    img {
                      width: 14px;
                    }
                  }
                }
              }
            }

            .document-generic-input {
              width: 100%;
              margin: 40px 0;
              padding: 0;

              .dropzone-ui {
                width: 328px;
                height: 174px;
                background: #ecf7f8;
                border: 1px dashed #8f9cb2;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                flex-flow: column;
                align-items: center;

                .dz-ui-label {
                  font-family: "Monserrat";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 17px;
                  color: #596178;
                }

                .dz-ui-footer {
                  font-family: "Montserra";
                  font-weight: 500;
                  font-size: 12px;
                  color: #373a3c;
                }
              }

              .verifying {
                display: flex;
                justify-content: space-between;

                span {
                  color: #ffab03;
                }

                p {
                  color: #eb5757;
                }

                span,
                p {
                  font-size: 12px;
                  line-height: 24px;
                  font-weight: 600;
                  margin-top: 5px;
                  font-family: "Montserrat";
                }

                img {
                  margin-right: 5px;
                }
              }

              .input-text-large {
                background: #ffffff;
                border: 1px solid #cccccc;
                box-sizing: border-box;
                border-radius: 40px;
                font-family: "Montserrat";
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #333333;
                outline: none !important;
                padding: 7px 0px 7px 16px;
                margin-bottom: 50px;
                width: 100%;
              }

              span {
                font-weight: 600;
                font-size: 14px;
                margin: 16px 0 8px;
              }

              h5 {
                font-weight: 500;
                font-size: 14px;
              }

              span,
              h5 {
                color: #828282;
                font-family: "Montserrat";
                line-height: 24px;
              }
            }

            .information {
              background: rgba(235, 87, 87, 0.22);
              border-radius: 20px;
              text-align: center;
              padding: 16px 8px;
              margin-bottom: 40px;

              li {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 19px;
                color: #333333;
                margin-bottom: 4px;
              }
            }

            .accesories-container {
              text-align: start;
              margin-top: 24px;

              .more-container {
                display: flex;
                align-items: center;
                margin: 14px 0;

                .more {
                  font-family: "Montserrat";
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 30px;
                  margin-bottom: 0;
                }

                img {
                  width: 12px;
                  margin-left: 5px;
                }
              }

              .only-accesories {
                .accesories-content {
                  display: flex;
                  flex-direction: column;
                  .option {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;

                    label {
                      font-family: "Montserra";
                      font-weight: normal;
                      font-size: 16px;
                      color: #333333;
                      margin: 0 0 0 3px;
                    }

                    img {
                      width: 20px;
                    }
                  }
                }
              }
            }

            .message-box {
              height: 130px;
              width: 329px;
              border: 1px solid #3cb2b9;
              box-sizing: border-box;
              border-radius: 10px;
              max-height: 255px;
              min-height: 130px;
              margin: 24px auto 8px;
              padding: 17px 15px;
              font-family: "Montserrat";
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              color: #333333;
            }

            .max-text {
              text-align: end;
              font-family: "Montserrat";
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: #195154;
              margin-bottom: 40px;
            }

            .dropdown-divider {
              width: 100%;
            }
          }

          #accesories {
            padding-left: 8px;
          }
        }
      }
    }
  }

  .price-disponibility {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 50px;

    .form-container {
      width: 333px;

      .info-container {
        margin-bottom: 40px;

        .title {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: #195154;
        }

        h2 {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #3cb2b9;
        }

        .title,
        h2 {
          font-family: "Montserrat";
        }
      }

      .form {
        .file-container {
          box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%);
          .MuiAccordionSummary-root {
            border-bottom: 1px solid rgb(0 0 0 / 20%);
          }
          .option-title {
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #195154;
            padding: 8px 0;
          }

          .drop-row {
            width: 12px;
          }

          .drop-options {
            display: flex;
            flex-flow: column;

            .price-text-container,
            .insurance-text-container {
              .percentage {
                width: 100%;
                margin-bottom: 5px;
                padding: 0;
                .chip-per-day {
                  margin-left: 5px;
                  font-family: Montserrat;
                  font-size: 14px;
                  font-style: normal;
                }
              }

              .comission-information {
                padding: 0;
                font-family: "Montserrat";
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                text-align: left;
                color: #373a3c;
              }
            }

            .day-discount {
              display: flex;
              flex-direction: column;
              margin: 16px 0 20px;
              gap: 24px;

              .price-d {
                width: 100%;
                display: flex;
                flex-flow: column;

                .label-price {
                  font-family: "Montserrat";
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: #373a3c;
                }

                .input-price {
                  width: 50%;
                  .MuiInputBase-root {
                    outline: none !important;
                    height: 50px;
                    text-align: center;
                    background: #ffffff;
                    box-sizing: border-box;
                    border-radius: var(--radius-xl, 12px);
                  }
                }

                .price-d__static-input {
                  padding: 10px 14px;
                  color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                  font-family: "Montserrat";
                  font-size: 16px;
                  width: 100%;
                  font-style: normal;
                  font-weight: 400;
                  padding-bottom: 10px;
                  border-bottom: 1px solid
                    var(--Colors-Brand-colors-Alternate, #d1cfcf);
                }
              }
            }
            .day-discount-insurance {
              display: flex;
              flex-direction: column;
              margin: 16px 0 20px;
              gap: 24px;

              .price-d-insurance {
                width: 100%;
                display: flex;
                flex-flow: column;

                .label-price {
                  font-family: "Montserrat";
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: #373a3c;
                }

                img {
                  width: 105.143px;
                }
              }
            }

            .insurance-inputs {
              display: flex;
              flex-direction: column;
              .charge-container,
              .deposit-amount-container,
              .insurance-name-container {
                display: flex;
                flex-flow: column;
                margin: 16px 0 30px;

                label {
                  font-family: "Montserrat";
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: #373a3c;
                }

                p {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 24px;
                  color: #373a3c;
                }

                .depositAmount,
                .charge,
                .insurance-name {
                  .MuiInputBase-formControl {
                    outline: none !important;
                    height: 40px;
                    text-align: center;
                    background: #ffffff;
                    box-sizing: border-box;
                    border-radius: 40px;
                  }
                }
              }
            }

            .extra-info {
              text-align: left;
              margin: 32px 0 50px;

              p {
                color: #373a3c;
                margin-bottom: 5px;
              }

              a {
                text-decoration: underline;
                color: #2f80ed;
              }

              p,
              a {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 24px;
              }
            }

            .btn-container {
              display: flex;
              justify-content: center;
              margin-top: 32px;

              .btn-orange {
                width: 311px;
                height: 38px;
                margin: 0;
                font-family: "Montserrat";
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
                background: var(--Brand-Colors-Primary-Color);
                border-radius: 40px;
              }
            }

            .date-container {
              .react-datepicker {
                .react-datepicker__month-container {
                  width: 100%;
                }
              }

              .MuiPickerStaticWrapper-root {
                box-shadow: 0px 8px 25px rgba(209, 217, 230, 0.8);
                padding: 8px 8px;
                border-radius: 10px;
                .MuiDialogActions-root {
                  display: none;
                }

                svg:hover {
                  color: orange;
                }
              }
            }

            .disponibility-text-container {
              text-align: start;
              margin: 16px 0 32px;

              p {
                font-weight: normal;
                margin-bottom: 5px;
              }

              span {
                font-weight: 600;
                text-decoration: underline;
              }

              p,
              span {
                color: #373a3c;
                font-family: "Montserrat";
                font-style: normal;
                font-size: 16px;
                line-height: 24px;
              }
            }

            .input-dates-container {
              display: flex;
              flex-flow: column;
              justify-content: center;
              margin-bottom: 20px;

              .btn-orange {
                width: 200px;
                height: 38px;
                border-radius: var(--radius-xl, 12px);
                border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
                background: var(--Colors-Brand-colors-Primary-color, #b72446);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                margin: 32px auto 30px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
              }

              .dates-text {
                font-family: "Montserrat";
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                color: #373a3c;
              }

              .dates-table {
                display: flex;
                flex-flow: column;
                align-items: center;

                .date-container {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;

                  .input-date {
                    width: 273px;
                    height: 38px;
                    background: #ffffff;
                    border: 1px solid #cccccc;
                    box-sizing: border-box;
                    outline: none !important;
                    border-radius: 40px;
                    padding: 0px 8px;
                    text-align: center;
                  }

                  p {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #000;
                    margin: 10px 0;
                  }
                }

                .btn-erase {
                  width: 38px;
                  height: 38px;
                  background: #bdbdbd;
                  border-radius: 50%;
                  padding: 0;
                  margin: 10px 0 25px;
                }

                .btn-reserved-date {
                  border-radius: 40px;
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  color: #ffffff;
                  margin: 10px 0 25px;
                  background: #3cb2b9;
                }
              }
            }

            .ubication {
              h3 {
                font-family: "Montserrat";
                font-weight: 600;
                color: #373a3c;
              }

              .direction {
                font-family: "Montserrat";
                font-weight: normal;
                color: #333333;
                text-align: center;
                border-bottom: 1px solid #cccccc;
              }

              .direction-des {
                display: none;
              }

              h3,
              .direction {
                font-size: 16px;
                line-height: 24px;
              }

              .btn-orange {
                width: 311px;
                height: 38px;
                background: var(--Brand-Colors-Primary-Color);
                border-radius: 40px;
                margin: 25px auto 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 21px;
                  color: #ffffff;
                  margin: 0 0 0 10px;
                }

                img {
                  width: 15px;
                }
              }

              .enable-check {
                display: flex;

                p {
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: #333333;
                  margin: 0 4px 0 8px;
                }

                .info-icon {
                  width: 15px;
                }
              }

              span {
                text-align: left;
                margin: 20px 0 24px;
                font-family: "Montserrat";
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #828282;
              }
            }

            .extra-option {
              .extra-option-title {
                font-weight: bold;
                font-family: "Montserrat";
              }

              .extra-address-container {
                flex-direction: column;
              }

              h3 {
                font-family: "Montserrat";
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: #373a3c;
              }

              .no-charge-recommendation {
                font-size: 14px;
                padding: 8px 0 0 12px;
              }

              .extra-info-h3 {
                font-family: "Montserrat";
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #828282;
              }

              .option-input {
                width: 100%;
                height: 45px;
                border: 1px solid #000;
                background: #ffffff;
                box-sizing: border-box;
                outline: none !important;
                border-radius: 40px;
                padding: 7px 0 7px 16px;
              }

              .btn-container {
                display: flex;
                align-items: center;
                margin: 16px 0 30px;
                width: 100%;

                .btn-erase {
                  width: 38px;
                  height: 38px;
                  background: #bdbdbd;
                  border-radius: 50%;
                  padding: 0;
                  order: 1;
                }

                .btn-orange-des {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 8px;
                  margin-left: 7px;
                  background: var(--Brand-Colors-Primary-Color);
                  padding: 0;
                  color: white;
                  font-weight: bold;
                  border-radius: 40px;
                  font-family: "Montserrat";
                  padding: 8px 18px;
                  font-size: 14px;
                  img {
                    margin-right: 8px;
                  }

                  p {
                    margin: 0;
                  }
                }

                .btn-orange {
                  width: 274px;
                  height: 38px;
                  background: var(--Brand-Colors-Primary-Color);
                  border-radius: 40px;
                  margin: 0 auto;
                  display: flex;
                  order: 2;
                  justify-content: center;
                  align-items: center;

                  p {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 21px;
                    color: #ffffff;
                    margin-right: 4px;
                  }

                  img {
                    width: 15px;
                  }
                }
              }

              .add-price {
                span {
                  font-family: "Montserrat";
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                }

                .delibery-price {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 8px 0 40px;

                  .delibery-input {
                    .MuiInputBase-formControl {
                      width: 104px;
                      height: 45px;
                      background: #ffffff;
                      box-sizing: border-box;
                      border-radius: 40px;
                      outline: none !important;
                      font-family: "Montserrat";
                      font-weight: normal;
                      font-size: 16px;
                      line-height: 24px;
                      text-align: center;
                      color: #333333;
                      margin-right: 32px;
                    }
                  }

                  .enable-check {
                    display: flex;
                    margin-bottom: 0px;
                    width: 22ch;

                    .check-icon {
                      width: 20px;
                      height: 20px;
                    }

                    span {
                      font-family: "Montserrat";
                      font-weight: normal;
                      font-size: 16px;
                      line-height: 24px;
                      line-height: 24px;
                      margin: 0 0 0 8px;
                    }
                  }
                }
              }

              .enable-check {
                display: flex;

                .check-icon {
                  width: 20px;
                  height: 20px;
                }

                p {
                  font-family: "Montserrat";
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: #333333;
                  margin: 0 4px 0 8px;
                }
              }

              .add-container {
                display: flex;
                justify-content: space-between;
                margin: 24px 0 60px;

                .btn-add-direction {
                  width: 290px;
                  height: 38px;
                  display: flex;
                  justify-content: center;
                  border-radius: var(--radius-xl, 12px);
                  border: 1px solid
                    var(--Colors-Brand-colors-Alternate, #d1cfcf);
                  background: var(--Colors-Brand-colors-Primary-color, #b72446);
                  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                  p {
                    font-family: "Montserrat";
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 21px;
                    color: #ffffff;
                  }

                  .plus-icon {
                    width: 24px;
                    height: 24px;
                  }
                }
              }

              .enable-home-delivery {
                .enable-check {
                  display: flex;

                  .check-icon {
                    width: 20px;
                    height: 20px;
                  }

                  p {
                    font-family: "Montserrat";
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    color: #333333;
                    margin: 0 4px 0 8px;
                  }

                  .info-icon {
                    width: 15px;
                  }
                }
              }
            }

            .dropdown-divider {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .btn-container.save-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    gap: 24px;
    padding: 23px 0 18px;

    .reminder {
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
    }

    .btn-blue {
      width: 311px;
      height: 52px;
      border-radius: var(--radius-xl, 12px);
      border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
      background: var(--Colors-Brand-colors-Primary-color, #b72446);
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  .btn-container-preview {
    width: 100%;
    display: flex;
    justify-content: center;

    .btn-orange {
      margin: 0 auto;
      width: 311px;
      height: 52px;
      border-radius: var(--radius-xl, 12px);
      border: 1px solid var(--Colors-Brand-colors-Alternate, #d1cfcf);
      background: var(--Colors-Brand-colors-Primary-color, #b72446);
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}

@media only screen and (min-width: 733px) {
  .edit-car-container {
    display: flex;
    align-items: center;
    flex-flow: column;
  }
}

@media only screen and (min-width: 991px) {
  .edit-car-container {
    padding-left: 58px;

    .btn-container.save-container {
      box-shadow: 0px 5px 22px rgb(0 0 0 / 60%);
      position: fixed;
      bottom: 0;
      left: 0;
      height: 100px;
      width: 100vw;
      flex-direction: row;
      justify-content: right;
      padding-right: 203px;
      z-index: 10;
      margin: 0px;
      background: white;
    }

    .return-prev-page {
      padding: 50px 0px 50px 0px;
    }

    .back-menu {
      display: none;
    }

    .back-menu-des {
      display: flex;
      background: #ecf7f8;
      width: 100%;
      height: 100px;
      position: relative;

      .nav-filter {
        display: flex;
        align-items: flex-end;
        width: 100%;

        .option-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          width: 80%;
          text-align: center;

          .MuiTabs-flexContainer {
            display: flex;
            justify-content: center;
            .option {
              display: flex;
              justify-content: center;
              width: 25%;
              font-family: "Montserrat";
              font-weight: bold;
              font-size: 20px;
              line-height: 27px;
              color: #828282;
              margin-bottom: 10px;

              .first-option {
                color: #195154;
                text-decoration: none;
              }
            }
          }
        }

        .btn-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25%;
          margin-bottom: 17px;

          .btn-orange {
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--Brand-Colors-Primary-Color);
            border-radius: 40px;
            color: #fff;
            width: 160px;
            height: 38px;
          }
        }
      }
    }

    .model-ubication {
      align-items: flex-start;
      margin-bottom: 100px;
      width: 95%;
      max-width: 1109px;

      .form-container {
        width: 100%;

        .info-container {
          margin-bottom: 40px;

          .title {
            font-size: 42px;
            line-height: 46px;
            margin-bottom: 33px;
          }

          h2 {
            font-size: 24px;
            line-height: 29px;
            margin: 0 0 29px;
          }
        }

        .form {
          display: flex;
          flex-flow: column;

          .file-container {
            width: 100%;

            .information {
              display: flex;
              flex-flow: wrap;

              .generic-input {
                width: 445px;
                margin: 0 30px 24px 0;

                label {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }

            .categories {
              width: 800px;
            }

            .goals {
              display: flex;
              flex-wrap: wrap;

              .goal-container {
                width: 445px;
                margin: 24px 30px 0 0;
              }
            }

            .option-title {
              font-size: 24px;
              line-height: 24px;
              padding: 8px 0;
            }

            .drop-row {
              width: 12px;
            }

            .drop-options {
              .document-generic-input {
                margin-bottom: 40px;
                padding: 0;
                display: flex;
                flex-flow: column;

                .dropzone-ui {
                  width: 445px;
                  height: 174px;
                  .dz-ui-label {
                    font-size: 19px;
                    line-height: 17px;
                  }
                }

                .direction-input {
                  display: flex;
                  align-items: center;
                  order: 2;
                  margin-bottom: 15px;

                  .btn-orange-des {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 189px;
                    margin: 0 0 0 7px;
                    height: 38px;
                    padding: 0;
                  }

                  .input-text-large {
                    width: 539px;
                    margin-bottom: 0;

                    .MuiInputBase-formControl {
                      width: 539px;
                      margin-bottom: 8px;
                    }
                  }

                  .input-error {
                    border: 1px solid #dc3545;
                  }
                }

                span {
                  display: block;
                  order: 1;
                }

                h5 {
                  order: 3;
                  margin-bottom: 40px;

                  br {
                    display: none;
                  }
                }

                .map {
                  width: 836px;
                  height: 292px;
                  order: 4;
                  margin-bottom: 80px;
                }
              }
            }
          }

          .deactivate-container {
            display: flex;
            flex-flow: row-reverse;
            justify-content: flex-end;
            padding-top: 54px;

            .deactivate-button {
              height: 30px;
              align-items: baseline;
              margin-top: 0px;

              p {
                margin: 0 10px 0 0;
              }
            }

            a {
              margin-right: 15px;
            }
          }
        }
      }
    }

    .documentation {
      align-items: flex-start;
      margin-bottom: 100px;
      width: 95%;
      max-width: 1109px;

      .form-container {
        width: 100%;

        .info-container {
          margin-bottom: 40px;

          .title {
            font-size: 42px;
            line-height: 46px;
            margin-bottom: 33px;
          }

          h2 {
            font-size: 24px;
            line-height: 29px;
            margin: 0 0 29px;
          }
        }

        .form {
          display: flex;
          flex-flow: column;
          .file-container {
            width: 100%;
            .option-title {
              font-size: 24px;
              line-height: 24px;
            }

            .drop-row {
              width: 12px;
            }

            .drop-options {
              display: flex;
              flex-flow: column;
              align-items: center;

              .car-images {
                display: none;
              }

              .car-images-des {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .image-name-container {
                  display: flex;
                  width: 161px;
                  flex-flow: column;
                  justify-content: center;
                  margin-right: 0px;
                  text-align: center;

                  p {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-family: "Montserrat";
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 24px;
                    color: #373a3c;
                  }

                  .image-container {
                    width: 161px;
                    height: 102px;
                    margin-top: 24px;
                    position: relative;
                    background: #fff;
                    display: flex;
                    border: 1px solid #8f9cb2;
                    box-sizing: border-box;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;

                    .image-in {
                      height: 68px;
                    }

                    .btn-options {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      top: 6px;
                      position: absolute;
                      width: 24px;
                      height: 24px;
                      border-radius: 50%;
                      padding: 0;
                      background: #ffffff;
                      box-shadow: 0px 2px 8px rgba(196, 209, 237, 0.51);

                      img {
                        width: 14px;
                      }
                    }
                  }
                }
              }

              .document-generic-input {
                margin: 40px 0;
                padding: 0;

                .dropzone-ui {
                  width: 920px;
                  .dz-ui-label {
                    font-size: 19px;
                    line-height: 17px;
                  }
                }

                span {
                  font-weight: 600;
                  font-size: 14px;
                  margin: 16px 0 8px;
                }

                h5 {
                  font-weight: 500;
                  font-size: 12px;
                }

                span,
                h5 {
                  color: #828282;
                  font-family: "Montserrat";
                  line-height: 24px;
                }
              }

              .information {
                width: 920px;
                padding-left: 23px;

                li {
                  text-align: start;
                }
              }

              .accesories-container {
                width: 921px;

                .more-container {
                  display: flex;
                  justify-content: center;
                  margin: 14px 0 40px;
                }

                .only-accesories {
                  display: none;
                }

                .only-accesories {
                  display: flex;
                  justify-content: space-around;

                  .accesories-content {
                    .option {
                      display: flex;
                      align-items: center;
                      margin-bottom: 30px;

                      label {
                        font-family: "Montserra";
                        font-weight: normal;
                        font-size: 16px;
                        color: #333333;
                        margin: 0 0 0 3px;
                      }

                      img {
                        width: 20px;
                      }
                    }
                  }
                }
              }

              .message-box {
                height: 140px;
                width: 100%;
              }

              .max-text {
                width: 100%;
              }

              .dropdown-divider {
                width: 100%;
              }
            }

            #accesories {
              padding-left: 8px;
            }
          }
        }
      }
    }

    .price-disponibility {
      align-items: flex-start;
      margin-bottom: 100px;
      width: 95%;
      max-width: 1109px;

      .form-container {
        width: 100%;

        .info-container {
          .title {
            font-size: 42px;
            line-height: 46px;
            margin-bottom: 33px;
          }

          h2 {
            font-size: 24px;
            line-height: 29px;
            margin: 0 0 29px;
          }
        }

        .form {
          .file-container {
            .option-title {
              font-size: 24px;
              line-height: 24px;
            }

            .drop-options {
              width: 100%;
              margin: 0 auto;

              .price-text-container,
              .insurance-text-container {
                p {
                  text-align: start;
                  font-size: 16px;
                  line-height: 24px;
                  padding: 12px 6px 12px 0;
                  margin: 0;
                }
                .more-details {
                  padding-left: 6px;
                  text-decoration: underline;
                  padding: 8px 0;
                }
              }

              .consult-pricing {
                display: flex;
                align-items: center;
                gap: 6px;

                svg {
                  color: var(--Colors-Custom-colors-hyperlinks, #0a7df5);
                  width: 16px;
                  height: 16px;
                }

                a {
                  color: var(--Colors-Custom-colors-hyperlinks, #0a7df5);
                  font-family: "Montserrat";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  cursor: pointer;
                }

                p {
                  color: var(--Colors-Utility-colors-Secondary-text, #4a4a57);
                  font-family: "Montserrat";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  margin-bottom: 0;
                }
              }

              .day-discount {
                flex-direction: row;
                gap: var(--spacing-3xl, 24px);
                padding-left: 48px;

                .price-d {
                  gap: 6px;
                  width: 240px;
                }
              }

              .doubts {
                display: flex;

                span {
                  color: var(--Gray-3, #828282);
                  font-family: "Montserrat";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                }
              }

              .insurance-inputs {
                display: flex;
                justify-content: space-evenly;

                .charge-container,
                .deposit-amount-container,
                .insurance-name-container {
                  width: 70%;
                  .charge,
                  .depositAmount {
                    width: 310px;
                  }
                }
              }

              .extra-info {
                text-align: left;
                display: flex;

                p {
                  margin-right: 6px;
                }
              }

              .date-container {
                display: flex;
                align-items: center;
                justify-content: center;
                .react-datepicker {
                  max-width: 600px;
                  .react-datepicker__month-container {
                    width: 50%;
                  }
                }
              }

              .disponibility-text-container {
                display: flex;
                justify-content: center;

                p {
                  margin-right: 6px;
                }

                p,
                a {
                  font-size: 18px;
                  line-height: 24px;
                }
              }

              .input-dates-container {
                margin: 0 auto 40px;
                width: 702px;

                .dates-text {
                  font-size: 18px;
                }

                .dates-table {
                  display: flex;
                  flex-flow: row;
                  align-items: center;
                  width: 800px;

                  .date-container {
                    display: flex;
                    justify-content: space-around;
                    width: 43%;

                    .input-date {
                      width: 273px;
                      height: 38px;
                      background: #ffffff;
                      border: 1px solid #cccccc;
                      box-sizing: border-box;
                      outline: none !important;
                      border-radius: 40px;
                      padding: 0px 8px;
                      text-align: center;
                    }

                    p {
                      font-family: "Montserrat";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 21px;
                      color: #000;
                      margin: 10px 0;
                    }
                  }

                  .btn-erase {
                    width: 38px;
                    height: 38px;
                    background: #bdbdbd;
                    border-radius: 50%;
                    padding: 0;
                    margin: 10px 0;
                  }

                  .btn-reserved-date {
                    border-radius: 40px;
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #ffffff;
                    margin: 10px 0;
                    background: #3cb2b9;
                  }
                }
              }

              .ubication {
                display: flex;
                flex-wrap: wrap;

                h3 {
                  width: 100%;
                }

                .direction-des {
                  display: flex;
                  .MuiInputBase-formControl {
                    width: 539px;
                    height: 45px;
                    background: #ffffff;
                    border-radius: 40px;
                    font-family: "Montserrat";
                    font-weight: normal;
                    color: #333333;
                    font-size: 16px;
                    padding: 7px 0 7px 10px;
                    line-height: 24px;
                    margin-right: 7px;
                  }
                }

                .direction {
                  display: none;
                }

                .btn-orange {
                  margin: 0;
                  width: 189px;
                  height: 38px;
                  border-radius: 40px;
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  p {
                    margin: 0 0 0 10px;
                  }
                }

                .enable-check {
                  order: 4;
                  width: 100%;
                  margin-bottom: 40px;
                }

                span {
                  order: 3;
                  width: 100%;
                }

                .enable-more-directions {
                  margin-top: 20px;
                }
              }

              .extra-option {
                display: flex;
                flex-wrap: wrap;

                h3 {
                  font-size: 16px;
                  width: 100%;
                }

                .extra-address-container {
                  flex-direction: row;
                }

                .option-input {
                  width: 40%;
                  .MuiInputBase-formControl {
                    width: 539px;
                    height: 38px;
                  }
                }

                .btn-container {
                  display: flex;
                  justify-content: space-between;
                  margin: 0 10px 0 7px;
                  width: 23%;
                  height: 38px;

                  .btn-erase {
                    order: 2;
                  }

                  .btn-orange {
                    margin: 0 10px 0 0;
                    width: 80%;
                    order: 1;
                    height: 38px;
                    border-radius: 40px;
                    padding: 0;
                    justify-content: center;
                    align-items: center;

                    p {
                      margin: 0 0 0 5px;
                    }
                  }
                }

                .add-price {
                  position: relative;
                  bottom: 36px;

                  .delibery-price {
                    justify-content: center;
                    margin: 8px 0 0;

                    .delibery-input {
                      .MuiInputBase-formControl {
                        width: 94px;
                      }
                    }
                  }
                }

                .add-container {
                  justify-content: center;
                  margin: 30px 0;
                  width: 100%;
                  padding-bottom: 30px;
                  border-bottom: 1px solid #e0e0e0;

                  .btn-add-direction {
                    margin-right: 18px;
                  }
                }

                .enable-home-delivery {
                  margin: 0 50px 70px;

                  .enable-check {
                    display: flex;

                    .check-icon {
                      width: 20px;
                      height: 20px;
                    }

                    p {
                      font-family: "Montserrat";
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 24px;
                      color: #333333;
                      margin: 0 4px 0 8px;
                    }

                    .info-icon {
                      width: 15px;
                    }
                  }
                }

                #extra-divider {
                  display: none;
                }
              }

              .dropdown-divider {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .btn-container-preview {
      justify-content: center;
      align-items: center;
      width: 20%;

      .btn-orange {
        margin: 15px auto 20px;
        width: 70%;
        height: 40px;
      }
    }

    .btn-container {
      justify-content: flex-end;

      .btn-orange {
        margin: 27px 87px 0px 0;
        width: 255px;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .edit-car-container {
    .documentation {
      min-width: 1050px;
    }
  }
}
