.status-alert.MuiAlert-root {
  border-radius: 0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 16px;
  font-weight: unset;
  width: 100%;
  text-align: center;
  background-color: var(--Utility-Colors-Primary-Text);

  .status-alert__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    p,
    a {
      color: var(--Utility-Colors-Primary-Background);
      margin-bottom: 0;
      font-size: 12px;
    }

    a {
      text-underline-offset: 3px;
    }

    .user-documents-status {
      width: 340px;
      height: 36px;
      background-color: var(--Utility-Colors-Primary-Background);
      border: 1px solid var(--Utility-Colors-Primary-Background);
      border-radius: 40px;
      justify-content: space-around;
      margin-left: 6px;

      .user-button-status {
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--Utility-Colors-Primary-Text);
      }

      .MuiButton-endIcon {
        color: var(--Utility-Colors-Primary-Text);
      }
    }

    .css-qshwzs-MuiPaper-root-MuiPopover-paper {
      top: 140px;
    }
  }
}

@media only screen and (min-width: 802px) {
  .status-alert.MuiAlert-root {
    .status-alert__message {
      display: flex;
      flex-direction: row;
      gap: 33px;
    }
  }
}
